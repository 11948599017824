import { GET_ADVISOR_PERMISSIONS_SUCCESS, UPDATE_ADVISOR_PERMISSIONS_SUCCESS } from '../actionTypes'

const init: any = {
  documentPermissions: {},
  insurancePermissions: {},
  mortgagePermissions: {},
  notificationPermissions: {},
  clientPermissions: {},
}
export function advisorPermissionsReducer(state = init, { type, payload }: any = {}) {
  switch (type) {
    case GET_ADVISOR_PERMISSIONS_SUCCESS:
      return {
        ...state,
        documentPermissions: payload.documentPermissions,
        insurancePermissions: payload.insurancePermissions,
        mortgagePermissions: payload.mortgagePermissions,
        notificationPermissions: payload.notificationPermissions,
        clientPermissions: payload.clientPermissions,
      }
    case UPDATE_ADVISOR_PERMISSIONS_SUCCESS:
      return {
        ...state,
        documentPermissions: payload.documentPermissions,
        insurancePermissions: payload.insurancePermissions,
        mortgagePermissions: payload.mortgagePermissions,
        notificationPermissions: payload.notificationPermissions,
        clientPermissions: payload.clientPermissions,
      }

    default:
      return state
  }
}
