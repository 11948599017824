import { Add, Delete, Download } from '@mui/icons-material'
import { Alert, Button, Container, IconButton, Modal, Snackbar } from '@mui/material'
import { DataGrid, frFR, GridColDef } from '@mui/x-data-grid'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'
import {
  ClientDocumentsApi,
  Configuration,
  Direction,
  DocumentSearchQuery,
  DocumentSearchQuerySortByEnum,
  DocumentType,
  ProductType,
} from '../../api'
import { DocumentTypeModified } from '../../helper/constants'
import { snackBarProps } from '../../helper/iTypes'
import { deleteDocument, getDocuments } from '../../redux/actions/documents.action'
import { RootState, useAppDispatch } from '../../redux/store'
import { handleAccessToken } from '../../services/config'
import PromptView from '../customPrompt/promptView'
import { ModalAddDocument } from '../modalAddDocument'

interface stateType {
  userID: number
}

export default function DocumentsList() {
  const [filterValue, setFilterValue] = React.useState<DocumentSearchQuery>({
    search: {
      documentType: undefined,
      productType: undefined,
      title: undefined,
      filename: undefined,
      contentType: undefined,
      textSearch: undefined,
    },
    sortBy: DocumentSearchQuerySortByEnum.DocumentType,
    sortDir: Direction.Asc,
    pageIndex: 0,
    pageSize: 10,
  })
  const [snackBar, setSnackBar] = React.useState<snackBarProps>({
    open: false,
    message: '',
    severity: 'success',
  })
  const [showPromptTabs, setShowPromptTabs] = useState(false)
  const [open, setOpen] = React.useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const [documentToDelete, setDocumentToDelete] = useState({ id: 0, filename: '' })
  const dispatch = useAppDispatch()
  const location = useLocation()
  const params = useParams()
  const routerState = location.state as stateType
  const userID = routerState?.userID ?? params.id
  const documents = useSelector((state: RootState) => state.documentsReducer.documents)
  const loading = useSelector((state: RootState) => state.documentsReducer.loading)
  const totalElements = useSelector((state: RootState) => state.documentsReducer.totalElements)
  const permissions = useSelector((state: RootState) => state.advisorPermissionsReducer)
  const handleCloseSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }

    setSnackBar({
      open: false,
      message: '',
      severity: 'success',
    })
  }
  const { t } = useTranslation()
  /* -------------------------------------------------------------------------- */
  /*                                  HELPERS                                   */
  /* -------------------------------------------------------------------------- */
  const onDownloadDocument = (id: number, fileName: string, title: string) => {
    const clientApi = new ClientDocumentsApi(
      new Configuration({
        basePath: `${process.env.REACT_APP_BACKEND_API_URL}`,
        accessToken: handleAccessToken.getAccessTokenSilently(),
      }),
    )
    clientApi
      .downloadDocument(id, fileName, 'fr', {
        responseType: 'blob',
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', title)
        document.body.appendChild(link)
        link.click()
      })
      .catch((err) => {
        setSnackBar({
          open: true,
          message: t(`errors.${err}`),
          severity: 'error',
        })
      })
  }
  const onDeleteDocumentDialog = (id: number, filename: string) => {
    setShowPromptTabs(true)
    setDocumentToDelete({
      id,
      filename,
    })
  }
  const cancelNavigationTab = () => {
    setShowPromptTabs(false)
  }
  const confirmNavigationTab = () => {
    dispatch(deleteDocument(documentToDelete.id, documentToDelete.filename))
    setShowPromptTabs(false)
  }
  const checkAdvisorPermissions = (clientPermissions: any) =>
    Object.values(clientPermissions).some((permission) => permission)
  const checkAdvisorPermissionsForDelete = (documentTypes: any, rowType: string) => {
    const keyPermission: any = Object.keys(documentTypes).find(
      (key) => documentTypes[key] === rowType,
    )
    return !permissions.documentPermissions[keyPermission]
  }
  /* -------------------------------------------------------------------------- */
  /*                                  COSNTANT                                  */
  /* -------------------------------------------------------------------------- */
  const columns: GridColDef[] = [
    {
      field: 'documentType',
      headerName: t('documents_table.document_type'),
      flex: 1,
      disableColumnMenu: true,
      valueFormatter: ({ value }) => {
        let type = ''
        // eslint-disable-next-line array-callback-return
        Object.entries(DocumentType).map(([key, v]) => {
          if (value === v) type = key
        })
        return t(`documents_types.${type}`)
      },
    },
    {
      field: 'productType',
      headerName: t('documents_table.product_type'),
      flex: 1,
      disableColumnMenu: true,
      valueFormatter: ({ value }) => {
        let type = ''
        // eslint-disable-next-line array-callback-return
        Object.entries(ProductType).map(([key, v]) => {
          if (value === v) type = key
        })
        return t(`product_types.${type}`)
      },
    },
    {
      field: 'title',
      headerName: t('documents_table.document'),
      flex: 1,
      disableColumnMenu: true,
      renderCell: ({ value, row }) => (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a
          href="#"
          rel="noreferrer"
          onClick={() => onDownloadDocument(row.clientId, row.filename, row.title)}
        >
          {value}
        </a>
      ),
    },
    {
      field: 'lastModifiedByFullName',
      headerName: t('documents_table.author'),
      flex: 1,
      disableColumnMenu: true,
      sortable: false, // TODO DocumentSearchQuery dont have this param in search
    },
    {
      field: 'lastModifiedDate',
      headerName: t('documents_table.date'),
      flex: 1,
      disableColumnMenu: true,
      valueFormatter: ({ value }) => value.substring(0, 10),
    },
    {
      field: 'Actions',
      headerName: '',
      disableColumnMenu: true,
      sortable: false,
      renderCell: (cellValues) => (
        <>
          <IconButton
            onClick={() =>
              onDownloadDocument(
                cellValues.row.clientId,
                cellValues.row.filename,
                cellValues.row.title,
              )
            }
            color="secondary"
          >
            <Download />
          </IconButton>
          <IconButton
            onClick={() => onDeleteDocumentDialog(cellValues.row.clientId, cellValues.row.filename)}
            color="warning"
            disabled={checkAdvisorPermissionsForDelete(
              DocumentTypeModified,
              cellValues.row.documentType,
            )}
          >
            <Delete />
          </IconButton>
        </>
      ),
    },
  ]

  const handleChangePage = (page: number) => {
    setFilterValue((prevState) => ({
      ...prevState,
      pageIndex: page,
    }))
  }

  const handleSortChange = (model: any) => {
    setFilterValue((prevState) => ({
      ...prevState,
      sortBy: model[0]?.field ?? DocumentSearchQuerySortByEnum.DocumentType,
      sortDir: model[0]?.sort === 'desc' ? Direction.Desc : Direction.Asc,
    }))
  }

  React.useEffect(() => {
    dispatch(getDocuments(filterValue, userID)).then((res) => {
      if (res?.status !== 200) {
        setSnackBar({
          open: true,
          message: t(`errors.${res.status}`),
          severity: 'error',
        })
      }
    })
  }, [filterValue])

  return (
    <div>
      <div style={{ textAlign: 'end', marginBottom: '12px' }}>
        {checkAdvisorPermissions(permissions.documentPermissions) && (
          <Button onClick={handleOpen} variant="contained" startIcon={<Add />}>
            {t('documents_table.add_document_title')}
          </Button>
        )}
        <Modal open={open} onClose={handleClose} className="modal">
          <Container>
            <ModalAddDocument onCancel={handleClose} />
          </Container>
        </Modal>
      </div>
      <PromptView
        showPrompt={showPromptTabs}
        cancelNavigation={cancelNavigationTab}
        confirmNavigation={confirmNavigationTab}
        dialogTitle="common.delete_changes_title"
        dialogText="common.delete_changes_content"
      />
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={snackBar.open}
        onClose={handleCloseSnackbar}
        autoHideDuration={4000}
      >
        <Alert onClose={handleCloseSnackbar} variant="filled" severity={snackBar?.severity}>
          {snackBar.message}
        </Alert>
      </Snackbar>
      <DataGrid
        density="compact"
        rows={documents ?? []}
        pagination
        columns={columns}
        rowCount={totalElements}
        pageSize={10}
        autoHeight
        onSortModelChange={(model) => handleSortChange(model)}
        paginationMode="server"
        loading={loading}
        onPageChange={(data) => {
          handleChangePage(data)
        }}
        disableSelectionOnClick
        localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
        rowsPerPageOptions={[10]}
      />
    </div>
  )
}
