import { Box, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { advisorTypes } from '../../helper/constants'

export default function ServicesLegend() {
  const { t } = useTranslation()
  return (
    <Box display="flex" flexDirection="row" marginTop={3}>
      <Typography variant="body2" component="p">
        <strong>{t('forms.labels.legend')}</strong> : {t('forms.labels.services_types')}
        {advisorTypes.map((advisorType) => (
          <span key={advisorType.value}>
            <img
              src={advisorType.icon}
              alt={advisorType.label}
              style={{ paddingLeft: 24, paddingRight: 8, verticalAlign: 'middle' }}
            />
            {advisorType.shortLabel}
          </span>
        ))}
      </Typography>
    </Box>
  )
}
