import { GET_NOTIFICATIONS_LOADING, GET_NOTIFICATIONS_SUCCESS } from '../actionTypes'
import { INotifications } from '../types'

const init: INotifications = {
  notifications: [],
  numberOfElements: 0,
  totalElements: 0,
  totalPages: 0,
  first: null,
  last: null,
  empty: null,
  loading: false,
}
export function notificationsReducer(state = init, { type, payload }: any = {}) {
  switch (type) {
    case GET_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notifications: payload.content,
        numberOfElements: payload.numberOfElements,
        first: payload.first,
        last: payload.last,
        empty: payload.empty,
        totalPages: payload.totalPages,
        totalElements: payload.totalElements,
        loading: false,
      }

    case GET_NOTIFICATIONS_LOADING:
      return {
        ...state,
        loading: payload,
      }

    default:
      return state
  }
}
