import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface promptViewProps {
  showPrompt: boolean | (() => void)
  cancelNavigation: boolean | (() => void)
  confirmNavigation: boolean | (() => void)
  dialogTitle: string
  dialogText: string
}
export default function PromptView({
  showPrompt,
  cancelNavigation,
  confirmNavigation,
  dialogTitle,
  dialogText,
}: promptViewProps) {
  const { t } = useTranslation()
  return (
    <div>
      <Dialog open={typeof showPrompt === 'boolean' && showPrompt}>
        <DialogTitle id="alert-dialog-title">{t(dialogTitle)}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{t(dialogText)}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => typeof cancelNavigation !== 'boolean' && cancelNavigation()}>
            {t('common.cancel')}
          </Button>
          <Button
            onClick={() => typeof confirmNavigation !== 'boolean' && confirmNavigation()}
            autoFocus
          >
            {t('common.continue')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
