/* eslint-disable no-param-reassign */
import { Configuration } from '../../api'
import {
  ClientApi,
  Direction,
  MortgageSearchQuery,
  MortgageSearchQuerySortByEnum,
} from '../../api/api'
import { handleAccessToken } from '../../services/config'
import { ADD_MORTGAGE_SUCCESS, GET_MORTGAGES_SUCCESS } from '../actionTypes'
import { AppDispatch } from '../store'
import { onHandleLoading } from './loading.action'

export function getMortgagesSuccess(data: any) {
  return {
    type: GET_MORTGAGES_SUCCESS,
    payload: data,
  }
}

export function addMortgageSuccess(data: any) {
  return {
    type: ADD_MORTGAGE_SUCCESS,
    payload: data,
  }
}

export function getMortgages(data: MortgageSearchQuery, id: number) {
  return async (dispatch: AppDispatch) => {
    const token = await handleAccessToken.getAccessTokenSilently()
    const clientAPI = new ClientApi(
      new Configuration({
        basePath: `${process.env.REACT_APP_BACKEND_API_URL}`,
        accessToken: token,
      }),
    )
    return clientAPI
      .searchClientMortgage(id, 'fr', data)
      .then((res) => {
        dispatch(getMortgagesSuccess(res.data))
        return res
      })
      .catch((error) => error.response)
  }
}

export function addMortgage(id: number, data: any, clientData: any, permissionInsurance: boolean) {
  return async (dispatch: AppDispatch) => {
    const token = await handleAccessToken.getAccessTokenSilently()
    dispatch(onHandleLoading(true))
    const clientAPI = new ClientApi(
      new Configuration({
        basePath: `${process.env.REACT_APP_BACKEND_API_URL}`,
        accessToken: token,
      }),
    )
    const filteredMortgages: any = clientData.mortgages.filter(
      (element: any) => element.id !== data.id,
    )
    if (permissionInsurance === false) {
      delete clientData.insurancePolicies
    }
    const updateClientData: any = { ...clientData, mortgages: [...filteredMortgages, data] }
    const dataInfo = {
      search: {
        name: undefined,
        visible: undefined,
        description: undefined,
        maturityDateFrom: undefined,
        maturityDateTo: undefined,
        rate: undefined,
        rateType: undefined,
        frequency: undefined,
        homeEquityLineOfCredit: undefined,
        propertyValue: undefined,
        balance: undefined,
        lastBalanceUpdateFrom: undefined,
        lastBalanceUpdateTo: undefined,
        lender: undefined,
        textSearch: undefined,
      },
      sortBy: MortgageSearchQuerySortByEnum.MaturityDate,
      sortDir: Direction.Asc,
      pageIndex: 0,
      pageSize: 10,
    }
    return clientAPI
      .updateClient(id, 'fr', updateClientData)
      .then((response) => {
        dispatch(onHandleLoading(false))
        return clientAPI.searchClientMortgage(id, 'fr', dataInfo).then((res: any) => {
          dispatch(getMortgagesSuccess(res.data))
          return response
        })
      })
      .catch((error) => {
        dispatch(onHandleLoading(false))
        return error.response
      })
  }
}

export function deleteMortgage(id: number, mortgageId: any, clientData: any) {
  return async (dispatch: AppDispatch) => {
    const token = await handleAccessToken.getAccessTokenSilently()
    dispatch(onHandleLoading(true))
    const clientAPI = new ClientApi(
      new Configuration({
        basePath: `${process.env.REACT_APP_BACKEND_API_URL}`,
        accessToken: token,
      }),
    )
    const data = {
      search: {
        name: undefined,
        visible: undefined,
        description: undefined,
        maturityDateFrom: undefined,
        maturityDateTo: undefined,
        rate: undefined,
        rateType: undefined,
        frequency: undefined,
        homeEquityLineOfCredit: undefined,
        propertyValue: undefined,
        balance: undefined,
        lastBalanceUpdateFrom: undefined,
        lastBalanceUpdateTo: undefined,
        lender: undefined,
        textSearch: undefined,
      },
      sortBy: MortgageSearchQuerySortByEnum.MaturityDate,
      sortDir: Direction.Asc,
      pageIndex: 0,
      pageSize: 10,
    }
    // eslint-disable-next-line array-callback-return
    // eslint-disable-next-line no-return-assign
    const disabledMortgages: any = clientData.mortgages.map((el: any) =>
      el.id === mortgageId ? { ...el, visible: false } : el,
    )
    const updateClientData: any = { ...clientData, mortgages: disabledMortgages }

    return clientAPI
      .updateClient(id, 'fr', updateClientData)
      .then(() => {
        dispatch(onHandleLoading(false))
        return clientAPI.searchClientMortgage(id, 'fr', data).then((res: any) => {
          dispatch(getMortgagesSuccess(res.data))
          return res
        })
      })
      .catch((error) => {
        dispatch(onHandleLoading(false))
        return error.response
      })
  }
}
