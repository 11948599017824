import Box from '@mui/material/Box'
import CssBaseline from '@mui/material/CssBaseline'
import Toolbar from '@mui/material/Toolbar'
import * as React from 'react'
import { drawerWidth } from '../../helper/constants'
import DrawerMenu from '../drawer'
import HeaderMenu from '../header'

interface DashboardProps {
  children: React.ReactElement
  headerTitle: string
}

export default function DashboardLayout({ children, headerTitle }: DashboardProps) {
  const [mobileOpen, setMobileOpen] = React.useState(false)
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }
  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <HeaderMenu onClose={() => handleDrawerToggle()} headerTitle={headerTitle} />
      <DrawerMenu onOpen={mobileOpen} onClose={() => handleDrawerToggle()} />
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
      >
        <Toolbar />
        {children}
      </Box>
    </Box>
  )
}
