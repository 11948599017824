import { Edit } from '@mui/icons-material'
import { Alert, IconButton, Snackbar } from '@mui/material'
import { DataGrid, frFR, GridColDef } from '@mui/x-data-grid'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { ClientSearchQuery, Direction, Profile, ProfileSearchQuerySortByEnum } from '../../api'
import { advisorTypes } from '../../helper/constants'
import { snackBarProps } from '../../helper/iTypes'
import { getClients } from '../../redux/actions/client.action'
import { RootState, useAppDispatch } from '../../redux/store'
import FilterPanel from '../filterList/clients'
import { Filtres } from '../filterList/clients/types'
import ServicesLegend from './ServicesLegend'

interface IProps {
  reload: boolean
}
export default function ClientsList({ reload }: IProps) {
  const [filterValue, setFilterValue] = React.useState<ClientSearchQuery>({
    search: {
      advisorIds: undefined,
      email: undefined,
      firstName: undefined,
      lastName: undefined,
    },
    sortBy: ProfileSearchQuerySortByEnum.LastName,
    sortDir: Direction.Asc,
    pageIndex: 0,
    pageSize: 10,
  })
  const [snackBar, setSnackBar] = React.useState<snackBarProps>({
    open: false,
    message: '',
    severity: 'success',
  })
  const dispatch = useAppDispatch()
  const clients = useSelector((state: RootState) => state.usersReducer.users)
  const loading = useSelector((state: RootState) => state.usersReducer.loading)
  const totalElements = useSelector((state: RootState) => state.usersReducer.totalElements)
  const permissions = useSelector((state: RootState) => state.advisorPermissionsReducer)
  const [editBtnStatus, setEditBtnStatus] = React.useState<boolean>(false)

  const handleCloseSnackbar = (_event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }

    setSnackBar({
      open: false,
      message: '',
      severity: 'success',
    })
  }
  const { t } = useTranslation()
  const navigate = useNavigate()
  const onNavigate = (data: Profile) => {
    navigate(`/client/${data.id}`, {
      state: {
        userID: data.id,
        from: 'clientsList',
      },
    })
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const permissionEdit = () => {
    if (
      permissions.clientPermissions.updateContactInformation ||
      permissions.clientPermissions.updateProfile
    ) {
      return setEditBtnStatus(false)
    }
    return setEditBtnStatus(true)
  }

  const getAdvisorTypeIcon = (selectedAdvisorType: string) => {
    const advisorType = advisorTypes.filter((obj) => obj.value === selectedAdvisorType)
    return (
      <img
        src={advisorType[0].icon}
        alt={advisorType[0].label}
        style={{ paddingRight: 16, verticalAlign: 'middle' }}
      />
    )
  }

  /* -------------------------------------------------------------------------- */
  /*                                  COSNTANT                                  */
  /* -------------------------------------------------------------------------- */
  const columns: GridColDef[] = [
    {
      field: 'firstName',
      headerName: t('forms.labels.first_name'),
      flex: 1,
      disableColumnMenu: true,
    },
    {
      field: 'lastName',
      headerName: t('forms.labels.last_name'),
      flex: 1,
      disableColumnMenu: true,
    },
    {
      field: 'email',
      headerName: t('forms.labels.email'),
      flex: 1,
      disableColumnMenu: true,
    },
    {
      field: 'advisorTypes',
      headerName: t('forms.labels.services'),
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (cellValues) => (
        <div>
          {cellValues.value?.map((value: string) => (
            <span key={value}>{getAdvisorTypeIcon(value)}</span>
          ))}
        </div>
      ),
    },
    {
      field: 'Actions',
      headerName: '',
      disableColumnMenu: true,
      sortable: false,
      renderCell: (cellValues) => (
        <IconButton
          disabled={editBtnStatus}
          onClick={() => onNavigate(cellValues.row)}
          color="secondary"
        >
          <Edit />
        </IconButton>
      ),
    },
  ]

  const handleFilters = (values: Filtres) => {
    setFilterValue((prevState: ClientSearchQuery) => ({
      ...prevState,
      search: {
        advisorIds: values?.advisorIds ?? undefined,
        email: values?.email ?? undefined,
        firstName: values?.firstName,
        lastName: values?.lastName ?? undefined,
        isOrphan: values.isOrphan,
      },
    }))
  }

  const handleChangePage = (page: number) => {
    setFilterValue((prevState) => ({
      ...prevState,
      pageIndex: page,
    }))
  }

  const handleSortChange = (model: any) => {
    setFilterValue((prevState) => ({
      ...prevState,
      sortBy: model[0]?.field ?? ProfileSearchQuerySortByEnum.LastName,
      sortDir: model[0]?.sort === 'desc' ? Direction.Desc : Direction.Asc,
    }))
  }

  React.useEffect(() => {
    dispatch(getClients(filterValue)).then((res) => {
      if (res?.status !== 200) {
        setSnackBar({
          open: true,
          message: t(`errors.${res.status}`),
          severity: 'error',
        })
      }
    })
  }, [dispatch, filterValue, t, reload])

  React.useEffect(() => {
    permissionEdit()
  }, [permissionEdit, permissions])

  return (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={snackBar.open}
        onClose={handleCloseSnackbar}
        autoHideDuration={4000}
      >
        <Alert onClose={handleCloseSnackbar} variant="filled" severity={snackBar?.severity}>
          {snackBar.message}
        </Alert>
      </Snackbar>
      <FilterPanel handleFilters={(values) => handleFilters(values)} />
      <DataGrid
        density="compact"
        rows={clients ?? []}
        pagination
        columns={columns}
        rowCount={totalElements}
        pageSize={10}
        autoHeight
        onSortModelChange={(model) => handleSortChange(model)}
        paginationMode="server"
        loading={loading}
        onPageChange={(data) => {
          handleChangePage(data)
        }}
        disableSelectionOnClick
        localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
        rowsPerPageOptions={[10]}
      />
      <ServicesLegend />
    </div>
  )
}
