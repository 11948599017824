import { Configuration } from '../../api'
import { ClientDocumentsApi, DocumentSearchQuery } from '../../api/api'
import { handleAccessToken } from '../../services/config'
import {
  ADD_DOCUMENT_SUCCESS,
  DELETE_DOCUMENT_SUCCESS,
  GET_DOCUMENTS_SUCCESS,
} from '../actionTypes'
import { AppDispatch } from '../store'
import { onHandleLoading } from './loading.action'

export function getDocumentSuccess(data: any) {
  return {
    type: GET_DOCUMENTS_SUCCESS,
    payload: data,
  }
}

export function deleteDocumentSuccess(fileName: string) {
  return {
    type: DELETE_DOCUMENT_SUCCESS,
    payload: fileName,
  }
}

export function addDocumentSuccess(data: any) {
  return {
    type: ADD_DOCUMENT_SUCCESS,
    payload: data,
  }
}

export function getDocuments(data: DocumentSearchQuery, id: number) {
  return async (dispatch: AppDispatch) => {
    const token = await handleAccessToken.getAccessTokenSilently()
    const clientDocuments = new ClientDocumentsApi(
      new Configuration({
        basePath: `${process.env.REACT_APP_BACKEND_API_URL}`,
        accessToken: token,
      }),
    )
    return clientDocuments
      .searchClientDocument(id, 'fr', data)
      .then((res) => {
        dispatch(getDocumentSuccess(res.data))
        return res
      })
      .catch((error) => error.response)
  }
}

export function downloadDocument(id: number, fileName: string) {
  return async () => {
    const token = await handleAccessToken.getAccessTokenSilently()
    const clientDocuments = new ClientDocumentsApi(
      new Configuration({
        basePath: `${process.env.REACT_APP_BACKEND_API_URL}`,
        accessToken: token,
      }),
    )
    return clientDocuments
      .downloadDocument(id, fileName, 'fr')
      .then((res) => res.data)
      .catch((error) => error.response)
  }
}

export function deleteDocument(id: number, fileName: string) {
  return async (dispatch: AppDispatch) => {
    const token = await handleAccessToken.getAccessTokenSilently()
    const clientDocuments = new ClientDocumentsApi(
      new Configuration({
        basePath: `${process.env.REACT_APP_BACKEND_API_URL}`,
        accessToken: token,
      }),
    )
    // eslint-disable-next-line no-underscore-dangle
    return clientDocuments
      ._delete(id, fileName, 'fr')
      .then(() => dispatch(deleteDocumentSuccess(fileName)))
      .catch((error) => error.response)
  }
}

export function addDoucment(id: number, blob: any, file: any) {
  return async (dispatch: AppDispatch) => {
    const token = await handleAccessToken.getAccessTokenSilently()
    dispatch(onHandleLoading(true))
    const clientDocuments = new ClientDocumentsApi(
      new Configuration({
        basePath: `${process.env.REACT_APP_BACKEND_API_URL}`,
        accessToken: token,
      }),
    )

    return clientDocuments
      .uploadDocument(id, 'fr', blob, file)
      .then((res) => {
        dispatch(addDocumentSuccess(res.data))
        dispatch(onHandleLoading(false))
        return res
      })
      .catch((error) => {
        dispatch(onHandleLoading(false))
        return error.response
      })
  }
}
