import { AppState, Auth0Provider } from '@auth0/auth0-react'
import React, { ReactChild, ReactChildren } from 'react'
import { useNavigate } from 'react-router-dom'
import { getUserProfile } from '../redux/actions/userProfil.action'
import { useAppDispatch } from '../redux/store'

interface AuxProps {
  children: ReactChild | ReactChildren
}

export function Auth0ProviderWithHistory({ children }: AuxProps): JSX.Element | null {
  /* -------------------------------------------------------------------------- */
  /*                                    HOOKS                                   */
  /* -------------------------------------------------------------------------- */
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  /* -------------------------------------------------------------------------- */
  /*                                  CONSTANTS                                 */
  /* -------------------------------------------------------------------------- */
  const domain: string | undefined = process.env.REACT_APP_AUTH0_DOMAIN
  const clientId: string | undefined = process.env.REACT_APP_AUTH0_CLIENT_ID
  const audience: string | undefined = process.env.REACT_APP_AUTH0_AUDIENCE
  /* -------------------------------------------------------------------------- */
  /*                                   HELPERS                                  */
  /* -------------------------------------------------------------------------- */
  const onRedirectCallback = (appState?: AppState) => {
    dispatch(getUserProfile()).then((res) => localStorage.setItem('user', JSON.stringify(res.data)))
    navigate(appState?.returnTo || window.location.pathname)
  }

  if (!(domain && clientId)) {
    return null
  }
  /* -------------------------------------------------------------------------- */
  /*                                   RENDER                                   */
  /* -------------------------------------------------------------------------- */
  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      redirectUri={window.location.origin}
      audience={audience}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  )
}
