export const GET_USER_SUCCESS = 'GET_USER_SUCCESS'
export const GET_USER_LOADING = 'GET_USER_LOADING'
export const GET_PROFILE_USER_SUCCESS = 'GET_PROFILE_USER_SUCCESS'
export const GET_ADVISOR_LOADING = 'GET_ADVISOR_LOADING'
export const GET_DOCUMENTS_LOADING = 'GET_DOCUMENTS_LOADING'
export const GET_DOCUMENTS_SUCCESS = 'GET_DOCUMENTS_SUCCESS'
export const LOADING_APP = 'LOADING_APP'
export const DELETE_DOCUMENT_SUCCESS = 'DELETE_DOCUMENT_SUCCESS'
export const ADD_DOCUMENT_SUCCESS = 'ADD_DOCUMENT_SUCCESS'
export const GET_MORTGAGES_SUCCESS = 'GET_MORTGAGES_SUCCESS'
export const GET_MORTGAGES_LOADING = 'GET_MORTGAGES_LOADING'
export const GET_ADVISOR_PERMISSIONS_SUCCESS = 'GET_ADVISOR_PERMISSIONS_SUCCESS'
export const UPDATE_ADVISOR_PERMISSIONS_SUCCESS = 'UPDATE_ADVISOR_PERMISSIONS_SUCCESS'
export const ADD_MORTGAGE_SUCCESS = 'ADD_MORTGAGE_SUCCESS'
export const GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS'
export const GET_NOTIFICATIONS_LOADING = 'GET_NOTIFICATIONS_LOADING'
