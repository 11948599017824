let getAccessTokenSilently: any

function setAccessToken(func: any) {
  getAccessTokenSilently = func
}

function getAccessToken(): Promise<string> {
  return getAccessTokenSilently()
}
export const handleAccessToken = {
  getAccessTokenSilently: getAccessToken,
  setAccessTokenSilently: setAccessToken,
}
