import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Modal,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  Snackbar,
  TextField,
  Typography,
} from '@mui/material'
import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Client, NotificationSearchQuery, ProductType } from '../../api'
import { snackBarProps } from '../../helper/iTypes'
import { getAdvisorClients } from '../../redux/actions/advisor.action'
import { getNotifications, sendNotifications } from '../../redux/actions/notifications.actions'
import { getUsers } from '../../redux/actions/users.action'
import { RootState, useAppDispatch } from '../../redux/store'
import Loader from '../loader'

interface IModalSendNotification {
  filterValue: NotificationSearchQuery
  toProfileIdDefault?: number
}
interface ISendNotification {
  toProfileId: number | null | undefined | string
  productType: string | null
  isBroadcast: boolean
  message: string
}
/* -------------------------------------------------------------------------- */
/*                                  COMPONENT                                 */
/* -------------------------------------------------------------------------- */
export function ModalSendNotification({
  filterValue,
  toProfileIdDefault,
}: Readonly<IModalSendNotification>) {
  /* -------------------------------------------------------------------------- */
  /*                                  CONSTANTS                                 */
  /* -------------------------------------------------------------------------- */
  const dispatch = useAppDispatch()
  const [open, setOpen] = React.useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const { t } = useTranslation()
  const [snackBar, setSnackBar] = React.useState<snackBarProps>({
    open: false,
    message: '',
    severity: 'success',
  })
  const isAdmin = useSelector((state: RootState) => state.userProfileReducer.admin)
  const loading = useSelector((state: RootState) => state.loadingReducer.loading)
  const [productType, setProductType] = useState<ProductType | null>(ProductType.General)
  const [message, setMessage] = useState('')
  const [toProfileId, setToProfileId] = useState(toProfileIdDefault)
  const [advisorClients, setAdvisorClients] = useState<Client[]>()
  const [clientsCategory, setClientsCategory] = React.useState<string>(
    !isAdmin || toProfileIdDefault ? 'one' : 'all',
  )
  const [btnCheck, setBtnCheck] = React.useState<boolean>(false)
  const isBroadcast = useMemo(() => true, [])
  const handleCloseSnackbar = (_event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }

    setSnackBar({
      open: false,
      message: '',
      severity: 'success',
    })
  }
  const userProfile = useSelector((state: RootState) => state.userProfileReducer)
  /* -------------------------------------------------------------------------- */
  /*                                   HELPERS                                  */
  /* -------------------------------------------------------------------------- */
  const notifData: ISendNotification = {
    toProfileId,
    productType,
    isBroadcast,
    message,
  }
  // eslint-disable-next-line
  const changeClientCategory = (data: any) => {
    const datatoSend = { ...data }
    if (clientsCategory === 'all') {
      datatoSend.productType = null
      datatoSend.toProfileId = null
    } else if (clientsCategory === 'allCategory') {
      datatoSend.toProfileId = null
    } else if (clientsCategory === 'one') {
      datatoSend.isBroadcast = false
    }
    return datatoSend
  }
  const checkClientCategory = () => {
    if (clientsCategory === 'one' && !toProfileId) {
      setBtnCheck(true)
    } else {
      setBtnCheck(false)
    }
  }
  // eslint-disable-next-line
  const onSendNotifications = (data: any) => {
    const modifiedDataToSend = changeClientCategory(data)
    dispatch(sendNotifications(modifiedDataToSend)).then((res) => {
      if (res?.status === 200) {
        setSnackBar({
          open: true,
          message: t('forms.notification.send_notification'),
          severity: 'success',
        })
        setOpen(false)
        dispatch(getNotifications(filterValue))
      } else {
        setSnackBar({
          open: true,
          message: t(`errors.${res.status}`),
          severity: 'error',
        })
      }
    })
  }
  /* ---------------------------------------------------------------------------- */
  /*                                   HANDLERS                                   */
  /* ---------------------------------------------------------------------------- */
  const handleChangeProductType = (event: SelectChangeEvent) => {
    setProductType(event.target.value as ProductType)
  }
  const handleChangeMessage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMessage(event.target.value)
  }
  const handleChangeToProfileId = (event: SelectChangeEvent) => {
    setToProfileId(event.target.value as unknown as number)
  }
  const handleChangeClientsCategory = (event: React.ChangeEvent<HTMLInputElement>) => {
    setClientsCategory(event.target.value)
  }
  /* -------------------------------------------------------------------------- */
  /*                                   RENDER                                   */
  /* -------------------------------------------------------------------------- */
  React.useEffect(() => {
    if (isAdmin) {
      dispatch(getUsers({ search: { profileType: 'CLIENT' }, pageIndex: 0, pageSize: 1000 })).then(
        ({ data: { content } }) => {
          setAdvisorClients(content)
        },
      )
    } else {
      dispatch(getAdvisorClients(userProfile.id)).then((res) => {
        if (res.status === 200) {
          setAdvisorClients(res.data)
        } else {
          setSnackBar({
            open: true,
            message: t(`errors.${res.status}`),
            severity: 'error',
          })
        }
      })
    }
  }, []) // eslint-disable-line

  React.useEffect(() => {
    checkClientCategory()
  }, [clientsCategory, toProfileId]) // eslint-disable-line

  return (
    <>
      <Button color="primary" variant="contained" onClick={handleOpen}>
        {t('forms.notification.send_notification')}
      </Button>
      <Loader loading={loading} />
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={snackBar.open}
        onClose={handleCloseSnackbar}
        autoHideDuration={3000}
      >
        <Alert onClose={handleCloseSnackbar} variant="filled" severity={snackBar?.severity}>
          {snackBar.message}
        </Alert>
      </Snackbar>

      <Modal open={open} onClose={handleClose} className="modal">
        <Container>
          <Card>
            <Divider />
            <CardHeader title={t('forms.notification.new_notification')} />
            <Divider />

            <CardContent sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Grid container spacing={3} justifyContent="center" alignItems="center">
                <Grid item md={12} xs={12}>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Box
                      sx={{
                        width: 200,
                        display: { xs: 'none', md: 'block' },
                      }}
                    >
                      <Typography
                        sx={{
                          display: { xs: 'none', md: 'block' },
                        }}
                      >
                        {t('forms.notification.recipients')}
                      </Typography>
                    </Box>
                    <FormControl sx={{ width: 500, marginLeft: 1, marginBottom: 2 }}>
                      <RadioGroup value={clientsCategory} onChange={handleChangeClientsCategory}>
                        <FormControlLabel
                          value="all"
                          control={<Radio />}
                          label={t('forms.notification.all_clients')}
                          disabled={!isAdmin}
                        />
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'start',
                            flexWrap: { xs: 'wrap', md: 'nowrap' },
                          }}
                        >
                          <FormControlLabel
                            value="allCategory"
                            control={<Radio />}
                            label={t('forms.notification.category_clients')}
                            disabled={!isAdmin}
                          />
                          <FormControl sx={{ width: 250, marginLeft: 1, marginBottom: 2 }}>
                            <Select
                              value={productType as string}
                              onChange={handleChangeProductType}
                              disabled={clientsCategory !== 'allCategory'}
                            >
                              {Object.entries(ProductType).map(([key, value]) => (
                                <MenuItem value={value} key={key}>
                                  {t(`product_types.${key}`)}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Box>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'start',
                            flexWrap: { xs: 'wrap', md: 'nowrap' },
                          }}
                        >
                          <FormControlLabel
                            value="one"
                            control={<Radio />}
                            label={t('forms.notification.one_client')}
                          />
                          <FormControl sx={{ width: 250, marginLeft: 1, marginBottom: 2 }}>
                            <Select
                              value={toProfileId as unknown as string}
                              onChange={handleChangeToProfileId}
                              disabled={clientsCategory !== 'one'}
                            >
                              {advisorClients?.map((client) => (
                                <MenuItem
                                  value={client.id}
                                  key={client.id}
                                >{`${client.firstName} ${client.lastName}`}</MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Box>
                      </RadioGroup>
                    </FormControl>
                  </Box>
                </Grid>

                <Grid item md={12} xs={12}>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Box
                      sx={{
                        width: 200,
                        display: { xs: 'none', md: 'block' },
                      }}
                    >
                      <Typography
                        sx={{
                          display: { xs: 'none', md: 'block' },
                        }}
                      >
                        {t('forms.notification.notification')}
                      </Typography>
                    </Box>
                    <TextField
                      onChange={handleChangeMessage}
                      sx={{ width: 500, marginLeft: 1 }}
                      id="outlined-multiline-static"
                      multiline
                      rows={4}
                      placeholder="Text"
                    />
                  </Box>
                </Grid>
              </Grid>
            </CardContent>

            {/* Buttons */}
            <Divider />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                p: 2,
              }}
            >
              <Button color="primary" variant="contained" onClick={() => handleClose()}>
                {t('common.cancel')}
              </Button>
              <Button
                onClick={() => onSendNotifications(notifData)}
                color="primary"
                variant="contained"
                sx={{ ml: 1 }}
                disabled={btnCheck}
              >
                {t('common.send')}
              </Button>
            </Box>
            {btnCheck && <Alert severity="error">{t('errors.required_client')}</Alert>}
          </Card>
        </Container>
      </Modal>
    </>
  )
}

ModalSendNotification.defaultProps = {
  toProfileIdDefault: '',
}
