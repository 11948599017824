import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import TRANSLATIONS_FR from './translations/fr.json'

i18n.use(initReactI18next).init({
  fallbackLng: 'fr',
  lng: 'fr',
  resources: {
    fr: {
      common: TRANSLATIONS_FR,
    },
  },
  ns: ['common'],
  defaultNS: 'common',
  debug: process.env.NODE_ENV !== 'production',
})

export default i18n
