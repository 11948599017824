import { Avatar, Box, Typography } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../redux/store'

export default function UserInformation() {
  /* -------------------------------------------------------------------------- */
  /*                                    HOOKS                                   */
  /* -------------------------------------------------------------------------- */
  const user = useSelector((state: RootState) => state.userProfileReducer)

  /* -------------------------------------------------------------------------- */
  /*                                   RENDER                                   */
  /* -------------------------------------------------------------------------- */
  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        py: 2,
      }}
    >
      <Avatar
        src={user?.picture}
        sx={{
          height: 50,
          mb: 2,
          width: 50,
        }}
      />
      <Typography color="textPrimary" gutterBottom variant="h5">
        {user?.firstName} {user?.lastName}
      </Typography>
      <Typography color="textSecondary" variant="body2">
        {user?.email}
      </Typography>
    </Box>
  )
}
