import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useParams } from 'react-router-dom'
import { AdvisorType, Assignment, ProfileSearchResultContent } from '../../api'
import { advisorTypes } from '../../helper/constants'
import SelectSingleAdvisorInput from '../selectSingleAdvisorInput'

interface StateType {
  userID: number
}

interface TransferClientsProps {
  open: boolean
  handleClose: () => void
  types: string[]
  setTransferTo?: (values: Assignment[] | []) => void
}

export default function TransferClients({
  open,
  handleClose,
  types,
  setTransferTo,
}: TransferClientsProps) {
  const { t } = useTranslation()
  const location = useLocation()
  const params = useParams()

  const stateLocation = location?.state as StateType
  const userID = stateLocation?.userID ?? params.id
  const [selectedInvestmentAdvisor, setSelectedInvestmentAdvisor] =
    React.useState<ProfileSearchResultContent | null>(null)
  const [selectedInsuranceAdvisor, setSelectedInsuranceAdvisor] =
    React.useState<ProfileSearchResultContent | null>(null)
  const [selectedMortgageAdvisor, setSelectedMortgageAdvisor] =
    React.useState<ProfileSearchResultContent | null>(null)
  const [selectedAccountingAdvisor, setSelectedAccountingAdvisor] =
    React.useState<ProfileSearchResultContent | null>(null)

  const selectAdvisor = (type: string) => {
    switch (type) {
      case 'INVESTMENT':
        return selectedInvestmentAdvisor
      case 'INSURANCE':
        return selectedInsuranceAdvisor
      case 'MORTGAGE':
        return selectedMortgageAdvisor
      case 'ACCOUNTING':
        return selectedAccountingAdvisor
      default:
        return null
    }
  }

  const setSelectAdvisor = (type: string) => {
    switch (type) {
      case 'INVESTMENT':
        return setSelectedInvestmentAdvisor
      case 'INSURANCE':
        return setSelectedInsuranceAdvisor
      case 'MORTGAGE':
        return setSelectedMortgageAdvisor
      default:
        return setSelectedAccountingAdvisor
    }
  }

  const resetSelectedAdvisors = () => {
    setSelectedInvestmentAdvisor(null)
    setSelectedInsuranceAdvisor(null)
    setSelectedMortgageAdvisor(null)
    setSelectedAccountingAdvisor(null)
  }

  const saveTransferClientsTo = () => {
    const transferClientsTo: Assignment[] = []

    advisorTypes.forEach((advisorType) => {
      const selectedAdvisor = selectAdvisor(advisorType.value)

      if (selectedAdvisor) {
        transferClientsTo.push({
          advisorType: advisorType.value as AdvisorType,
          advisorId: selectedAdvisor.id,
        })
      }
    })

    if (setTransferTo) {
      setTransferTo(transferClientsTo)
    }

    resetSelectedAdvisors()
    handleClose()
  }

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={handleClose}
      sx={{
        '& .MuiDialogTitle-root': {
          paddingX: 6,
          paddingTop: 6,
        },
        '& .MuiDialogContent-root': {
          paddingX: 6,
        },
        '& .MuiDialogActions-root': {
          paddingX: 6,
          paddingBottom: 6,
        },
      }}
    >
      <DialogTitle paddingX={6} paddingTop={6} paddingBottom={2}>
        {t('forms.transfer_clients.title')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t('forms.transfer_clients.text1')}
          <br />
          <br />
          {t('forms.transfer_clients.text2')}
        </DialogContentText>

        {advisorTypes.map(
          (advisorType) =>
            types.includes(advisorType.value) && (
              <SelectSingleAdvisorInput
                key={advisorType.value}
                label={advisorType.label}
                excludedAdvisor={[userID]}
                typeFilter={advisorType.value}
                selectedAdvisor={selectAdvisor(advisorType.value)}
                setSelectedAdvisor={setSelectAdvisor(advisorType.value)}
              />
            ),
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          {t('forms.transfer_clients.not_reassign')}
        </Button>
        <Button variant="contained" onClick={saveTransferClientsTo}>
          {t('forms.transfer_clients.reassign')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

TransferClients.defaultProps = {
  setTransferTo: undefined,
}
