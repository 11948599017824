import { Add } from '@mui/icons-material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Modal from '@mui/material/Modal'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { ProfileSearchResultContent } from '../../api'
import { RootState } from '../../redux/store'
import SelectAdvisors from './seletctAdvisorsInput'

const style = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  width: '90%',
  maxWidth: '568px',
}

interface GetAdvisorsModalProps {
  handleAdvisorsList: (value: Array<ProfileSearchResultContent>) => void
  advisorList: Array<ProfileSearchResultContent>
}

export default function GetAdvisorsModal({
  handleAdvisorsList,
  advisorList,
}: GetAdvisorsModalProps) {
  const [newAdvisors, setNewAdvisors] = React.useState<Array<ProfileSearchResultContent>>([])
  const [open, setOpen] = React.useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => {
    setOpen(false)
    setNewAdvisors([])
  }
  const isAdmin = useSelector((state: RootState) => state.userProfileReducer.admin)
  const { t } = useTranslation()
  return (
    <div>
      {isAdmin && (
        <Button variant="contained" onClick={handleOpen} startIcon={<Add />}>
          {t('common.add_advisor')}
        </Button>
      )}
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <SelectAdvisors
            advisorList={advisorList}
            newAdvisors={newAdvisors}
            setNewAdvisors={setNewAdvisors}
          />

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mt: 4,
            }}
          >
            <Button color="primary" variant="contained" onClick={() => handleClose()}>
              {t('common.cancel')}
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                handleAdvisorsList(newAdvisors)
                handleClose()
              }}
              sx={{ ml: 1 }}
            >
              {t('common.add')}
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  )
}
