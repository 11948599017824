import { Configuration } from '../../api'
import { AdvisorApi, AdvisorPermissionsPut } from '../../api/api'
import { handleAccessToken } from '../../services/config'
import { GET_ADVISOR_PERMISSIONS_SUCCESS, UPDATE_ADVISOR_PERMISSIONS_SUCCESS } from '../actionTypes'
import { AppDispatch } from '../store'
import { onHandleLoading } from './loading.action'

export function getAdvisorPermissionsSuccess(data: any) {
  return {
    type: GET_ADVISOR_PERMISSIONS_SUCCESS,
    payload: data,
  }
}

export function updateAdvisorPermissionsSuccess(data: any) {
  return {
    type: UPDATE_ADVISOR_PERMISSIONS_SUCCESS,
    payload: data,
  }
}

export function getAdvisorPermissions(id: number) {
  return async (dispatch: AppDispatch) => {
    dispatch(onHandleLoading(true))
    const token = await handleAccessToken.getAccessTokenSilently()
    const advisorApi = new AdvisorApi(
      new Configuration({
        basePath: `${process.env.REACT_APP_BACKEND_API_URL}`,
        accessToken: token,
      }),
    )
    return advisorApi
      .getAdvisorPermissions(id, 'fr')
      .then((res) => {
        dispatch(getAdvisorPermissionsSuccess(res.data))
        dispatch(onHandleLoading(false))
        return res
      })
      .catch((error) => {
        dispatch(onHandleLoading(false))
        return error.response
      })
  }
}

export function updateAdvisorPermissions(id: number, permissions: AdvisorPermissionsPut) {
  return async (dispatch: AppDispatch) => {
    dispatch(onHandleLoading(true))
    const token = await handleAccessToken.getAccessTokenSilently()
    const advisorApi = new AdvisorApi(
      new Configuration({
        basePath: `${process.env.REACT_APP_BACKEND_API_URL}`,
        accessToken: token,
      }),
    )
    return advisorApi
      .updateAdvisorPermissions(id, 'fr', permissions)
      .then((res) => {
        dispatch(updateAdvisorPermissionsSuccess(res.data))
        dispatch(onHandleLoading(false))
        return res
      })
      .catch((error) => {
        dispatch(onHandleLoading(false))
        return error.response
      })
  }
}
