import { Configuration } from '../../api'
import { AdvisorApi, AdvisorPost, AdvisorPut, ReassignClients } from '../../api/api'
import { handleAccessToken } from '../../services/config'
import { AppDispatch } from '../store'
import { onHandleLoading } from './loading.action'

export function getAdvisor(id: number) {
  return async (dispatch: AppDispatch) => {
    dispatch(onHandleLoading(true))
    const token = await handleAccessToken.getAccessTokenSilently()
    const getOneAdvisor = new AdvisorApi(
      new Configuration({
        basePath: `${process.env.REACT_APP_BACKEND_API_URL}`,
        accessToken: token,
      }),
    )
    return getOneAdvisor
      .getAdvisor(id, 'fr')
      .then((res) => {
        dispatch(onHandleLoading(false))
        return res
      })
      .catch((error) => {
        dispatch(onHandleLoading(false))
        return error.response
      })
  }
}

export function addAdvisor(data: AdvisorPost) {
  return async (dispatch: AppDispatch) => {
    const token = await handleAccessToken.getAccessTokenSilently()
    dispatch(onHandleLoading(true))
    const advisorApi = new AdvisorApi(
      new Configuration({
        basePath: `${process.env.REACT_APP_BACKEND_API_URL}`,
        accessToken: token,
      }),
    )

    const advisorPost: AdvisorPost = {
      email: data.email,
      firstName: data.firstName,
      lastName: data.lastName,
      locale: data.locale,
      communicationLocale: data.communicationLocale,
      types: data.types,
      phoneNumber: data.phoneNumber,
      clientIds: data.clientIds ?? [],
      admin: data.admin,
    }
    return advisorApi
      .createAdvisor('fr', advisorPost)
      .then((res) => {
        dispatch(onHandleLoading(false))
        return res
      })
      .catch((error) => {
        dispatch(onHandleLoading(false))
        return error.response
      })
  }
}

export function updateUserAdvisor(id: number, data: AdvisorPut) {
  return async (dispatch: AppDispatch) => {
    dispatch(onHandleLoading(true))
    const token = await handleAccessToken.getAccessTokenSilently()
    const advisorApi = new AdvisorApi(
      new Configuration({
        basePath: `${process.env.REACT_APP_BACKEND_API_URL}`,
        accessToken: token,
      }),
    )
    const advisorPut: AdvisorPut = {
      email: data.email,
      firstName: data.firstName,
      lastName: data.lastName,
      locale: data.locale,
      communicationLocale: data.communicationLocale,
      types: data.types,
      phoneNumber: data.phoneNumber,
      clientIds: data.clientIds ?? [],
      admin: data.admin,
      active: data.active,
      inactiveReason: data.inactiveReason,
    }

    return advisorApi
      .updateAdvisor(id, 'fr', advisorPut)
      .then((res) => {
        dispatch(onHandleLoading(false))
        return res
      })
      .catch((error) => {
        dispatch(onHandleLoading(false))
        return error.response
      })
  }
}

export function transferAdvisorClients(id: number, data: ReassignClients) {
  return async (dispatch: AppDispatch) => {
    dispatch(onHandleLoading(true))
    const token = await handleAccessToken.getAccessTokenSilently()
    const advisorApi = new AdvisorApi(
      new Configuration({
        basePath: `${process.env.REACT_APP_BACKEND_API_URL}`,
        accessToken: token,
      }),
    )
    const reassignClients: ReassignClients = {
      clientIds: data.clientIds,
      newAssignments: data.newAssignments,
    }

    return advisorApi
      .reassignAdvisorClients(id, 'fr', reassignClients)
      .then((res) => {
        dispatch(onHandleLoading(false))
        return res
      })
      .catch((error) => {
        dispatch(onHandleLoading(false))
        return error.response
      })
  }
}

export function getAdvisorClients(id: number) {
  return async (dispatch: AppDispatch) => {
    dispatch(onHandleLoading(true))
    const token = await handleAccessToken.getAccessTokenSilently()
    const getOneAdvisor = new AdvisorApi(
      new Configuration({
        basePath: `${process.env.REACT_APP_BACKEND_API_URL}`,
        accessToken: token,
      }),
    )
    return getOneAdvisor
      .getAllClientsForAdvisor(id, 'fr')
      .then((res) => {
        dispatch(onHandleLoading(false))
        return res
      })
      .catch((error) => {
        dispatch(onHandleLoading(false))
        return error.response
      })
  }
}
