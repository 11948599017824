import { Auth0ContextInterface, useAuth0, User } from '@auth0/auth0-react'
import { AccountCircle, Logout } from '@mui/icons-material'
import MenuIcon from '@mui/icons-material/Menu'
import { AppBar, Box, Button, IconButton, Menu, MenuItem, Toolbar, Typography } from '@mui/material'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Profile, ProfileType } from '../../api'
import { drawerWidth } from '../../helper/constants'

interface HeaderProps {
  onClose: () => void
  headerTitle: string
}
export default function HeaderMenu({ onClose, headerTitle }: HeaderProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const { logout }: Auth0ContextInterface<User> = useAuth0()
  const handleClose = () => {
    setAnchorEl(null)
  }
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const userInfo: Profile = JSON.parse(localStorage.getItem('user')!)
  const navigate = useNavigate()
  const { t } = useTranslation()
  const handleNavigateProfile = () => {
    if (userInfo.profileType === ProfileType.Advisor) {
      navigate(`/advisor/${userInfo.id}`)
    } else {
      navigate(`/client/${userInfo.id}`)
    }
    handleClose()
  }
  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar style={{ justifyContent: 'space-between' }}>
          <div>
            <IconButton
              color="inherit"
              edge="start"
              onClick={onClose}
              sx={{ mr: 2, display: { sm: 'none' } }}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap component="div">
              {headerTitle ?? t('common.admin_console')}
            </Typography>
          </div>
          <div>
            <IconButton
              size="large"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={handleNavigateProfile}>{t('common.my_profile')}</MenuItem>
              <MenuItem onClick={handleClose}>
                <Button
                  variant="outlined"
                  endIcon={<Logout />}
                  onClick={() => {
                    localStorage.removeItem('user')
                    logout({
                      client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
                      returnTo: window.location.origin,
                    })
                  }}
                >
                  {t('common.logout')}
                </Button>
              </MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
    </Box>
  )
}
