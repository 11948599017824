/* -------------------------------------------------------------------------- */
/*                                   IMPORTS                                  */
/* -------------------------------------------------------------------------- */
import { useAuth0, User, withAuthenticationRequired } from '@auth0/auth0-react'
import React, { ComponentType } from 'react'
import { RoleType } from '../api'
import Loader from '../components/loader'
/* -------------------------------------------------------------------------- */
/*                                  INTERFACE                                 */
/* -------------------------------------------------------------------------- */
interface ProtectedRouteProps {
  component: ComponentType
  adminOnly?: boolean
}

const audience: string | undefined = process.env.REACT_APP_AUTH0_AUDIENCE
const rolesClaimKey = `${audience}/roles`

export default function ProtectedRoute({ component, adminOnly }: ProtectedRouteProps): JSX.Element {
  const acceptedRoles = adminOnly
    ? [RoleType.Admin.toUpperCase()]
    : [RoleType.Admin.toUpperCase(), RoleType.Advisor.toUpperCase()]

  const { isLoading, logout } = useAuth0()

  const loader = () => <Loader loading={isLoading} />

  const checkRoles = (user?: User) => {
    if (user && user[rolesClaimKey] && Array.isArray(user[rolesClaimKey])) {
      const usersRoles = user[rolesClaimKey] as string[]
      const hasRoles = acceptedRoles.some((acceptedRole) => usersRoles.includes(acceptedRole))
      if (!hasRoles) {
        // TODO forward to a page saying the user doesn't have the good role
        logout({
          client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
          returnTo: window.location.origin,
        })
      }
    }
    return true
  }

  const Component = withAuthenticationRequired(component, {
    onRedirecting: loader,
    claimCheck: checkRoles,
  })
  /* -------------------------------------------------------------------------- */
  /*                                   RENDER                                   */
  /* -------------------------------------------------------------------------- */
  return <Component />
}

ProtectedRoute.defaultProps = {
  adminOnly: false,
}
