import { PersonAddAlt } from '@mui/icons-material'
import { Alert, Button, Container, Modal, Snackbar } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { AdvisorPost, Locale } from '../../../../api'
import { UserForm } from '../../../../components/Forms/signupUser/formUser'
import { snackBarProps } from '../../../../helper/iTypes'
import { addAdvisor } from '../../../../redux/actions/advisor.action'
import { useAppDispatch } from '../../../../redux/store'
import '../../styles/index.css'

interface IProps {
  reload: boolean
  setReload: React.Dispatch<React.SetStateAction<boolean>>
}
function AddAdvisorModal({ reload, setReload }: IProps) {
  const dispatch = useAppDispatch()
  /* -------------------------------------------------------------------------- */
  /*                                    HOOKS                                   */
  /* -------------------------------------------------------------------------- */
  const [open, setOpen] = React.useState(false)
  const [snackBar, setSnackBar] = React.useState<snackBarProps>({
    open: false,
    message: '',
    severity: 'success',
  })
  const navigate = useNavigate()
  const { t } = useTranslation()
  /* -------------------------------------------------------------------------- */
  /*                                  CONSTANTS                                 */
  /* -------------------------------------------------------------------------- */
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const initialValues: AdvisorPost = {
    firstName: '',
    lastName: '',
    email: '',
    admin: false,
    locale: Locale.Fr,
    communicationLocale: Locale.Fr,
    phoneNumber: '',
    clientIds: [],
    types: [],
  }

  const handleCloseSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }

    setSnackBar({
      open: false,
      message: '',
      severity: 'success',
    })
  }
  /* -------------------------------------------------------------------------- */
  /*                                   FORMIK                                   */
  /* -------------------------------------------------------------------------- */
  function onSubmitForm(values: AdvisorPost) {
    dispatch(addAdvisor(values)).then((res) => {
      if (res?.status === 201) {
        setSnackBar({
          open: true,
          message: t('add_user.success_add_msg'),
          severity: 'success',
        })
        setReload(!reload)
        setOpen(false)
        navigate(`/advisor/${res.data.id}`, {
          state: {
            userID: res.data.id,
          },
        })
      } else {
        setSnackBar({
          open: true,
          message: t(`errors.${res.status}`),
          severity: 'error',
        })
      }
    })
  }
  /* -------------------------------------------------------------------------- */
  /*                                   RENDER                                   */
  /* -------------------------------------------------------------------------- */
  return (
    <div>
      <Button onClick={handleOpen} startIcon={<PersonAddAlt />}>
        {t('add_user.adding_advisor')}
      </Button>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={snackBar.open}
        onClose={handleCloseSnackbar}
        autoHideDuration={4000}
      >
        <Alert onClose={handleCloseSnackbar} variant="filled" severity={snackBar?.severity}>
          {snackBar.message}
        </Alert>
      </Snackbar>
      <Modal open={open} onClose={handleClose} className="modal">
        <Container>
          <UserForm
            submitLabel={t('add_user.add_advisor')}
            initialValues={initialValues}
            onSubmitForm={(values) => onSubmitForm(values as AdvisorPost)}
            profileType="ADVISOR"
            onCancel={handleClose}
          />
        </Container>
      </Modal>
    </div>
  )
}

export default AddAdvisorModal
