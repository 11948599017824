import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import { FormikProvider, useFormik } from 'formik'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { AdvisorPost, AdvisorPut, ClientPost, ClientPut, ProfileType } from '../../../api'
import { options } from '../../../helper/constants'
// import { userForm } from '../../../helper/formik'
import { AdvisorSchema, ClientSchema } from '../../../helper/validationSchema'
import { RootState } from '../../../redux/store'
import Loader from '../../loader'
/* -------------------------------------------------------------------------- */
/*                                  COMPONENT                                 */
/* -------------------------------------------------------------------------- */

type InitialValues = AdvisorPost | AdvisorPut | ClientPut | ClientPost

interface FormSignup {
  submitLabel: string
  onSubmitForm: (values: InitialValues) => void
  onCancel: () => void
  profileType: string
  initialValues: InitialValues
}

export function UserForm({
  submitLabel,
  initialValues,
  onSubmitForm,
  profileType,
  onCancel,
}: FormSignup) {
  /* -------------------------------------------------------------------------- */
  /*                                  CONSTANTS                                 */
  /* -------------------------------------------------------------------------- */
  const loading = useSelector((state: RootState) => state.loadingReducer.loading)
  const { t } = useTranslation()
  /* -------------------------------------------------------------------------- */
  /*                                   FORMIK                                   */
  /* -------------------------------------------------------------------------- */

  const FormikUserForm = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: (values) => {
      if (ProfileType.Advisor) {
        const castValues = AdvisorSchema.cast(values)
        onSubmitForm(castValues as AdvisorPost)
      } else {
        const castValues = ClientSchema.cast(values)
        onSubmitForm(castValues as ClientPost)
      }
    },
    validationSchema: profileType === ProfileType.Advisor ? AdvisorSchema : ClientSchema,
  })
  /* -------------------------------------------------------------------------- */
  /*                                   RENDER                                   */
  /* -------------------------------------------------------------------------- */

  return (
    <FormikProvider value={FormikUserForm}>
      <Loader loading={loading} />

      <Card>
        <CardHeader subheader={t('add_user.card_header')} title={submitLabel} />
        <Divider />

        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label={t('forms.labels.first_name')}
                name="firstName"
                onChange={FormikUserForm.handleChange}
                required
                value={FormikUserForm.values.firstName}
                variant="outlined"
                helperText={
                  FormikUserForm.errors.firstName &&
                  FormikUserForm.touched.firstName &&
                  FormikUserForm.errors.firstName
                }
                error={!!(FormikUserForm.errors.firstName && FormikUserForm.touched.firstName)}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label={t('forms.labels.last_name')}
                name="lastName"
                onChange={FormikUserForm.handleChange}
                required
                value={FormikUserForm.values.lastName}
                variant="outlined"
                helperText={
                  FormikUserForm.errors.lastName &&
                  FormikUserForm.touched.lastName &&
                  FormikUserForm.errors.lastName
                }
                error={!!(FormikUserForm.errors.lastName && FormikUserForm.touched.lastName)}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label={t('forms.labels.email')}
                name="email"
                onChange={FormikUserForm.handleChange}
                required
                value={FormikUserForm.values.email}
                variant="outlined"
                helperText={
                  FormikUserForm.errors.email &&
                  FormikUserForm.touched.email &&
                  FormikUserForm.errors.email
                }
                error={!!(FormikUserForm.errors.email && FormikUserForm.touched.email)}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                required
                label={t('forms.labels.phone_number')}
                name="phoneNumber"
                helperText={
                  FormikUserForm.errors.phoneNumber &&
                  FormikUserForm.touched.phoneNumber &&
                  FormikUserForm.errors.phoneNumber
                }
                error={!!(FormikUserForm.errors.phoneNumber && FormikUserForm.touched.phoneNumber)}
                onChange={FormikUserForm.handleChange}
                value={FormikUserForm.values.phoneNumber}
                variant="outlined"
              />
            </Grid>
            {profileType === ProfileType.Advisor && (
              <Grid item md={12} xs={12}>
                <FormControlLabel
                  label={t('forms.labels.admin') as string}
                  control={
                    <Checkbox
                      name="admin"
                      checked={(FormikUserForm?.values as AdvisorPost)?.admin ?? false}
                      onChange={FormikUserForm.handleChange}
                    />
                  }
                />
              </Grid>
            )}
            <Grid item md={12} xs={12}>
              {profileType === ProfileType.Advisor ? (
                <FormGroup>
                  <Typography>{t('forms.labels.advisor_type')}</Typography>

                  <FormLabel
                    error={
                      !!(
                        (FormikUserForm.errors as AdvisorPost).types &&
                        (FormikUserForm.touched as unknown as AdvisorPost).types
                      )
                    }
                  >
                    {options.map((el) => (
                      <FormControlLabel
                        key={el.value}
                        control={
                          <Checkbox
                            onChange={(e) => {
                              const array: Array<string> =
                                (FormikUserForm.values as AdvisorPost)?.types ?? []
                              if (e.target.checked) {
                                array?.push(el.value)
                                FormikUserForm.setFieldValue('types', array)
                              } else {
                                const index = array?.indexOf(el.value)
                                if (index > -1) {
                                  array?.splice(index, 1)
                                  FormikUserForm.setFieldValue('types', array)
                                }
                              }
                            }}
                            checked={
                              (
                                (FormikUserForm.values as AdvisorPost).types as Array<string>
                              )?.indexOf(el.value) > -1
                            }
                          />
                        }
                        label={el.label}
                      />
                    ))}
                  </FormLabel>
                </FormGroup>
              ) : (
                <TextField
                  fullWidth
                  label={t('forms.labels.crm_code')}
                  required
                  name="crmCode"
                  helperText={
                    (FormikUserForm.errors as ClientPost | ClientPut).crmCode &&
                    (FormikUserForm.touched as unknown as ClientPost | ClientPut).crmCode &&
                    (FormikUserForm.errors as ClientPost | ClientPut).crmCode
                  }
                  error={
                    !!(
                      (FormikUserForm.errors as ClientPost | ClientPut).crmCode &&
                      (FormikUserForm.touched as unknown as ClientPost | ClientPut).crmCode
                    )
                  }
                  onChange={FormikUserForm.handleChange}
                  value={(FormikUserForm.values as ClientPost | ClientPut).crmCode}
                  variant="outlined"
                />
              )}
            </Grid>
            <Grid item md={6} xs={12}>
              <FormControl fullWidth>
                <InputLabel>{t('forms.labels.language')}</InputLabel>
                <Select
                  value={FormikUserForm.values.locale || 'fr'}
                  name="locale"
                  label="Site Language"
                  onChange={FormikUserForm.handleChange}
                  onBlur={FormikUserForm.handleBlur}
                >
                  <MenuItem value="fr">{t('forms.languages.french')}</MenuItem>
                  <MenuItem value="en">{t('forms.languages.english')}</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={6} xs={12}>
              <FormControl fullWidth>
                <InputLabel>{t('forms.labels.communication_language')}</InputLabel>
                <Select
                  value={FormikUserForm.values.communicationLocale || 'fr'}
                  name="communicationLocale"
                  label="Communication Language"
                  onChange={FormikUserForm.handleChange}
                  onBlur={FormikUserForm.handleBlur}
                >
                  <MenuItem value="fr">{t('forms.languages.french')}</MenuItem>
                  <MenuItem value="en">{t('forms.languages.english')}</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            p: 2,
          }}
        >
          <Button color="primary" variant="contained" onClick={() => onCancel()}>
            {t('common.cancel')}
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => FormikUserForm.handleSubmit()}
            sx={{ ml: 1 }}
          >
            {submitLabel}
          </Button>
        </Box>
      </Card>
    </FormikProvider>
  )
}
