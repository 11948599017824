import { Edit } from '@mui/icons-material'
import { Alert, IconButton, Snackbar } from '@mui/material'
import { DataGrid, frFR, GridColDef } from '@mui/x-data-grid'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  Direction,
  Profile,
  ProfileSearchQuery,
  ProfileSearchQuerySortByEnum,
  ProfileType,
} from '../../api'
import { snackBarProps } from '../../helper/iTypes'
import { getUsers } from '../../redux/actions/users.action'
import { RootState, useAppDispatch } from '../../redux/store'
import FilterPanel from '../filterList/users'
import { Filtres } from '../filterList/users/types'

interface IProps {
  reload: boolean
}
export default function UsersList({ reload }: IProps) {
  const [filterValue, setFilterValue] = React.useState<ProfileSearchQuery>({
    search: {
      profileType: undefined,
      email: undefined,
      firstName: undefined,
      lastName: undefined,
    },
    sortBy: ProfileSearchQuerySortByEnum.LastName,
    sortDir: Direction.Asc,
    pageIndex: 0,
    pageSize: 10,
  })
  const [snackBar, setSnackBar] = React.useState<snackBarProps>({
    open: false,
    message: '',
    severity: 'success',
  })
  const dispatch = useAppDispatch()
  const users = useSelector((state: RootState) => state.usersReducer.users)
  const loading = useSelector((state: RootState) => state.usersReducer.loading)
  const totalElements = useSelector((state: RootState) => state.usersReducer.totalElements)
  const permissions = useSelector((state: RootState) => state.advisorPermissionsReducer)
  const userProfile = useSelector((state: RootState) => state.userProfileReducer)
  const [editBtnStatus, setEditBtnStatus] = React.useState<boolean>(false)

  const handleCloseSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }

    setSnackBar({
      open: false,
      message: '',
      severity: 'success',
    })
  }
  const { t } = useTranslation()
  const navigate = useNavigate()
  const onNavigate = (data: Profile) => {
    if (data.profileType === ProfileType.Advisor) {
      navigate(`/advisor/${data.id}`, {
        state: {
          userID: data.id,
        },
      })
    } else {
      navigate(`/client/${data.id}`, {
        state: {
          userID: data.id,
        },
      })
    }
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const permissionEdit = () => {
    if (
      permissions.clientPermissions.updateContactInformation ||
      permissions.clientPermissions.updateProfile
    ) {
      return setEditBtnStatus(false)
    }
    return setEditBtnStatus(true)
  }
  /* -------------------------------------------------------------------------- */
  /*                                  COSNTANT                                  */
  /* -------------------------------------------------------------------------- */
  const columns: GridColDef[] = [
    {
      field: 'firstName',
      headerName: t('forms.labels.first_name'),
      flex: 1,
      disableColumnMenu: true,
    },
    {
      field: 'lastName',
      headerName: t('forms.labels.last_name'),
      flex: 1,
      disableColumnMenu: true,
    },
    {
      field: 'email',
      headerName: t('forms.labels.email'),
      flex: 1,
      disableColumnMenu: true,
    },
    {
      field: 'profileType',
      headerName: t('forms.labels.role'),
      flex: 1,
      disableColumnMenu: true,
      valueFormatter: (params) => {
        const role = params.value === ProfileType.Advisor ? t('common.advisor') : t('common.client')
        return role
      },
    },
    {
      field: 'Actions',
      headerName: '',
      disableColumnMenu: true,
      sortable: false,
      renderCell: (cellValues) => (
        <IconButton
          disabled={editBtnStatus}
          onClick={() => onNavigate(cellValues.row)}
          color="secondary"
        >
          <Edit />
        </IconButton>
      ),
    },
  ]

  const handleFilters = (values: Filtres) => {
    setFilterValue((prevState: any) => ({
      ...prevState,
      search: {
        profileType: values?.profileType === 'TOUS' ? undefined : values?.profileType,
        email: values?.email ?? undefined,
        firstName: values?.firstName,
        lastName: values?.lastName ?? undefined,
      },
    }))
  }

  const handleChangePage = (page: number) => {
    setFilterValue((prevState) => ({
      ...prevState,
      pageIndex: page,
    }))
  }

  const handleSortChange = (model: any) => {
    setFilterValue((prevState) => ({
      ...prevState,
      sortBy: model[0]?.field ?? ProfileSearchQuerySortByEnum.LastName,
      sortDir: model[0]?.sort === 'desc' ? Direction.Desc : Direction.Asc,
    }))
  }

  React.useEffect(() => {
    dispatch(getUsers(filterValue)).then((res) => {
      if (res?.status !== 200) {
        setSnackBar({
          open: true,
          message: t(`errors.${res.status}`),
          severity: 'error',
        })
      }
    })
  }, [dispatch, filterValue, t, reload])
  React.useEffect(() => {
    permissionEdit()
  }, [permissionEdit, permissions])

  return (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={snackBar.open}
        onClose={handleCloseSnackbar}
        autoHideDuration={4000}
      >
        <Alert onClose={handleCloseSnackbar} variant="filled" severity={snackBar?.severity}>
          {snackBar.message}
        </Alert>
      </Snackbar>
      <FilterPanel handleFilters={(values) => handleFilters(values)} />
      <DataGrid
        density="compact"
        rows={users ?? []}
        pagination
        columns={columns}
        rowCount={totalElements}
        pageSize={10}
        autoHeight
        onSortModelChange={(model) => handleSortChange(model)}
        paginationMode="server"
        loading={loading}
        onPageChange={(data) => {
          handleChangePage(data)
        }}
        disableSelectionOnClick
        localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
        rowsPerPageOptions={[10]}
      />
    </div>
  )
}
