import { Delete } from '@mui/icons-material'
import { Box, IconButton } from '@mui/material'
import { DataGrid, frFR, GridColDef } from '@mui/x-data-grid'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { ProfileSearchResultContent } from '../../../api'
import { options } from '../../../helper/constants'
import { RootState } from '../../../redux/store'
import GetAdvisorsModal from '../../modalGetAdvisors'

interface DataGridUpdateUserProps {
  onChangeUserList: (data: Array<ProfileSearchResultContent>) => void
  advisorList: Array<ProfileSearchResultContent>
}
export default function DataGridUpdateUser({
  onChangeUserList,
  advisorList,
}: DataGridUpdateUserProps) {
  const isAdmin = useSelector((state: RootState) => state.userProfileReducer.admin)
  /* -------------------------------------------------------------------------- */
  /*                                  COSNTANT                                  */
  /* -------------------------------------------------------------------------- */
  const onDeleteUserFromList = (userId: number) => {
    const newArray = advisorList.filter((el) => el.id !== userId)
    onChangeUserList(newArray)
  }
  const onAddUserFromList = (value: Array<ProfileSearchResultContent>) => {
    onChangeUserList(advisorList.concat(value))
  }
  const { t } = useTranslation()
  const handleAdvisorsList = (value: Array<ProfileSearchResultContent>) => {
    onAddUserFromList(value)
  }

  const columns: GridColDef[] = [
    {
      field: 'firstName',
      headerName: t('forms.labels.first_name'),
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: 'lastName',
      headerName: t('forms.labels.last_name'),
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: 'email',
      headerName: t('forms.labels.email'),
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: 'types',
      headerName: t('forms.labels.types'),
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      valueFormatter: (params) => {
        const types = (params?.value as Array<string>).map(
          (el) => options.find((element) => element.value === el)?.label,
        )
        return types.toString()
      },
    },
    {
      field: 'Actions',
      headerName: '',
      disableColumnMenu: true,
      sortable: false,
      renderCell: (cellValues) =>
        isAdmin && (
          <IconButton color="error" onClick={() => onDeleteUserFromList(cellValues.row.id)}>
            <Delete />
          </IconButton>
        ),
    },
  ]

  return (
    <div>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          p: 2,
        }}
      >
        <GetAdvisorsModal
          advisorList={advisorList}
          handleAdvisorsList={(value: Array<ProfileSearchResultContent>) =>
            handleAdvisorsList(value)
          }
        />
      </Box>
      <DataGrid
        rows={typeof advisorList[0] === 'number' ? [] : advisorList}
        columns={columns}
        pageSize={10}
        autoHeight
        localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
        rowsPerPageOptions={[10]}
      />
    </div>
  )
}
