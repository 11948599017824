import { Notifications, People, Person } from '@mui/icons-material'
import {
  Box,
  Divider,
  Drawer,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
} from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import logo from '../../assets/GrowthEco_Logo_761x150.png'
import { drawerWidth } from '../../helper/constants'
import { getAdvisorPermissions } from '../../redux/actions/advisor.permissions.actions'
import { RootState, useAppDispatch } from '../../redux/store'
import UserInformation from '../userInfo'

interface Props {
  onOpen: boolean
  onClose: () => void
}
export default function DrawerMenu({ onOpen, onClose }: Props) {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const navigateToUser = (path: string) => {
    const connectedUser = JSON.parse(window.localStorage.getItem('user') || '""')
    if (connectedUser !== '') {
      dispatch(getAdvisorPermissions(connectedUser.id))
    }
    navigate(`/${path}`)
  }
  const isAdmin = useSelector((state: RootState) => state.userProfileReducer.admin)
  /* -------------------------------------------------------------------------- */
  /*                                RENDER HELPER                               */
  /* -------------------------------------------------------------------------- */
  const drawer = (
    <div>
      <Toolbar>
        <img
          src={logo}
          width={150}
          alt="Logo GrowthEco"
          style={{
            float: 'none',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        />
      </Toolbar>
      <Divider />
      <UserInformation />
      <Divider />
      <List>
        {/*
        <ListItemButton
          sx={{ pl: 3 }}
          onClick={() => {
            navigate('/')
          }}
        >
          <ListItemIcon>
            <Home />
          </ListItemIcon>
          <ListItemText primary={t('common.dashboard')} />
        </ListItemButton>
        */}

        {isAdmin && (
          <ListItemButton sx={{ pl: 3 }} onClick={() => navigateToUser('users')}>
            <ListItemIcon>
              <People />
            </ListItemIcon>
            <ListItemText primary={t('common.users')} />
          </ListItemButton>
        )}
        <ListItemButton sx={{ pl: 3 }} onClick={() => navigateToUser('notifications')}>
          <ListItemIcon>
            <Notifications />
          </ListItemIcon>
          <ListItemText primary="Notifications" />
        </ListItemButton>
        <ListItemButton sx={{ pl: 3 }} onClick={() => navigateToUser('clients')}>
          <ListItemIcon>
            <Person />
          </ListItemIcon>
          <ListItemText primary={t('common.clients')} />
        </ListItemButton>
      </List>
    </div>
  )
  /* -------------------------------------------------------------------------- */
  /*                                  RENDER                                    */
  /* -------------------------------------------------------------------------- */
  return (
    <Box
      component="nav"
      sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
      aria-label="mailbox folders"
    >
      <Drawer
        variant="temporary"
        open={onOpen}
        onClose={onClose}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
        }}
      >
        {drawer}
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: 'none', sm: 'block' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
        }}
        open
      >
        {drawer}
      </Drawer>
    </Box>
  )
}
