import { GET_USER_LOADING, GET_USER_SUCCESS } from '../actionTypes'
import { IUsers } from '../types'

const init: IUsers = {
  users: [],
  numberOfElements: 0,
  totalElements: 0,
  totalPages: 0,
  first: null,
  last: null,
  empty: null,
  loading: false,
}
export function usersReducer(state = init, { type, payload }: any = {}) {
  switch (type) {
    case GET_USER_SUCCESS:
      return {
        ...state,
        users: payload.content,
        numberOfElements: payload.numberOfElements,
        first: payload.first,
        last: payload.last,
        empty: payload.empty,
        totalPages: payload.totalPages,
        totalElements: payload.totalElements,
        loading: false,
      }

    case GET_USER_LOADING:
      return {
        ...state,
        loading: payload,
      }

    default:
      return state
  }
}
