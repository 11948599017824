import {
  Box,
  Card,
  CardContent,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import { FormikProps } from 'formik'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { ClientPut, MaritalStatus } from '../../api'
import { RootState } from '../../redux/store'

/* -------------------------------------------------------------------------- */
/*                                  COMPONENT                                 */
/* -------------------------------------------------------------------------- */
interface UserFormUpdateProps {
  FormikUserForm: FormikProps<ClientPut>
}

export function ClientProfileUpdate({ FormikUserForm }: UserFormUpdateProps) {
  /* -------------------------------------------------------------------------- */
  /*                                  CONSTANTS                                 */
  /* -------------------------------------------------------------------------- */
  const permissions = useSelector((state: RootState) => state.advisorPermissionsReducer)
  const { t } = useTranslation()

  /* -------------------------------------------------------------------------- */
  /*                                   RENDER                                   */
  /* -------------------------------------------------------------------------- */
  return (
    <Card>
      <CardContent>
        <Grid container spacing={3}>
          <Grid item md={12} xs={12}>
            <Box sx={{ maxWidth: 150 }}>
              <FormControl fullWidth>
                <InputLabel>{t('forms.labels.status')}</InputLabel>
                <Select
                  value={FormikUserForm.values.maritalStatus}
                  name="maritalStatus"
                  label="Marital Status"
                  onChange={FormikUserForm.handleChange}
                  onBlur={FormikUserForm.handleBlur}
                  disabled={!permissions.clientPermissions.updateProfile}
                >
                  {Object.entries(MaritalStatus).map(([key, value]) => (
                    <MenuItem value={value} key={key}>
                      {t(`user_marital_status.${key}`)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Grid>
          <Grid item md={12} xs={12}>
            <Typography>{t('forms.labels.financial_goals')}</Typography>
          </Grid>
          <Grid item md={12} xs={12}>
            <TextField
              fullWidth
              label={t('forms.labels.objective', { number: 1 })}
              name="financialObjectives[0]"
              variant="outlined"
              value={
                FormikUserForm.values.financialObjectives
                  ? FormikUserForm.values.financialObjectives[0]
                  : ''
              }
              onChange={FormikUserForm.handleChange}
              disabled={!permissions.clientPermissions.updateProfile}
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <TextField
              fullWidth
              label={t('forms.labels.objective', { number: 2 })}
              name="financialObjectives[1]"
              variant="outlined"
              value={
                FormikUserForm.values.financialObjectives
                  ? FormikUserForm.values.financialObjectives[1]
                  : ''
              }
              onChange={FormikUserForm.handleChange}
              disabled={!permissions.clientPermissions.updateProfile}
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <TextField
              fullWidth
              label={t('forms.labels.objective', { number: 3 })}
              name="financialObjectives[2]"
              variant="outlined"
              value={
                FormikUserForm.values.financialObjectives
                  ? FormikUserForm.values.financialObjectives[2]
                  : ''
              }
              onChange={FormikUserForm.handleChange}
              disabled={!permissions.clientPermissions.updateProfile}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
