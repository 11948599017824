import {
  Alert,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Snackbar,
  Typography,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { DocumentType } from '../../api'
import { snackBarProps } from '../../helper/iTypes'
import { getAdvisor } from '../../redux/actions/advisor.action'
import {
  getAdvisorPermissions,
  updateAdvisorPermissions,
} from '../../redux/actions/advisor.permissions.actions'
import { RootState, useAppDispatch } from '../../redux/store'
import Loader from '../loader'

interface StateType {
  userID: number
}
interface PermissionsType {
  [key: string]: boolean
}

function AdvisorPermissions() {
  /* -------------------------------------------------------------------------- */
  /*                                    HOOKS                                   */
  /* -------------------------------------------------------------------------- */
  const loading = useSelector((state: RootState) => state.loadingReducer.loading)
  const permissions = useSelector((state: RootState) => state.advisorPermissionsReducer)
  const [snackBar, setSnackBar] = React.useState<snackBarProps>({
    open: false,
    message: '',
    severity: 'success',
  })
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const location = useLocation()
  const params = useParams()
  const stateLocation = location?.state as StateType
  const userID = stateLocation?.userID ?? params.id
  const navigate = useNavigate()
  const [updateInsurancePolicy, setUpdateInsurancePolicy] = useState(true)
  const [isAdmin, setIsAdmin] = useState(true)
  const [isCurrentAdvisor, setIsCurrentAdvisor] = useState(false)
  const [updateMortgage, setUpdateMortgage] = useState(true)
  const [sendNotifications, setSendNotifications] = useState(true)
  const [createContactInformation, setCreateContactInformation] = useState(true)
  const [updateContactInformation, setUpdateContactInformation] = useState(true)
  const [updateProfile, setUpdateProfile] = useState(true)
  const [updateDocuments, setUpdateDocuments] = useState<PermissionsType>({
    InsurancePolicy: true,
    MortgageStatement: true,
    RrspContribution: true,
    NoticeOfAssessment: true,
    FinancialPlan: true,
    Testament: true,
    DriversLicense: true,
    CheckSpecimen: true,
    PowerOfAttorney: true,
    T1: true,
    T4: true,
    General: true,
  })
  const navigateTo = isAdmin ? '/users' : '/clients'
  /* -------------------------------------------------------------------------- */
  /*                                  CONSTANTS                                 */
  /* -------------------------------------------------------------------------- */
  const handleCloseSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }
    setSnackBar({
      open: false,
      message: '',
      severity: 'success',
    })
  }
  function onSubmitForm(id: number, data: any) {
    dispatch(updateAdvisorPermissions(id, data)).then((res) => {
      if (res?.status === 200) {
        setSnackBar({
          open: true,
          message: t('update_user.success_update_msg'),
          severity: 'success',
        })
      } else {
        setSnackBar({
          open: true,
          message: t(`errors.${res.status}`),
          severity: 'error',
        })
      }
    })
  }
  const permissionsData = {
    insurancePermissions: {
      updateInsurancePolicy,
    },
    mortgagePermissions: {
      updateMortgage,
    },
    notificationPermissions: {
      sendNotifications,
    },
    documentPermissions: {
      updateInsurancePolicyDocuments: updateDocuments.InsurancePolicy,
      updateMortgageStatements: updateDocuments.MortgageStatement,
      updateRRSPContributions: updateDocuments.RrspContribution,
      updateNoticeOfAssessments: updateDocuments.NoticeOfAssessment,
      updateFinancialPlans: updateDocuments.FinancialPlan,
      updateTestaments: updateDocuments.Testament,
      updateDriversLicences: updateDocuments.DriversLicense,
      updateCheckSpecimens: updateDocuments.CheckSpecimen,
      updatePowerOfAttorneys: updateDocuments.PowerOfAttorney,
      updateT1s: updateDocuments.T1,
      updateT4s: updateDocuments.T4,
      updateGeneralDocuments: updateDocuments.General,
    },
    clientPermissions: {
      createContactInformation,
      updateContactInformation,
      updateProfile,
    },
  }
  /* -------------------------------------------------------------------------- */
  /*                                    USEEFFECTS                              */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    const connectedUser = JSON.parse(window.localStorage.getItem('user') ?? '""')
    if (stateLocation || params.id) {
      dispatch(getAdvisor(userID)).then((res) => {
        if (res?.status === 200) {
          setIsAdmin(res.data.admin)
          if (res.data.id === connectedUser.id) setIsCurrentAdvisor(true)
        } else {
          setSnackBar({
            open: true,
            message: t(`errors.${res?.status}`),
            severity: 'error',
          })
        }
      })
    }
  }, [permissions])
  useEffect(() => {
    if ((stateLocation || params.id) && !isAdmin) {
      dispatch(getAdvisorPermissions(userID)).then((res) => {
        setUpdateInsurancePolicy(res.data.insurancePermissions.updateInsurancePolicy)
        setUpdateMortgage(res.data.mortgagePermissions.updateMortgage)
        setSendNotifications(res.data.notificationPermissions.sendNotifications)
        setCreateContactInformation(res.data.clientPermissions.createContactInformation)
        setUpdateContactInformation(res.data.clientPermissions.updateContactInformation)
        setUpdateProfile(res.data.clientPermissions.updateProfile)
        setUpdateDocuments({
          InsurancePolicy: res.data.documentPermissions.updateInsurancePolicyDocuments,
          MortgageStatement: res.data.documentPermissions.updateMortgageStatements,
          RrspContribution: res.data.documentPermissions.updateRRSPContributions,
          NoticeOfAssessment: res.data.documentPermissions.updateNoticeOfAssessments,
          FinancialPlan: res.data.documentPermissions.updateFinancialPlans,
          Testament: res.data.documentPermissions.updateTestaments,
          DriversLicense: res.data.documentPermissions.updateDriversLicences,
          CheckSpecimen: res.data.documentPermissions.updateCheckSpecimens,
          PowerOfAttorney: res.data.documentPermissions.updatePowerOfAttorneys,
          T1: res.data.documentPermissions.updateT1s,
          T4: res.data.documentPermissions.updateT4s,
          General: res.data.documentPermissions.updateGeneralDocuments,
        })
        if (res?.status !== 200) {
          setSnackBar({
            open: true,
            message: t(`errors.${res.status}`),
            severity: 'error',
          })
        }
      })
    }
  }, [isAdmin])
  /* -------------------------------------------------------------------------- */
  /*                                   HANDLERS                                 */
  /* -------------------------------------------------------------------------- */
  const handleChangeInsurancePolicy = (event: any) => {
    setUpdateInsurancePolicy(event.target.checked)
  }
  const handleChangeUpdateMortgage = (event: any) => {
    setUpdateMortgage(event.target.checked)
  }
  const handleChangeSendNotifications = (event: any) => {
    setSendNotifications(event.target.checked)
  }
  const handleChangeCreateContactInformation = (event: any) => {
    setCreateContactInformation(event.target.checked)
  }
  const handleChangeUpdateContactInformation = (event: any) => {
    setUpdateContactInformation(event.target.checked)
  }
  const handleChangeUpdateProfile = (event: any) => {
    setUpdateProfile(event.target.checked)
  }
  const handleChangeUpdateDocuments = (event: any, key: string) => {
    setUpdateDocuments({
      ...updateDocuments,
      [key]: event.target.checked,
    })
  }
  /* -------------------------------------------------------------------------- */
  /*                                   RENDER                                   */
  /* -------------------------------------------------------------------------- */
  return (
    <>
      <Loader loading={loading} />
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={snackBar.open}
        onClose={handleCloseSnackbar}
        autoHideDuration={4000}
      >
        <Alert onClose={handleCloseSnackbar} variant="filled" severity={snackBar?.severity}>
          {snackBar.message}
        </Alert>
      </Snackbar>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          p: 2,
        }}
      >
        <Button color="primary" variant="contained" onClick={() => navigate(navigateTo)}>
          {t('common.cancel')}
        </Button>
        <Button
          color="primary"
          variant="contained"
          sx={{ ml: 2 }}
          onClick={() => onSubmitForm(userID, permissionsData)}
          disabled={isAdmin}
        >
          {t('common.save')}
        </Button>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          p: 2,
        }}
      >
        <Grid container spacing={5}>
          <Grid item md={12} xs={12}>
            <Typography style={{ fontWeight: 600 }}>
              {t('forms.permissions.insurrance_title')}
            </Typography>
            <FormControlLabel
              label={t('forms.permissions.insurrance_text')}
              control={
                <Checkbox
                  disabled={isAdmin || isCurrentAdvisor}
                  checked={updateInsurancePolicy}
                  onChange={handleChangeInsurancePolicy}
                />
              }
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <Typography style={{ fontWeight: 600 }}>
              {t('forms.permissions.mortgage_title')}
            </Typography>
            <FormControlLabel
              label={t('forms.permissions.mortgage_text')}
              control={
                <Checkbox
                  disabled={isAdmin || isCurrentAdvisor}
                  checked={updateMortgage}
                  onChange={handleChangeUpdateMortgage}
                />
              }
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <Typography style={{ fontWeight: 600 }}>
              {t('forms.permissions.notifications_title')}
            </Typography>
            <FormControlLabel
              label={t('forms.permissions.notifications_text')}
              control={
                <Checkbox
                  disabled={isAdmin || isCurrentAdvisor}
                  checked={sendNotifications}
                  onChange={handleChangeSendNotifications}
                />
              }
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <Typography style={{ fontWeight: 600 }}>
              {t('forms.permissions.clients_update_title')}
            </Typography>
            <FormControlLabel
              label={t('forms.permissions.create_contact_informations_text')}
              control={
                <Checkbox
                  disabled={isAdmin || isCurrentAdvisor}
                  checked={createContactInformation}
                  onChange={handleChangeCreateContactInformation}
                />
              }
            />
            <FormControlLabel
              label={t('forms.permissions.contact_informations_text')}
              control={
                <Checkbox
                  disabled={isAdmin || isCurrentAdvisor}
                  checked={updateContactInformation}
                  onChange={handleChangeUpdateContactInformation}
                />
              }
            />
            <FormControlLabel
              label={t('forms.permissions.profile_text')}
              control={
                <Checkbox
                  disabled={isAdmin || isCurrentAdvisor}
                  checked={updateProfile}
                  onChange={handleChangeUpdateProfile}
                />
              }
            />
          </Grid>
        </Grid>
        <Grid container spacing={5}>
          <Grid item md={12} xs={12}>
            <Typography style={{ fontWeight: 600 }}>
              {t('forms.permissions.add_delete_title')}
            </Typography>
            {Object.entries(DocumentType).map(([key]) => (
              <Grid key={key} item md={12} xs={12}>
                <FormControlLabel
                  label={t(`documents_types.${key}`)}
                  key={key}
                  control={
                    <Checkbox
                      disabled={isAdmin || isCurrentAdvisor}
                      checked={updateDocuments[key]}
                      onChange={(e) => handleChangeUpdateDocuments(e, key)}
                    />
                  }
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default AdvisorPermissions
