import {
  ADD_DOCUMENT_SUCCESS,
  DELETE_DOCUMENT_SUCCESS,
  GET_DOCUMENTS_LOADING,
  GET_DOCUMENTS_SUCCESS,
} from '../actionTypes'
import { IDocumentContent, IDocuments } from '../types'

const init: IDocuments = {
  documents: [],
  numberOfElements: 0,
  totalElements: 0,
  totalPages: 0,
  first: null,
  last: null,
  empty: null,
  loading: false,
}
export function documentsReducer(state = init, { type, payload }: any = {}) {
  switch (type) {
    case GET_DOCUMENTS_SUCCESS:
      return {
        ...state,
        documents: payload.content,
        numberOfElements: payload.numberOfElements,
        first: payload.first,
        last: payload.last,
        empty: payload.empty,
        totalPages: payload.totalPages,
        totalElements: payload.totalElements,
        loading: false,
      }

    case GET_DOCUMENTS_LOADING:
      return {
        ...state,
        loading: payload,
      }
    case DELETE_DOCUMENT_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const filteredDocuments: IDocumentContent[] = state.documents.filter(
        (doc: any) => doc.filename !== payload,
      )
      return {
        ...state,
        documents: filteredDocuments,
        totalElements: filteredDocuments.length,
      }
    case ADD_DOCUMENT_SUCCESS:
      return {
        ...state,
        documents: [...state.documents, payload],
        totalElements: [...state.documents].length + 1,
      }

    default:
      return state
  }
}
