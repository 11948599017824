import { FormikProps } from 'formik'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { ClientPut, InsuranceType } from '../../../api'
import { RootState } from '../../../redux/store'
import CustomEditor from '../../customEditorComponent'

interface UserFormUpdateProps {
  FormikUserForm: FormikProps<ClientPut>
}

interface EditorData {
  type: InsuranceType
  visible: boolean
  description: string | undefined
}

export function InsurancePoliciesForm({ FormikUserForm }: UserFormUpdateProps) {
  const { t } = useTranslation()
  const permissions = useSelector((state: RootState) => state.advisorPermissionsReducer)
  const item: any = FormikUserForm?.values
  const [currentState, setCurrentState] = useState<EditorData[]>(
    item.insurancePolicies ?? [{}, {}, {}, {}, {}, {}, {}],
  )

  return (
    <>
      <CustomEditor
        handleEditorData={({ visible, description }) => {
          if (visible !== currentState[0].visible || description !== currentState[0].description) {
            FormikUserForm.setFieldValue('insurancePolicies[0]', {
              type: InsuranceType.Life,
              visible,
              description,
            })
            const values: EditorData = {
              type: InsuranceType.Life,
              visible,
              description,
            }
            const array = currentState
            array[0] = values
            setCurrentState(array)
          }
        }}
        description={
          item.insurancePolicies[0]?.description ? item.insurancePolicies[0]?.description : ''
        }
        visible={item.insurancePolicies[0]?.visible ? item.insurancePolicies[0]?.visible : false}
        title={t('assurance.labels.LIFE')}
        alertMessage={t('assurance.subHeaderAlert')}
        placeholder={t('assurance.placeholder.create_resume')}
        isDisabled={permissions.insurancePermissions.updateInsurancePolicy}
      />
      <CustomEditor
        handleEditorData={({ visible, description }) => {
          if (visible !== currentState[1].visible || description !== currentState[1].description) {
            FormikUserForm.setFieldValue('insurancePolicies[1]', {
              type: InsuranceType.Disability,
              visible,
              description,
            })
            const values: EditorData = {
              type: InsuranceType.Disability,
              visible,
              description,
            }
            const array = currentState
            array[1] = values
            setCurrentState(array)
          }
        }}
        description={
          item.insurancePolicies[1]?.description ? item.insurancePolicies[1]?.description : ''
        }
        visible={item.insurancePolicies[1]?.visible ? item.insurancePolicies[1]?.visible : false}
        title={t('assurance.labels.DISABILITY')}
        alertMessage={t('assurance.subHeaderAlert')}
        placeholder={t('assurance.placeholder.create_resume')}
        isDisabled={permissions.insurancePermissions.updateInsurancePolicy}
      />
      <CustomEditor
        handleEditorData={({ visible, description }) => {
          if (visible !== currentState[2].visible || description !== currentState[2].description) {
            FormikUserForm.setFieldValue('insurancePolicies[2]', {
              type: InsuranceType.CriticalIllness,
              visible,
              description,
            })
            const values: EditorData = {
              type: InsuranceType.CriticalIllness,
              visible,
              description,
            }
            const array = currentState
            array[2] = values
            setCurrentState(array)
          }
        }}
        description={
          item.insurancePolicies[2]?.description ? item.insurancePolicies[2]?.description : ''
        }
        visible={item.insurancePolicies[2]?.visible ? item.insurancePolicies[2]?.visible : false}
        title={t('assurance.labels.CRITICAL_ILLNESS')}
        alertMessage={t('assurance.subHeaderAlert')}
        placeholder={t('assurance.placeholder.create_resume')}
        isDisabled={permissions.insurancePermissions.updateInsurancePolicy}
      />
      <CustomEditor
        handleEditorData={({ visible, description }) => {
          if (visible !== currentState[3].visible || description !== currentState[3].description) {
            FormikUserForm.setFieldValue('insurancePolicies[3]', {
              type: InsuranceType.Group,
              visible,
              description,
            })
            const values: EditorData = {
              type: InsuranceType.Group,
              visible,
              description,
            }
            const array = currentState
            array[3] = values
            setCurrentState(array)
          }
        }}
        description={
          item.insurancePolicies[3]?.description ? item.insurancePolicies[3]?.description : ''
        }
        visible={item.insurancePolicies[3]?.visible ? item.insurancePolicies[3]?.visible : false}
        title={t('assurance.labels.GROUP')}
        alertMessage={t('assurance.subHeaderAlert')}
        placeholder={t('assurance.placeholder.create_resume')}
        isDisabled={permissions.insurancePermissions.updateInsurancePolicy}
      />

      <CustomEditor
        handleEditorData={({ visible, description }) => {
          if (visible !== currentState[4].visible || description !== currentState[4].description) {
            FormikUserForm.setFieldValue('insurancePolicies[4]', {
              type: InsuranceType.Child,
              visible,
              description,
            })
            const values: EditorData = {
              type: InsuranceType.Child,
              visible,
              description,
            }
            const array = currentState
            array[4] = values
            setCurrentState(array)
          }
        }}
        description={
          item.insurancePolicies[4]?.description ? item.insurancePolicies[4]?.description : ''
        }
        visible={item.insurancePolicies[4]?.visible ? item.insurancePolicies[4]?.visible : false}
        title={t('assurance.labels.CHILD')}
        alertMessage={t('assurance.subHeaderAlert')}
        placeholder={t('assurance.placeholder.create_resume')}
        isDisabled={permissions.insurancePermissions.updateInsurancePolicy}
      />
      <CustomEditor
        handleEditorData={({ visible, description }) => {
          if (visible !== currentState[5].visible || description !== currentState[5].description) {
            FormikUserForm.setFieldValue('insurancePolicies[5]', {
              type: InsuranceType.Car,
              visible,
              description,
            })
            const values: EditorData = {
              type: InsuranceType.Car,
              visible,
              description,
            }
            const array = currentState
            array[5] = values
            setCurrentState(array)
          }
        }}
        description={
          item.insurancePolicies[5]?.description ? item.insurancePolicies[5]?.description : ''
        }
        visible={item.insurancePolicies[5]?.visible ? item.insurancePolicies[5]?.visible : false}
        title={t('assurance.labels.CAR')}
        alertMessage={t('assurance.subHeaderAlert')}
        placeholder={t('assurance.placeholder.create_resume')}
        isDisabled={permissions.insurancePermissions.updateInsurancePolicy}
      />
      <CustomEditor
        handleEditorData={({ visible, description }) => {
          if (visible !== currentState[6].visible || description !== currentState[6].description) {
            FormikUserForm.setFieldValue('insurancePolicies[6]', {
              type: InsuranceType.Home,
              visible,
              description,
            })
            const values: EditorData = {
              type: InsuranceType.Home,
              visible,
              description,
            }
            const array = currentState
            array[6] = values
            setCurrentState(array)
          }
        }}
        description={
          item.insurancePolicies[6]?.description ? item.insurancePolicies[6]?.description : ''
        }
        visible={item.insurancePolicies[6]?.visible ? item.insurancePolicies[6]?.visible : false}
        title={t('assurance.labels.HOME')}
        alertMessage={t('assurance.subHeaderAlert')}
        placeholder={t('assurance.placeholder.create_resume')}
        isDisabled={permissions.insurancePermissions.updateInsurancePolicy}
      />
    </>
  )
}
