import { Add, Delete, Edit } from '@mui/icons-material'
import { Alert, Button, Container, IconButton, Modal, Snackbar } from '@mui/material'
import { DataGrid, frFR, GridColDef } from '@mui/x-data-grid'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'
import { Direction, MortgageSearchQuery, MortgageSearchQuerySortByEnum } from '../../api'
import { snackBarProps } from '../../helper/iTypes'
import { getClient } from '../../redux/actions/client.action'
import { addMortgage, deleteMortgage, getMortgages } from '../../redux/actions/mortgages.actions'
import { RootState, useAppDispatch } from '../../redux/store'
import { MortgageForm } from '../Forms/mortgage/mortgageForm'

interface stateType {
  userID: number
}

export default function MortgagesList() {
  const [filterValue, setFilterValue] = React.useState<MortgageSearchQuery>({
    search: {
      name: undefined,
      description: undefined,
      maturityDateFrom: undefined,
      maturityDateTo: undefined,
      rate: undefined,
      rateType: undefined,
      frequency: undefined,
      homeEquityLineOfCredit: undefined,
      propertyValue: undefined,
      balance: undefined,
      lastBalanceUpdateFrom: undefined,
      lastBalanceUpdateTo: undefined,
      lender: undefined,
      textSearch: undefined,
    },
    sortBy: MortgageSearchQuerySortByEnum.MaturityDate,
    sortDir: Direction.Asc,
    pageIndex: 0,
    pageSize: 10,
  })
  const [snackBar, setSnackBar] = React.useState<snackBarProps>({
    open: false,
    message: '',
    severity: 'success',
  })

  const dispatch = useAppDispatch()
  const location = useLocation()
  const params = useParams()
  const routerState = location.state as stateType
  const userID = routerState?.userID ?? params.id
  const mortgages = useSelector((state: RootState) => state.mortgagesReducer.mortgages)
  const loading = useSelector((state: RootState) => state.mortgagesReducer.loading)
  const totalElements = useSelector((state: RootState) => state.mortgagesReducer.totalElements)
  const permissions = useSelector((state: RootState) => state.advisorPermissionsReducer)
  const [open, setOpen] = React.useState(false)
  const [clientData, setClientData] = React.useState('')
  const [itsUpdate, setItsUpdate] = React.useState(false)
  const [mortgageDataToUpdate, setMortgageDataToUpdate] = React.useState<any>(null)

  const handleCloseSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }

    setSnackBar({
      open: false,
      message: '',
      severity: 'success',
    })
  }
  const { t } = useTranslation()
  const date = new Date()
  /* -------------------------------------------------------------------------- */
  /*                                   FORMIK                                   */
  /* -------------------------------------------------------------------------- */
  function onSubmitForm(values: any, data: any) {
    dispatch(
      addMortgage(userID, values, data, permissions.insurancePermissions.updateInsurancePolicy),
    ).then((res) => {
      if (res?.status === 200 && !itsUpdate) {
        setSnackBar({
          open: true,
          message: t('forms.mortgage.add_mortgage_success'),
          severity: 'success',
        })
        setOpen(false)
      } else if (res?.status === 200 && itsUpdate) {
        setSnackBar({
          open: true,
          message: t('forms.mortgage.update_mortgage_success'),
          severity: 'success',
        })
        setOpen(false)
      } else if (res?.status === 400) {
        setSnackBar({
          open: true,
          message: t('errors.mortgage_exist'),
          severity: 'error',
        })
      } else {
        setSnackBar({
          open: true,
          message: t(`errors.${res.status}`),
          severity: 'error',
        })
      }
    })
  }
  function onDeleteMortgage(mortgageId: number, clientInfos: any) {
    dispatch(deleteMortgage(userID, mortgageId, clientInfos)).then((res) => {
      if (res?.status === 200) {
        setSnackBar({
          open: true,
          message: t('forms.mortgage.delete_mortgage'),
          severity: 'success',
        })
        dispatch(getClient(userID)).then((response: any) => {
          if (response.status === 200) {
            setClientData(response.data)
          }
        })
        setOpen(false)
      } else {
        setSnackBar({
          open: true,
          message: t(`errors.${res.status}`),
          severity: 'error',
        })
      }
    })
  }
  const initialValues: any = {
    ...(itsUpdate && { id: mortgageDataToUpdate.id }),
    name: itsUpdate ? mortgageDataToUpdate.name : '',
    visible: itsUpdate ? mortgageDataToUpdate.visible : true,
    maturityDate: itsUpdate
      ? mortgageDataToUpdate.maturityDate
      : `${date.toISOString().split('.')[0]}Z`,
    rate: itsUpdate ? mortgageDataToUpdate.rate : '',
    description: itsUpdate ? mortgageDataToUpdate.description : '',
    rateType: itsUpdate ? mortgageDataToUpdate.rateType : 'FIX',
    frequency: itsUpdate ? mortgageDataToUpdate.frequency : 'MONTHLY',
    homeEquityLineOfCredit: itsUpdate ? mortgageDataToUpdate.homeEquityLineOfCredit : false,
    propertyValue: itsUpdate ? mortgageDataToUpdate.propertyValue : '',
    amount: itsUpdate ? mortgageDataToUpdate.amount : '',
    balance: itsUpdate ? mortgageDataToUpdate.balance : '',
    lastBalanceUpdate: itsUpdate
      ? mortgageDataToUpdate.lastBalanceUpdate
      : `${date.toISOString().split('.')[0]}Z`,
    lender: itsUpdate ? mortgageDataToUpdate.lender : '',
  }
  /* -------------------------------------------------------------------------- */
  /*                                  HELPERS                                   */
  /* -------------------------------------------------------------------------- */
  const onSetClientData = (data: any) => {
    setClientData(data)
  }
  /* -------------------------------------------------------------------------- */
  /*                                  COSNTANT                                  */
  /* -------------------------------------------------------------------------- */
  const handleOpen = () => {
    setItsUpdate(false)
    setOpen(true)
  }
  const handleOpenFromEdit = (data: any) => {
    setMortgageDataToUpdate(data)
    setItsUpdate(true)
    setOpen(true)
  }
  const handleClose = () => setOpen(false)
  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: "Nom de l'hypothéque",
      flex: 1,
      disableColumnMenu: true,
    },
    {
      field: 'maturityDate',
      headerName: "Date d'échéance",
      flex: 1,
      disableColumnMenu: true,
      valueFormatter: ({ value }) => value.substring(0, 10),
    },
    {
      field: 'propertyValue',
      headerName: 'Valeur de la propriété',
      flex: 1,
      disableColumnMenu: true,
      valueFormatter: ({ value }) => `${value}$`,
    },
    {
      field: 'balance',
      headerName: 'Solde',
      flex: 1,
      disableColumnMenu: true,
      valueFormatter: ({ value }) => `${value}$`,
    },
    {
      field: 'Actions',
      headerName: '',
      disableColumnMenu: true,
      sortable: false,
      renderCell: (cellValues) =>
        permissions.mortgagePermissions.updateMortgage && (
          <>
            <IconButton onClick={() => handleOpenFromEdit(cellValues.row)} color="secondary">
              <Edit />
            </IconButton>
            <IconButton
              onClick={() => onDeleteMortgage(cellValues.row.id, clientData as any)}
              color="warning"
            >
              <Delete />
            </IconButton>
          </>
        ),
    },
  ]

  const handleChangePage = (page: number) => {
    setFilterValue((prevState) => ({
      ...prevState,
      pageIndex: page,
    }))
  }

  const handleSortChange = (model: any) => {
    setFilterValue((prevState) => ({
      ...prevState,
      sortBy: model[0]?.field ?? MortgageSearchQuerySortByEnum.Name,
      sortDir: model[0]?.sort === 'desc' ? Direction.Desc : Direction.Asc,
    }))
  }

  React.useEffect(() => {
    dispatch(getMortgages(filterValue, userID)).then((res) => {
      if (res?.status !== 200) {
        setSnackBar({
          open: true,
          message: t(`errors.${res.status}`),
          severity: 'error',
        })
      }
    })
  }, [filterValue])

  React.useEffect(() => {
    dispatch(getClient(userID)).then((res: any) => {
      if (res.status === 200) {
        setClientData(res.data)
      }
    })
  }, [open])
  /* -------------------------------------------------------------------------- */
  /*                                   RENDER                                   */
  /* -------------------------------------------------------------------------- */
  return (
    <>
      <div style={{ textAlign: 'end', marginBottom: '12px' }}>
        {permissions.mortgagePermissions.updateMortgage && (
          <Button onClick={handleOpen} variant="contained" startIcon={<Add />}>
            {t('forms.mortgage.add_mortgage_title')}
          </Button>
        )}
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={snackBar.open}
          onClose={handleCloseSnackbar}
          autoHideDuration={4000}
        >
          <Alert onClose={handleCloseSnackbar} variant="filled" severity={snackBar?.severity}>
            {snackBar.message}
          </Alert>
        </Snackbar>
      </div>
      <Modal open={open} onClose={handleClose} className="modal">
        <Container sx={{ display: 'flex', justifyContent: 'center' }}>
          <MortgageForm
            submitLabel={!itsUpdate ? t('common.add') : t('common.edit')}
            initialValues={initialValues}
            onSubmitForm={(values) => onSubmitForm(values as any, clientData as any)}
            onCancel={handleClose}
            onSetClientData={onSetClientData}
            itsUpdate={itsUpdate}
          />
        </Container>
      </Modal>
      <DataGrid
        density="compact"
        rows={mortgages ?? []}
        pagination
        columns={columns}
        rowCount={totalElements}
        pageSize={10}
        autoHeight
        onSortModelChange={(model) => handleSortChange(model)}
        paginationMode="server"
        loading={loading}
        onPageChange={(data) => {
          handleChangePage(data)
        }}
        disableSelectionOnClick
        localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
        rowsPerPageOptions={[10]}
      />
    </>
  )
}
