import type { Blocker, History, Transition } from 'history'
import { ContextType, useCallback, useContext, useEffect, useState } from 'react'
import React, {
  Navigator as BaseNavigator,
  UNSAFE_NavigationContext as NavigationContext,
  useLocation,
  useNavigate,
} from 'react-router-dom'
import PromptView from './promptView'

interface Navigator extends BaseNavigator {
  block: History['block']
}

type NavigationContextWithBlock = ContextType<typeof NavigationContext> & { navigator: Navigator }
/**
 * custom hook to handle prompt from navigation event
 * @param blocker
 * @param when
 */
export function useBlocker(blocker: Blocker, when: boolean) {
  const { navigator } = useContext(NavigationContext) as NavigationContextWithBlock
  useEffect(() => {
    if (!when) return

    const unblock = navigator.block((tx: Transition) => {
      const autoUnblockingTx = {
        ...tx,
        retry() {
          unblock()
          tx.retry()
        },
      }

      blocker(autoUnblockingTx)
    })

    // eslint-disable-next-line consistent-return
    return unblock
  }, [navigator, blocker, when])
}

export function Prompt({ shouldPrompt }: { shouldPrompt: boolean }) {
  function useCallbackPrompt(when: boolean) {
    const navigate = useNavigate()
    const location = useLocation()
    const [showPrompt, setShowPrompt] = useState<boolean>(false)
    const [lastLocation, setLastLocation] = useState<any>(null)
    const [confirmedNavigation, setConfirmedNavigation] = useState(false)

    const cancelNavigation = useCallback(() => {
      setShowPrompt(false)
    }, [])

    const handleBlockedNavigation = useCallback(
      (nextLocation) => {
        if (!confirmedNavigation && nextLocation.location.pathname !== location.pathname) {
          setShowPrompt(true)
          setLastLocation(nextLocation)
          return false
        }
        return true
      },
      [confirmedNavigation],
    )

    const confirmNavigation = useCallback(() => {
      setShowPrompt(false)
      setConfirmedNavigation(true)
    }, [])

    useEffect(() => {
      if (confirmedNavigation && lastLocation) {
        navigate(lastLocation.location.pathname)
      }
    }, [confirmedNavigation, lastLocation])

    useBlocker(handleBlockedNavigation, when)

    return [showPrompt, confirmNavigation, cancelNavigation]
  }
  const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(shouldPrompt)
  return (
    <PromptView
      showPrompt={showPrompt}
      confirmNavigation={confirmNavigation}
      cancelNavigation={cancelNavigation}
      dialogTitle="common.unsaved_changes"
      dialogText="common.blockNavigation"
    />
  )
}
