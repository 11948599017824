import {
  Alert,
  Box,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  Typography,
} from '@mui/material'
import { ContentState, convertFromHTML, convertToRaw, EditorState } from 'draft-js'
import 'draft-js/dist/Draft.css'
import draftToHtml from 'draftjs-to-html'
import React, { ChangeEvent, useEffect, useState } from 'react'
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import './style.css'

interface CustomEditorProps {
  title: string
  alertMessage: string
  placeholder: string
  description: string
  visible: boolean
  handleEditorData: ({ visible, description }: { visible: boolean; description?: string }) => void
  isDisabled: boolean
}
export default function CustomEditor({
  title,
  alertMessage,
  placeholder,
  description,
  visible,
  handleEditorData,
  isDisabled,
}: CustomEditorProps) {
  const [editorState, setEditorState] = useState<EditorState>(EditorState.createEmpty())
  const [checked, setChecked] = useState(false)

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked)
  }

  useEffect(() => {
    handleEditorData({
      visible: checked,
      description: draftToHtml(convertToRaw(editorState.getCurrentContent())),
    })
  }, [checked, editorState, handleEditorData])

  useEffect(() => {
    const contentBlocks = convertFromHTML(description)
    const visibility = visible
    const contentState = ContentState.createFromBlockArray(
      contentBlocks.contentBlocks,
      contentBlocks.entityMap,
    )
    setEditorState(EditorState.createWithContent(contentState))
    setChecked(visibility)
  }, [])

  const HeaderCard = React.memo(() => (
    <Box display="flex" flexDirection="row" alignItems="center">
      <Checkbox checked={checked} onChange={handleChange} disabled={!isDisabled} />
      <Typography>{title}</Typography>
    </Box>
  ))

  const onEditorStateChange = (state: EditorState) => {
    setEditorState(state)
  }

  const isEditorStateEmpty = convertToRaw(editorState.getCurrentContent()).blocks[0].text

  const SubHeader = React.memo(() => (
    <div>
      {!checked && isEditorStateEmpty.length > 0 && (
        <Alert severity="warning">{alertMessage}</Alert>
      )}
    </div>
  ))

  return (
    <Card className="card">
      <CardHeader subheader={<SubHeader />} title={<HeaderCard />} />
      <Divider />
      <CardContent>
        <Editor
          editorState={editorState}
          toolbar={{
            options: ['inline', 'fontSize', 'textAlign', 'colorPicker', 'remove'],
            inline: {
              options: ['bold', 'italic', 'underline'],
            },
          }}
          readOnly={!checked || !isDisabled}
          editorClassName={`editor-class ${!checked && 'isDisabled'}`}
          placeholder={placeholder}
          onEditorStateChange={(data) => onEditorStateChange(data)}
        />
      </CardContent>
    </Card>
  )
}
