import { Box, Button, Card, CardContent, CardHeader, Divider, Grid, TextField } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { ProductType } from '../../api'

interface NotificationRow {
  message: string
  productType: string
  createdDate: string
  fromProfileFullName: string
  toProfileFullName: string
}
interface INotificationDetails {
  onCancel: () => void
  values: NotificationRow
}
/* -------------------------------------------------------------------------- */
/*                                  COMPONENT                                 */
/* -------------------------------------------------------------------------- */
export function NotificationDetails({ values, onCancel }: INotificationDetails) {
  /* -------------------------------------------------------------------------- */
  /*                                  CONSTANTS                                 */
  /* -------------------------------------------------------------------------- */
  const { t } = useTranslation()
  const translateProductType = (value: string) => {
    let type = ''
    // eslint-disable-next-line array-callback-return
    Object.entries(ProductType).map(([key, v]) => {
      if (value === v) type = key
    })
    return type ? t(`product_types.${type}`) : ''
  }
  const style = {
    input: { fontStyle: 'italic', color: 'rgba(0,0,0,0.6)' },
  }
  /* -------------------------------------------------------------------------- */
  /*                                   RENDER                                   */
  /* -------------------------------------------------------------------------- */
  return (
    <Card>
      <CardHeader title={t('notifications_table.notification_title')} />
      <Divider />

      <CardContent>
        <Grid container spacing={3}>
          <Grid item md={6} xs={12}>
            <TextField
              sx={[!values.productType && style]}
              fullWidth
              label={t('notifications_table.product_type')}
              value={
                translateProductType(values.productType) || t('notifications_table.no_product_type')
              }
              variant="outlined"
              inputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              fullWidth
              label={t('notifications_table.author')}
              value={values.fromProfileFullName}
              variant="outlined"
              inputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              fullWidth
              label={t('notifications_table.send_date')}
              value={values.createdDate.substring(0, 10)}
              variant="outlined"
              inputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              fullWidth
              label={t('notifications_table.send_to')}
              value={values.toProfileFullName}
              variant="outlined"
              inputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              multiline
              fullWidth
              label={t('notifications_table.notification_message')}
              value={values.message || t('notifications_table.no_notification_message')}
              variant="outlined"
              inputProps={{
                readOnly: true,
                ...(!values.message && {
                  style: { fontStyle: 'italic', color: 'rgba(0,0,0,0.6)' },
                }),
              }}
            />
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          p: 2,
        }}
      >
        <Button color="primary" variant="contained" onClick={() => onCancel()}>
          {t('common.close')}
        </Button>
      </Box>
    </Card>
  )
}
