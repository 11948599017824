import { AdvisorPut, Locale, MaritalStatus, ProfileType } from '../api'
import Accounting from '../assets/accounting.svg'
import Insurance from '../assets/insurance.svg'
import Investment from '../assets/investment.svg'
import Mortgage from '../assets/mortgage.svg'
import i18n from '../i18n'

export const drawerWidth = 240
export const options = [
  {
    label: i18n.t('forms.labels.investment'),
    value: 'INVESTMENT',
  },
  {
    label: i18n.t('forms.labels.insurance'),
    value: 'INSURANCE',
  },
  {
    label: i18n.t('forms.labels.mortgage'),
    value: 'MORTGAGE',
  },
  {
    label: i18n.t('forms.labels.accounting'),
    value: 'ACCOUNTING',
  },
]

export const advisorTypes = [
  {
    label: i18n.t('forms.transfer_clients.types.investment'),
    shortLabel: i18n.t('forms.labels.investment'),
    value: 'INVESTMENT',
    icon: Investment,
  },
  {
    label: i18n.t('forms.transfer_clients.types.insurance'),
    shortLabel: i18n.t('forms.labels.insurance'),
    value: 'INSURANCE',
    icon: Insurance,
  },
  {
    label: i18n.t('forms.transfer_clients.types.mortgage'),
    shortLabel: i18n.t('forms.labels.mortgage'),
    value: 'MORTGAGE',
    icon: Mortgage,
  },
  {
    label: i18n.t('forms.transfer_clients.types.accounting'),
    shortLabel: i18n.t('forms.labels.accounting'),
    value: 'ACCOUNTING',
    icon: Accounting,
  },
]

export const Roles = [
  {
    value: 'TOUS',
    label: i18n.t('common.all'),
  },
  {
    value: ProfileType.Client,
    label: i18n.t('common.client'),
  },
  {
    value: ProfileType.Advisor,
    label: i18n.t('common.advisor'),
  },
]

export const maritalStatusOptions = [
  {
    value: '',
    label: i18n.t('maritalStatus.unknown'),
  },
  ...(Object.keys(MaritalStatus) as Array<keyof typeof MaritalStatus>).map((key) => ({
    value: key.toUpperCase(),
    label: i18n.t(`maritalStatus.${key}`),
  })),
]

export const initialValuesAdvisor: AdvisorPut = {
  firstName: '',
  lastName: '',
  email: '',
  locale: Locale.Fr,
  communicationLocale: Locale.Fr,
  phoneNumber: '',
  clientIds: [],
  types: [],
}
export const Equity = {
  True: true,
  False: false,
}

export const FileTypes = [
  'png',
  'jpg',
  'jpeg',
  'pdf',
  'txt',
  'doc',
  'docx',
  'xls',
  'xlsx',
  'ppt',
  'pptx',
  'csv',
  'vnd.openxmlformats-officedocument.presentationml.presentation',
  'plain',
  'vnd.openxmlformats-officedocument.wordprocessingml.document',
  'vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'msword',
  'vnd.ms-excel',
]

export const DocumentTypeModified = {
  updateInsurancePolicyDocuments: 'INSURANCE_POLICY',
  updateMortgageStatements: 'MORTGAGE_STATEMENT',
  updateNoticeOfAssessments: 'NOTICE_OF_ASSESSMENT',
  updateT4s: 'T4',
  updateT1s: 'T1',
  updateCheckSpecimens: 'CHECK_SPECIMEN',
  updateDriversLicences: 'DRIVERS_LICENSE',
  updateTestaments: 'TESTAMENT',
  updatePowerOfAttorneys: 'POWER_OF_ATTORNEY',
  updateFinancialPlans: 'FINANCIAL_PLAN',
  updateRRSPContributions: 'RRSP_CONTRIBUTION',
  updateGeneralDocuments: 'GENERAL',
}

export function compareObjects(obj1: any, obj2: any) {
  if (obj1 === obj2) return true

  if (typeof obj1 !== 'object' || typeof obj2 !== 'object' || obj1 == null || obj2 == null) {
    return false
  }

  const keysA = Object.keys(obj1)
  const keysB = Object.keys(obj2)

  if (keysA.length !== keysB.length) {
    return false
  }

  let result = true

  keysA.forEach((key) => {
    if (!keysB.includes(key)) {
      result = false
    }

    if (typeof obj1[key] === 'function' || typeof obj2[key] === 'function') {
      if (obj1[key].toString() !== obj2[key].toString()) {
        result = false
      }
    }

    if (!compareObjects(obj1[key], obj2[key])) {
      result = false
    }
  })

  return result
}
