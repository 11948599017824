import { Configuration } from '../../api'
import {
  Advisor,
  ClientApi,
  ClientPost,
  ClientPut,
  ClientSearchQuery,
  ClientSearchResult,
} from '../../api/api'
import { handleAccessToken } from '../../services/config'
import { GET_USER_SUCCESS } from '../actionTypes'
import { AppDispatch } from '../store'
import { onHandleLoading } from './loading.action'

export function getClientSuccess(data: ClientSearchResult) {
  return {
    type: GET_USER_SUCCESS,
    payload: data,
  }
}

export function getClients(data: ClientSearchQuery) {
  return async (dispatch: AppDispatch) => {
    const token = await handleAccessToken.getAccessTokenSilently()
    const getAllClients = new ClientApi(
      new Configuration({
        basePath: `${process.env.REACT_APP_BACKEND_API_URL}`,
        accessToken: token,
      }),
    )
    return getAllClients
      .searchClient('fr', data)
      .then((res) => {
        dispatch(getClientSuccess(res.data))
        return res
      })
      .catch((error) => error.response)
  }
}

export function getClient(id: number) {
  return async (dispatch: AppDispatch) => {
    dispatch(onHandleLoading(true))
    const token = await handleAccessToken.getAccessTokenSilently()
    const getOneClient = new ClientApi(
      new Configuration({
        basePath: `${process.env.REACT_APP_BACKEND_API_URL}`,
        accessToken: token,
      }),
    )
    return getOneClient
      .getClient(id, 'fr')
      .then((res) => {
        dispatch(onHandleLoading(false))
        return res
      })
      .catch((error) => {
        dispatch(onHandleLoading(false))
        return error.response
      })
  }
}

export function addClient(data: ClientPost) {
  return async (dispatch: AppDispatch) => {
    dispatch(onHandleLoading(true))
    const token = await handleAccessToken.getAccessTokenSilently()
    const clientApi = new ClientApi(
      new Configuration({
        basePath: `${process.env.REACT_APP_BACKEND_API_URL}`,
        accessToken: token,
      }),
    )

    const clientPost: ClientPost = {
      email: data.email,
      firstName: data.firstName,
      lastName: data.lastName,
      locale: data.locale,
      communicationLocale: data.communicationLocale,
      crmCode: data.crmCode,
      phoneNumber: data.phoneNumber,
      advisorIds: data.advisorIds ?? [],
    }
    return clientApi
      .createClient('fr', clientPost)
      .then((res) => res)
      .catch((error) => error.response)
      .finally(() => dispatch(onHandleLoading(false)))
  }
}

export function updateUserClient(id: number, data: ClientPut) {
  return async (dispatch: AppDispatch) => {
    const token = await handleAccessToken.getAccessTokenSilently()
    dispatch(onHandleLoading(true))
    const clientApi = new ClientApi(
      new Configuration({
        basePath: `${process.env.REACT_APP_BACKEND_API_URL}`,
        accessToken: token,
      }),
    )
    const clientUpdate: ClientPut = {
      email: data.email,
      firstName: data.firstName,
      lastName: data.lastName,
      locale: data.locale,
      communicationLocale: data.communicationLocale,
      crmCode: data.crmCode,
      phoneNumber: data.phoneNumber,
      advisorIds: data.advisorIds.map((el) => (el as unknown as Advisor).id) ?? [],
      active: data.active,
      inactiveReason: data.inactiveReason,
      streetAddress: data.streetAddress ? data.streetAddress : '',
      maritalStatus: data.maritalStatus ? data.maritalStatus : 'SINGLE',
      financialObjectives: data.financialObjectives ? data.financialObjectives : ['', '', ''],
      insurancePolicies: data.insurancePolicies,
    }
    return clientApi
      .updateClient(id, 'fr', clientUpdate)
      .then((res) => {
        dispatch(onHandleLoading(false))
        return res
      })
      .catch((error) => {
        dispatch(onHandleLoading(false))
        return error.response
      })
  }
}

export function getAllAdvisorClients(id: number) {
  return async () => {
    const token = await handleAccessToken.getAccessTokenSilently()
    const clientApi = new ClientApi(
      new Configuration({
        basePath: `${process.env.REACT_APP_BACKEND_API_URL}`,
        accessToken: token,
      }),
    )
    const resultat = await clientApi.getAllAdvisorsForClient(id, 'fr')
    return resultat
  }
}
