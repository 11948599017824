import { Configuration } from '../../api'
import { ProfileApi, ProfileSearchQuery, ProfileSearchResult } from '../../api/api'
import { handleAccessToken } from '../../services/config'
import { GET_USER_SUCCESS } from '../actionTypes'
import { AppDispatch } from '../store'

export function getUserSuccess(data: ProfileSearchResult) {
  return {
    type: GET_USER_SUCCESS,
    payload: data,
  }
}

export function getUsers(data: ProfileSearchQuery) {
  return async (dispatch: AppDispatch) => {
    const token = await handleAccessToken.getAccessTokenSilently()
    const getAllUsers = new ProfileApi(
      new Configuration({
        basePath: `${process.env.REACT_APP_BACKEND_API_URL}`,
        accessToken: token,
      }),
    )
    return getAllUsers
      .searchProfile('fr', data)
      .then((res) => {
        dispatch(getUserSuccess(res.data))
        return res
      })
      .catch((error) => error.response)
  }
}

export function getOnlyAdvisors(data: ProfileSearchQuery) {
  return async () => {
    const token = await handleAccessToken.getAccessTokenSilently()
    const getAllUsers = new ProfileApi(
      new Configuration({
        basePath: `${process.env.REACT_APP_BACKEND_API_URL}`,
        accessToken: token,
      }),
    )
    const resault = await getAllUsers
      .searchProfile('fr', data)
      .then((res) => res)
      .catch((error) => error.response)
    return resault
  }
}
