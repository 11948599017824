import Autocomplete from '@mui/material/Autocomplete'
import Chip from '@mui/material/Chip'
import TextField from '@mui/material/TextField'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { ProfileSearchQuery, ProfileSearchResultContent, ProfileType } from '../../api'
import { getOnlyAdvisors } from '../../redux/actions/users.action'
import { useAppDispatch } from '../../redux/store'

interface SelectUsersProps {
  advisorList: Array<ProfileSearchResultContent>
  newAdvisors: Array<ProfileSearchResultContent>
  setNewAdvisors: (value: Array<ProfileSearchResultContent>) => void
}

export default function SelectAdvisors({
  advisorList,
  newAdvisors,
  setNewAdvisors,
}: SelectUsersProps) {
  const [data, setData] = React.useState<Array<ProfileSearchResultContent>>([])
  const existingAdvisorList = advisorList.map((x: ProfileSearchResultContent) => x.id)
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  React.useEffect(() => {
    let isMounted = true

    const obj: ProfileSearchQuery = {
      search: {
        profileType: ProfileType.Advisor,
      },
      pageIndex: 0,
    }
    dispatch(getOnlyAdvisors(obj)).then((res) => {
      if (res?.status === 200 && isMounted) {
        setData(
          res?.data.content.filter(
            (el: ProfileSearchResultContent) =>
              el.active && existingAdvisorList.indexOf(el.id) === -1,
          ),
        )
      }
    })

    return () => {
      isMounted = false
    }
    // eslint-disable-next-line
  }, [])

  return (
    <div>
      <Autocomplete
        multiple
        fullWidth
        id="advisors-list"
        noOptionsText="Aucun resultat"
        value={newAdvisors}
        onChange={(event, newValue) => {
          setNewAdvisors(newValue)
        }}
        options={data}
        getOptionLabel={(option) => option.firstName ?? ''}
        renderOption={(props, option) => (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <li {...props} key={option.id}>
            {option?.firstName} {option?.lastName}
          </li>
        )}
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => (
            <Chip
              label={`${option?.firstName}  ${option?.lastName}`}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...getTagProps({ index })}
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...params}
            label={t('common.advisor')}
            placeholder={t('common.advisor')}
          />
        )}
      />
    </div>
  )
}
