import { Alert, Button, Snackbar } from '@mui/material'
import Box from '@mui/material/Box'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import { FormikProps, FormikProvider, useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import {
  Client,
  ClientPut,
  InsurancePolicy,
  InsurancePolicyPut,
  InsuranceType,
} from '../../../../api'
import { Prompt } from '../../../../components/customPrompt'
import PromptView from '../../../../components/customPrompt/promptView'
import DashboardLayout from '../../../../components/DashboardLayout'
import { InsurancePoliciesForm } from '../../../../components/Forms/insurancePolicies/insurancePoliciesForm'
import { UserFormUpdate } from '../../../../components/Forms/signupUser/formUpdateUser'
import DocumentsList from '../../../../components/listDocuments'
import MortgagesList from '../../../../components/listMortgages'
import NotificationsList from '../../../../components/listNotifications'
import Loader from '../../../../components/loader'
import { ClientProfileUpdate } from '../../../../components/profilClient'
import { compareObjects } from '../../../../helper/constants'
import { snackBarProps } from '../../../../helper/iTypes'
import { ClientSchema } from '../../../../helper/validationSchema'
import { getAdvisorPermissions } from '../../../../redux/actions/advisor.permissions.actions'
import {
  getAllAdvisorClients,
  getClient,
  updateUserClient,
} from '../../../../redux/actions/client.action'
import { RootState, useAppDispatch } from '../../../../redux/store'
import { initialValuesClient } from './initValuesFromik'

interface StateType {
  userID: number
  from: string
}
interface TabPanelProps {
  children: React.ReactChild
  index: number
  value: number
}

/* -------------------------------------------------------------------------- */
/*                                   HELPER                                   */
/* -------------------------------------------------------------------------- */
function TabPanel(props: Readonly<TabPanelProps>) {
  const { children, value, index } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  )
}

const addAndSortMissingInsurancePolicies = (
  insurancePolicies: InsurancePolicy[] = [],
): InsurancePolicyPut[] => {
  const insurancePoliciesPut: InsurancePolicyPut[] = []

  const insurancePolicyLife = insurancePolicies.find((value) => value.type === InsuranceType.Life)
  if (insurancePolicyLife) {
    insurancePoliciesPut.push({ ...insurancePolicyLife })
  } else {
    insurancePoliciesPut.push({
      type: InsuranceType.Life,
      visible: false,
      description: '<p></p>\n',
    })
  }

  const insurancePolicyDisability = insurancePolicies.find(
    (value) => value.type === InsuranceType.Disability,
  )
  if (insurancePolicyDisability) {
    insurancePoliciesPut.push({ ...insurancePolicyDisability })
  } else {
    insurancePoliciesPut.push({
      type: InsuranceType.Disability,
      visible: false,
      description: '<p></p>\n',
    })
  }

  const insurancePolicyCriticalIllness = insurancePolicies.find(
    (value) => value.type === InsuranceType.CriticalIllness,
  )
  if (insurancePolicyCriticalIllness) {
    insurancePoliciesPut.push({ ...insurancePolicyCriticalIllness })
  } else {
    insurancePoliciesPut.push({
      type: InsuranceType.CriticalIllness,
      visible: false,
      description: '<p></p>\n',
    })
  }

  const insurancePolicyGroup = insurancePolicies.find((value) => value.type === InsuranceType.Group)
  if (insurancePolicyGroup) {
    insurancePoliciesPut.push({ ...insurancePolicyGroup })
  } else {
    insurancePoliciesPut.push({
      type: InsuranceType.Group,
      visible: false,
      description: '<p></p>\n',
    })
  }

  const insurancePolicyChild = insurancePolicies.find((value) => value.type === InsuranceType.Child)
  if (insurancePolicyChild) {
    insurancePoliciesPut.push({ ...insurancePolicyChild })
  } else {
    insurancePoliciesPut.push({
      type: InsuranceType.Child,
      visible: false,
      description: '<p></p>\n',
    })
  }

  const insurancePolicyCar = insurancePolicies.find((value) => value.type === InsuranceType.Car)
  if (insurancePolicyCar) {
    insurancePoliciesPut.push({ ...insurancePolicyCar })
  } else {
    insurancePoliciesPut.push({
      type: InsuranceType.Car,
      visible: false,
      description: '<p></p>\n',
    })
  }

  const insurancePolicyHome = insurancePolicies.find((value) => value.type === InsuranceType.Home)
  if (insurancePolicyHome) {
    insurancePoliciesPut.push({ ...insurancePolicyHome })
  } else {
    insurancePoliciesPut.push({
      type: InsuranceType.Home,
      visible: false,
      description: '<p></p>\n',
    })
  }

  return insurancePoliciesPut
}

/* -------------------------------------------------------------------------- */
/*                                  COMPONENT                                 */
/* -------------------------------------------------------------------------- */
export default function UserClientScreen() {
  /* -------------------------------------------------------------------------- */
  /*                                    HOOKS                                   */
  /* -------------------------------------------------------------------------- */
  const location = useLocation()
  const { t } = useTranslation()
  const params = useParams()
  const routerState = location.state as StateType
  const userID = routerState?.userID ?? params.id
  const from = routerState?.from
  const redirectTo = from === 'clientsList' ? '/clients' : '/users'
  const [value, setValue] = useState(0)
  const [handleIndexTab, setHandleIndexTab] = useState(0)
  const [showPromptTabs, setShowPromptTabs] = useState(false)
  const [initialValues, setInitialValues] = useState(initialValuesClient)
  const [snackBar, setSnackBar] = React.useState<snackBarProps>({
    open: false,
    message: '',
    severity: 'success',
  })
  const loading = useSelector((state: RootState) => state.loadingReducer.loading)
  const permissions = useSelector((state: RootState) => state.advisorPermissionsReducer)
  const isAdmin = useSelector((state: RootState) => state.userProfileReducer.admin)
  const adminId = useSelector((state: RootState) => state.userProfileReducer.id)
  const dispatch = useAppDispatch()

  /* -------------------------------------------------------------------------- */
  /*                                   HELPERS                                  */
  const navigate = useNavigate()
  const checkAdvisorPermissions = (
    values: ClientPut,
    updateInsurancePolicy: boolean,
    updateMortgage: boolean,
  ) => {
    // eslint-disable-next-line no-param-reassign
    if (!updateInsurancePolicy) delete values.insurancePolicies
    // eslint-disable-next-line no-param-reassign
    if (!updateMortgage) delete values.mortgages
  }
  /* -------------------------------------------------------------------------- */
  /*                                 CONSTATNTS                                 */
  /* -------------------------------------------------------------------------- */
  function onSubmitForm(values: Client) {
    dispatch(updateUserClient(values?.id, values as ClientPut)).then((res) => {
      if (res?.status === 200) {
        dispatch(getAllAdvisorClients((initialValues as Client).id)).then((result) => {
          if (result?.status === 200) {
            const newState = {
              ...res.data,
              advisorIds: result?.data,
            }
            setInitialValues(newState)
          }
        })
        setSnackBar({
          open: true,
          message: t('update_user.success_update_msg'),
          severity: 'success',
        })
      } else {
        setSnackBar({
          open: true,
          message: t(`errors.${res.status}`),
          severity: 'error',
        })
      }
    })
  }
  /* -------------------------------------------------------------------------- */
  /*                                 USEEFFECTS                                 */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    dispatch(getClient(userID)).then((res) => {
      if (res.status === 200) {
        dispatch(getAllAdvisorClients(userID)).then((result) => {
          const newState = {
            streetAddress: '',
            maritalStatus: '',
            financialObjectives: ['', '', ''],
            ...res?.data,
            insurancePolicies: addAndSortMissingInsurancePolicies(res?.data?.insurancePolicies),
            advisorIds: result?.data ?? [],
          }
          setInitialValues(newState)
        })
      } else {
        setSnackBar({
          open: true,
          message: t(`errors.${res.status}`),
          severity: 'error',
        })
      }
    })
    return () => {
      setInitialValues({} as ClientPut)
    }
    // eslint-disable-next-line
  }, [])
  useEffect(() => {
    const connectedUser = JSON.parse(window.localStorage.getItem('user') ?? '""')
    if (connectedUser !== '') {
      dispatch(getAdvisorPermissions(connectedUser.id))
    }
    // eslint-disable-next-line
  }, [])
  /* -------------------------------------------------------------------------- */
  /*                                   FORMIK                                   */
  /* -------------------------------------------------------------------------- */
  const FormikUserForm: FormikProps<ClientPut> = useFormik<ClientPut>({
    initialValues,
    enableReinitialize: true,
    onSubmit: (values) => {
      checkAdvisorPermissions(
        values,
        permissions.insurancePermissions.updateInsurancePolicy,
        permissions.mortgagePermissions.updateMortgage,
      )
      const castValues = ClientSchema.cast(values)
      onSubmitForm(castValues as Client)
    },
    validationSchema: ClientSchema,
  })

  /* -------------------------------------------------------------------------- */
  /*                                 CONSTATNTS                                 */
  /* -------------------------------------------------------------------------- */
  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    if (!compareObjects(FormikUserForm.initialValues, FormikUserForm.values)) {
      setHandleIndexTab(newValue)
      setShowPromptTabs(true)
    } else {
      setValue(newValue)
    }
  }
  const cancelNavigationTab = () => {
    setShowPromptTabs(false)
  }
  const confirmNavigationTab = () => {
    FormikUserForm.handleReset()
    setValue(handleIndexTab)
    setShowPromptTabs(false)
  }
  /* -------------------------------------------------------------------------- */
  /*                                   HELPERS                                  */
  /* -------------------------------------------------------------------------- */
  const handleCloseSnackbar = (_event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }
    setSnackBar({
      open: false,
      message: '',
      severity: 'success',
    })
  }
  /* -------------------------------------------------------------------------- */
  /*                                   RENDER                                   */
  /* -------------------------------------------------------------------------- */
  return (
    <DashboardLayout
      headerTitle={`${t('common.client')}: ${initialValues.firstName} ${initialValues.lastName}`}
    >
      <div>
        <Loader loading={loading} />
        <Prompt shouldPrompt={!!FormikUserForm.dirty} />
        <PromptView
          showPrompt={showPromptTabs}
          cancelNavigation={cancelNavigationTab}
          confirmNavigation={confirmNavigationTab}
          dialogTitle="common.unsaved_changes"
          dialogText="common.blockNavigation"
        />
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={snackBar.open}
          onClose={handleCloseSnackbar}
          autoHideDuration={4000}
        >
          <Alert onClose={handleCloseSnackbar} variant="filled" severity={snackBar?.severity}>
            {snackBar.message}
          </Alert>
        </Snackbar>
        {(permissions.clientPermissions.updateContactInformation ||
          permissions.clientPermissions.updateProfile) && (
          <>
            <Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  p: 2,
                }}
              >
                <Button color="primary" variant="contained" onClick={() => navigate(redirectTo)}>
                  {t('common.cancel')}
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  sx={{ ml: 2 }}
                  onClick={() => FormikUserForm.handleSubmit()}
                >
                  {t('common.save')}
                </Button>
              </Box>
            </Box>
            <Box>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange}>
                  <Tab label={t('tabs_title.general')} id={`tab-${0}`} />
                  <Tab label={t('tabs_title.profile')} id={`tab-${1}`} />
                  <Tab label={t('tabs_title.assurance')} id={`tab-${2}`} />
                  <Tab label={t('tabs_title.mortgage')} id={`tab-${3}`} />
                  <Tab label={t('tabs_title.finance')} id={`tab-${4}`} disabled />
                  <Tab label={t('tabs_title.library')} id={`tab-${5}`} />
                  <Tab label={t('tabs_title.notifications')} id={`tab-${6}`} />
                </Tabs>
              </Box>
              <FormikProvider value={FormikUserForm}>
                <TabPanel value={value} index={0}>
                  <UserFormUpdate FormikUserForm={FormikUserForm} />
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <ClientProfileUpdate FormikUserForm={FormikUserForm} />
                </TabPanel>
                <TabPanel value={value} index={2}>
                  <InsurancePoliciesForm FormikUserForm={FormikUserForm} />
                </TabPanel>
                <TabPanel value={value} index={3}>
                  <MortgagesList />
                </TabPanel>
                <TabPanel value={value} index={5}>
                  <DocumentsList />
                </TabPanel>
                <TabPanel value={value} index={6}>
                  <NotificationsList
                    toProfileId={userID}
                    fromProfileId={isAdmin ? undefined : adminId}
                  />
                </TabPanel>
              </FormikProvider>
            </Box>
          </>
        )}
      </div>
    </DashboardLayout>
  )
}
