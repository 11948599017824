import { Stack, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import DashboardLayout from '../../../components/DashboardLayout'
import ClientsList from '../../../components/listClients'
import { getAdvisorPermissions } from '../../../redux/actions/advisor.permissions.actions'
import { getUserProfile } from '../../../redux/actions/userProfil.action'
import { RootState, useAppDispatch } from '../../../redux/store'
import AddClientModal from '../clients/addClient'

function ClientsListScreen() {
  /* -------------------------------------------------------------------------- */
  /*                                  CONSTANTS                                 */
  /* -------------------------------------------------------------------------- */
  const isAdmin = useSelector((state: RootState) => state.userProfileReducer.admin)
  const permissions = useSelector((state: RootState) => state.advisorPermissionsReducer)
  const { t } = useTranslation()
  const [reload] = useState(false)

  const dispatch = useAppDispatch()
  /* -------------------------------------------------------------------------- */
  /*                                   HOOKS                                   */
  /* -------------------------------------------------------------------------- */
  React.useEffect(() => {
    dispatch(getUserProfile()).then((res) => {
      dispatch(getAdvisorPermissions(res.data.id))
    })
  }, [dispatch])
  /* -------------------------------------------------------------------------- */
  /*                                   RENDER                                   */
  /* -------------------------------------------------------------------------- */
  return (
    <DashboardLayout headerTitle={t('common.clients')}>
      <div>
        <Typography sx={{ mb: 3 }} variant="h4">
          {t('common.clients_list')}
        </Typography>
        {(isAdmin || permissions.clientPermissions.createContactInformation) && (
          <Stack direction="row" alignItems="center" spacing={2}>
            <AddClientModal />
          </Stack>
        )}
        <ClientsList reload={reload} />
      </div>
    </DashboardLayout>
  )
}

export default ClientsListScreen
