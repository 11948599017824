import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Snackbar,
  Typography,
} from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'
import { ClientApi, Configuration, DocumentType, InsuranceType } from '../../api'
import { DocumentTypeModified, FileTypes } from '../../helper/constants'
import { snackBarProps } from '../../helper/iTypes'
import { addDoucment } from '../../redux/actions/documents.action'
import { RootState, useAppDispatch } from '../../redux/store'
import { handleAccessToken } from '../../services/config'
import Loader from '../loader'

interface FormSignup {
  onCancel: () => void
}
interface StateType {
  userID: number
}
/* -------------------------------------------------------------------------- */
/*                                  COMPONENT                                 */
/* -------------------------------------------------------------------------- */
export function ModalAddDocument({ onCancel }: Readonly<FormSignup>) {
  /* -------------------------------------------------------------------------- */
  /*                                  CONSTANTS                                 */
  /* -------------------------------------------------------------------------- */
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const years: number[] = [
    new Date().getFullYear(),
    new Date().getFullYear() - 1,
    new Date().getFullYear() - 2,
    new Date().getFullYear() - 3,
  ]
  const [snackBar, setSnackBar] = React.useState<snackBarProps>({
    open: false,
    message: '',
    severity: 'success',
  })
  const loading = useSelector((state: RootState) => state.loadingReducer.loading)
  const [documentType, setDocumentType] = useState('')
  const [fiscalYear, setFiscalYear] = useState(years[2])
  const [clientMortgageData, setClientMortgageData] = useState([])
  const [clientMortgage, setClientMortgage] = useState(clientMortgageData[0] ?? '')
  const [insuranceType, setInsuranceType] = useState(InsuranceType.Life)
  const [file, setFile] = useState({ fileToUpload: {}, filename: '' })
  const [fileTypeAllowed, setFileTypeAllowed] = useState(true)
  const handleCloseSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }

    setSnackBar({
      open: false,
      message: '',
      severity: 'success',
    })
  }
  const location = useLocation()
  const params = useParams()
  const routerState = location.state as StateType
  const userID = routerState?.userID ?? params.id
  const permissions = useSelector((state: RootState) => state.advisorPermissionsReducer)
  /* -------------------------------------------------------------------------- */
  /*                                   HELPERS                                   */
  /* -------------------------------------------------------------------------- */
  const conditionalRenderingSelect = (state: string, ...valuesArray: string[]): boolean =>
    valuesArray.includes(state)
  const onUploadDocument = (id: number, fileInfo: any, doc: any) => {
    dispatch(addDoucment(id, fileInfo, doc))
      .then((res) => {
        if (res?.status !== 200 && res?.status !== 201) {
          setSnackBar({
            open: true,
            message: t('common.noadd_document'),
            severity: 'error',
          })
        } else {
          setSnackBar({
            open: true,
            message: t('common.add_document'),
            severity: 'success',
          })
        }
        setTimeout(() => {
          onCancel()
        }, 1000)
      })
      .catch((err) => {
        setSnackBar({
          open: true,
          message: t(`errors.${err}`),
          severity: 'error',
        })
      })
  }
  // Info for file to be uploaded
  const fileInfos = new Blob(
    [
      JSON.stringify({
        type: documentType,
        title: file.filename,
        ...(conditionalRenderingSelect(
          documentType,
          DocumentType.T1,
          DocumentType.T4,
          DocumentType.NoticeOfAssessment,
          DocumentType.RrspContribution,
        ) && {
          fiscalYear,
        }),
        ...(conditionalRenderingSelect(documentType, DocumentType.InsurancePolicy) && {
          policyType: insuranceType,
        }),
        ...(conditionalRenderingSelect(documentType, DocumentType.MortgageStatement) && {
          mortgageId: clientMortgage,
        }),
      }),
    ],
    { type: 'application/json' },
  )
  /* ---------------------------------------------------------------------------- */
  /*                                   HANDLERS                                   */
  /* ---------------------------------------------------------------------------- */
  const handleChangeDocumentType = (event: any) => {
    setDocumentType(event.target.value)
  }
  const handleChangeFiscalYear = (event: any) => {
    setFiscalYear(event.target.value)
  }
  const handleChangeInsuranceType = (event: any) => {
    setInsuranceType(event.target.value)
  }
  const handleChangeFile = (event: any) => {
    const fileToUpload = event.target.files[0]
    if (FileTypes.includes(fileToUpload?.type.split('/')[1])) {
      setFileTypeAllowed(true)
    } else {
      setFileTypeAllowed(false)
    }
    setFile({
      fileToUpload,
      filename: fileToUpload.name,
    })
  }
  const handleClientMortgage = (event: any) => {
    setClientMortgage(event.target.value)
  }

  React.useEffect(() => {
    const searchClientMortgage = () => {
      const clientApi = new ClientApi(
        new Configuration({
          basePath: `${process.env.REACT_APP_BACKEND_API_URL}`,
          accessToken: handleAccessToken.getAccessTokenSilently(),
        }),
      )
      clientApi
        .searchClientMortgage(userID, 'fr', {})
        .then((res: any) => setClientMortgageData(res.data.content))
        .catch((err) => {
          setSnackBar({
            open: true,
            message: t(`errors.${err}`),
            severity: 'error',
          })
        })
    }
    searchClientMortgage()
  }, [])
  /* -------------------------------------------------------------------------- */
  /*                                   RENDER                                   */
  /* -------------------------------------------------------------------------- */
  return (
    <>
      <Loader loading={loading} />
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={snackBar.open}
        onClose={handleCloseSnackbar}
        autoHideDuration={4000}
      >
        <Alert onClose={handleCloseSnackbar} variant="filled" severity={snackBar?.severity}>
          {snackBar.message}
        </Alert>
      </Snackbar>
      <Card>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Divider />
          <CardHeader title={t('common.add_document_title')} />
          <Divider />

          <CardContent sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Grid container spacing={3} justifyContent="center" alignItems="center">
              <Grid item md={12} xs={12}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Box
                    sx={{
                      width: 200,
                    }}
                  >
                    <Typography>{t('common.type_document_title')}</Typography>
                  </Box>
                  <FormControl sx={{ width: 500, marginLeft: 1 }}>
                    <Select value={documentType} onChange={handleChangeDocumentType}>
                      {Object.entries(DocumentTypeModified).map(([key, value]) => (
                        <MenuItem
                          value={value}
                          key={key}
                          disabled={!permissions.documentPermissions[key]}
                        >
                          {t(`documents_types.${key}`)}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </Grid>

              {conditionalRenderingSelect(
                documentType,
                DocumentType.T1,
                DocumentType.T4,
                DocumentType.NoticeOfAssessment,
                DocumentType.RrspContribution,
              ) && (
                <Grid item md={12} xs={12}>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Box
                      sx={{
                        width: 200,
                      }}
                    >
                      <Typography>{t('common.fiscalYear')}</Typography>
                    </Box>
                    <FormControl sx={{ width: 500, marginLeft: 1 }}>
                      <Select value={fiscalYear} onChange={handleChangeFiscalYear}>
                        {years.map((year) => (
                          <MenuItem value={year} key={year.toString()}>
                            {year}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
              )}

              {conditionalRenderingSelect(documentType, DocumentType.InsurancePolicy) && (
                <Grid item md={12} xs={12}>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Box
                      sx={{
                        width: 200,
                      }}
                    >
                      <Typography>{t('common.insurance_type')}</Typography>
                    </Box>
                    <FormControl sx={{ width: 500, marginLeft: 1 }}>
                      <Select value={insuranceType} onChange={handleChangeInsuranceType}>
                        {Object.entries(InsuranceType).map(([key, value]) => (
                          <MenuItem value={value} key={key}>
                            {t(`insurance_types.${key}`)}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
              )}

              {conditionalRenderingSelect(documentType, DocumentType.MortgageStatement) && (
                <Grid item md={12} xs={12}>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Box
                      sx={{
                        width: 200,
                      }}
                    >
                      <Typography>{t('common.mortgage_type')}</Typography>
                    </Box>
                    <FormControl sx={{ width: 500, marginLeft: 1 }}>
                      <Select value={clientMortgage} onChange={handleClientMortgage}>
                        {clientMortgageData?.map((mortgage: any) => (
                          <MenuItem value={mortgage.id} key={mortgage.id}>
                            {mortgage.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
              )}
            </Grid>
          </CardContent>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              m: 1,
            }}
          >
            <Button variant="contained" component="label">
              {t('common.choose_file')}
              <input type="file" hidden onChange={handleChangeFile} />
            </Button>
            <Typography
              sx={{
                maxWidth: '700px',
                overflowWrap: 'anywhere',
                marginTop: '16px',
              }}
            >
              {file.filename}
            </Typography>
          </Box>

          {/* Buttons */}
          <Divider />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              p: 2,
            }}
          >
            <Button color="primary" variant="contained" onClick={() => onCancel()}>
              {t('common.cancel')}
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => onUploadDocument(userID, fileInfos, file.fileToUpload)}
              sx={{ ml: 1 }}
              disabled={
                conditionalRenderingSelect(documentType, DocumentType.MortgageStatement) &&
                Boolean(file.filename) &&
                Boolean(clientMortgage) &&
                fileTypeAllowed
                  ? false
                  : !(
                      !conditionalRenderingSelect(documentType, DocumentType.MortgageStatement) &&
                      Boolean(file.filename) &&
                      fileTypeAllowed &&
                      documentType
                    )
              }
            >
              {t('common.add')}
            </Button>
          </Box>
        </Box>
        {!fileTypeAllowed && <Alert severity="error">{t('errors.invalid_filetype')}</Alert>}
      </Card>
    </>
  )
}
