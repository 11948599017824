import { Configuration } from '../../api'
import { Profile, ProfileApi } from '../../api/api'
import { handleAccessToken } from '../../services/config'
import { GET_PROFILE_USER_SUCCESS } from '../actionTypes'
import { AppDispatch } from '../store'

export function getUserProfileSuccess(data: Profile) {
  return {
    type: GET_PROFILE_USER_SUCCESS,
    payload: data,
  }
}

export function getUserProfile() {
  return (dispatch: AppDispatch) => {
    const getMyProfil = new ProfileApi(
      new Configuration({
        basePath: `${process.env.REACT_APP_BACKEND_API_URL}`,
        accessToken: handleAccessToken.getAccessTokenSilently(),
      }),
    )
    return getMyProfil
      .me('fr')
      .then((res) => {
        dispatch(getUserProfileSuccess(res?.data))
        return res
      })
      .catch((error) => error.response)
  }
}
