import { ADD_MORTGAGE_SUCCESS, GET_MORTGAGES_LOADING, GET_MORTGAGES_SUCCESS } from '../actionTypes'
import { IMortgages } from '../types'

const init: IMortgages = {
  mortgages: [],
  numberOfElements: 0,
  totalElements: 0,
  totalPages: 0,
  first: null,
  last: null,
  empty: null,
  loading: false,
}
export function mortgagesReducer(state = init, { type, payload }: any = {}) {
  switch (type) {
    case GET_MORTGAGES_SUCCESS:
      return {
        ...state,
        mortgages: payload.content,
        numberOfElements: payload.numberOfElements,
        first: payload.first,
        last: payload.last,
        empty: payload.empty,
        totalPages: payload.totalPages,
        totalElements: payload.totalElements,
        loading: false,
      }
    case GET_MORTGAGES_LOADING:
      return {
        ...state,
        loading: payload,
      }
    case ADD_MORTGAGE_SUCCESS:
      return {
        ...state,
        mortgages: [...state.mortgages, payload],
      }

    default:
      return state
  }
}
