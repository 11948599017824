import { AdvisorPut, ClientPut, Locale } from '../../../../api'

export const initialValuesAdvisor: AdvisorPut = {
  firstName: '',
  lastName: '',
  email: '',
  locale: Locale.Fr,
  communicationLocale: Locale.Fr,
  phoneNumber: '',
  clientIds: [],
  types: [],
}
export const initialValuesClient: ClientPut = {
  firstName: '',
  lastName: '',
  email: '',
  crmCode: '',
  locale: Locale.Fr,
  communicationLocale: Locale.Fr,
  phoneNumber: '',
  advisorIds: [],
  streetAddress: '',
  maritalStatus: 'SINGLE',
  financialObjectives: ['', '', ''],
  insurancePolicies: [
    { type: 'LIFE', visible: false, description: '<p></p>\n' },
    { type: 'DISABILITY', visible: false, description: '<p></p>\n' },
    { type: 'CRITICAL_ILLNESS', visible: false, description: '<p></p>\n' },
    { type: 'GROUP', visible: false, description: '<p></p>\n' },
  ],
}
