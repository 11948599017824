import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Box, Button, Checkbox, Grid, Stack, TextField } from '@mui/material'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Typography from '@mui/material/Typography'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { ClientSearchResultContent } from '../../../api'
import { RootState } from '../../../redux/store'
import SelectAdvisors from '../../modalGetAdvisors/seletctAdvisorsInput'
import { Filtres } from './types'

interface FilterPanelProps {
  handleFilters: (Filtres: Filtres) => void
}

export default function FilterPanel({ handleFilters }: FilterPanelProps) {
  const { t } = useTranslation()
  const isAdmin = useSelector((state: RootState) => state.userProfileReducer.admin)
  const [filter, setFilter] = React.useState<Filtres>({
    email: '',
    firstName: '',
    lastName: '',
    sortBy: '',
    advisorIds: [],
    isOrphan: false,
  })
  const [newAdvisors, setNewAdvisors] = React.useState<Array<ClientSearchResultContent>>([])
  const updateFilter = (k: string, v: string) => setFilter((prev) => ({ ...prev, [k]: v }))
  const reset = () => {
    setFilter({
      email: '',
      firstName: '',
      lastName: '',
      sortBy: '',
      advisorIds: [],
      isOrphan: false,
    })
    handleFilters({
      email: '',
      firstName: '',
      lastName: '',
      sortBy: '',
      advisorIds: [],
      isOrphan: false,
    })
    setNewAdvisors([])
  }

  const handleOrphanClick = () => {
    setFilter({
      ...filter,
      isOrphan: !filter.isOrphan,
    })
  }

  const updateAdvisorFilter = () => {
    const advisors: number[] = []
    newAdvisors.forEach((advisor: ClientSearchResultContent) => advisors.push(advisor.id))
    setFilter({
      ...filter,
      advisorIds: advisors,
    })
  }

  React.useEffect(() => {
    updateAdvisorFilter()
    // eslint-disable-next-line
  }, [newAdvisors])

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>{t('common.filters')}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <div>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label={t('forms.labels.first_name')}
                name="firstName"
                value={filter.firstName}
                onChange={(e) => updateFilter('firstName', e.target.value)}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label={t('forms.labels.last_name')}
                name="lastName"
                value={filter.lastName}
                onChange={(e) => updateFilter('lastName', e.target.value)}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label={t('forms.labels.email')}
                name="email"
                value={filter.email}
                onChange={(e) => updateFilter('email', e.target.value)}
              />
            </Grid>
            {isAdmin && (
              <>
                <Grid item md={6} xs={12}>
                  <SelectAdvisors
                    advisorList={[]}
                    newAdvisors={newAdvisors}
                    setNewAdvisors={setNewAdvisors}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box display="flex" flexDirection="row" alignItems="center">
                    <Checkbox checked={filter.isOrphan} onChange={handleOrphanClick} />
                    <Typography>{t('forms.labels.is_orphan')}</Typography>
                  </Box>
                </Grid>
              </>
            )}
          </Grid>
        </div>
        <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={2} mt={2}>
          <Button color="primary" variant="contained" onClick={() => reset()}>
            {t('common.reset')}
          </Button>
          <Button color="primary" variant="contained" onClick={() => handleFilters(filter)}>
            {t('common.apply')}
          </Button>
        </Stack>
      </AccordionDetails>
    </Accordion>
  )
}
