import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@mui/material'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'
import Switch from '@mui/material/Switch'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Assignment, ProfileType } from '../../api'
import { RootState } from '../../redux/store'
import TransferClients from '../modalTransferClients'

interface SwitchUserStatusProps {
  status: boolean
  types: string[]
  clients: number
  inactiveReason: string
  onChangeRaison: (value: string) => void
  onChangeStatus: (status: boolean) => void
  errorHandel: boolean
  helperText: string
  profileType: string
  isCurrentAdvisor: boolean
  setTransferTo?: (values: Assignment[] | []) => void
}

export default function SwitchUserStatus({
  status,
  types,
  clients,
  onChangeStatus,
  onChangeRaison,
  inactiveReason,
  errorHandel,
  helperText,
  profileType,
  isCurrentAdvisor,
  setTransferTo,
}: SwitchUserStatusProps) {
  const [openConfirmation, setOpenConfirmation] = React.useState(false)
  const [openTransfer, setOpenTransfer] = React.useState(false)
  const isAdmin = useSelector((state: RootState) => state.userProfileReducer.admin)
  const { t } = useTranslation()

  const handleOpenConfirmation = () => setOpenConfirmation(true)
  const handleCloseConfirmation = () => setOpenConfirmation(false)
  const handleOpenTransfer = () => setOpenTransfer(true)
  const handleCloseTransfer = () => setOpenTransfer(false)

  const showTransferClient = () => {
    handleOpenTransfer()
  }

  const onDesactivate = () => {
    if (profileType === ProfileType.Advisor && status && clients > 0) {
      showTransferClient()
    }
    onChangeStatus(!status)
    handleCloseConfirmation()
  }

  return (
    <FormGroup>
      <FormControlLabel
        disabled={(!isAdmin && profileType !== ProfileType.Client) || isCurrentAdvisor}
        style={{ width: 200 }}
        control={
          <Switch color="primary" checked={status ?? false} onClick={handleOpenConfirmation} />
        }
        label={
          status ? (t('forms.labels.active') as string) : (t('forms.labels.inactive') as string)
        }
      />

      <Dialog
        fullWidth
        open={openConfirmation}
        onClose={handleCloseConfirmation}
        style={{ display: 'flex', justifyContent: 'center' }}
      >
        <DialogTitle style={{ display: 'flex', justifyContent: 'center' }}>
          {status
            ? t('forms.switch_user_status.deactivation_confirmation_title')
            : t('forms.switch_user_status.reactivation_confirmation_title')}
        </DialogTitle>
        <DialogContent
          style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}
        >
          <DialogContentText>
            {status
              ? t('forms.switch_user_status.deactivation_confirmation_text', {
                  user:
                    profileType === ProfileType.Client
                      ? t('common.client').toLowerCase()
                      : t('common.advisor').toLowerCase(),
                })
              : t('forms.switch_user_status.reactivation_confirmation_text', {
                  user:
                    profileType === ProfileType.Client
                      ? t('common.client').toLowerCase()
                      : t('common.advisor').toLowerCase(),
                })}
          </DialogContentText>
          <TextField
            placeholder={t('forms.switch_user_status.reason_for_inactivation')}
            multiline
            minRows={3}
            value={inactiveReason}
            maxRows={6}
            error={errorHandel}
            helperText={helperText}
            onChange={(e) => onChangeRaison(e.target.value)}
            disabled={!status}
          />
        </DialogContent>
        <DialogActions style={{ justifyContent: 'center' }}>
          <Button onClick={handleCloseConfirmation}>{t('common.cancel')}</Button>
          <Button disabled={status && inactiveReason === ''} onClick={onDesactivate}>
            {status ? t('common.disable') : t('common.enable')}
          </Button>
        </DialogActions>
      </Dialog>

      <TransferClients
        open={openTransfer}
        handleClose={handleCloseTransfer}
        types={types}
        setTransferTo={setTransferTo}
      />
    </FormGroup>
  )
}

SwitchUserStatus.defaultProps = {
  setTransferTo: undefined,
}
