import { OpenInNew } from '@mui/icons-material'
import { Alert, Container, IconButton, Modal, Snackbar } from '@mui/material'
import { DataGrid, frFR, GridColDef } from '@mui/x-data-grid'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import {
  Direction,
  NotificationSearchQuery,
  NotificationSearchQuerySortByEnum,
  ProductType,
} from '../../api'
import { snackBarProps } from '../../helper/iTypes'
import { getNotifications } from '../../redux/actions/notifications.actions'
import { RootState, useAppDispatch } from '../../redux/store'
import { ModalSendNotification } from '../modalSendNotification'
import { NotificationDetails } from '../notificationDetails'

interface INotificationsListProps {
  toProfileId?: number
  fromProfileId?: number
}

interface NotificationRow {
  message: string
  productType: string
  createdDate: string
  fromProfileFullName: string
  toProfileFullName: string
}

export default function NotificationsList({
  toProfileId,
  fromProfileId,
}: Readonly<INotificationsListProps>) {
  const [filterValue, setFilterValue] = useState<NotificationSearchQuery>({
    search: {
      fromProfileId,
      toProfileId,
      createdDateFrom: undefined,
      createdDateTo: undefined,
      productType: undefined,
      isBroadcast: undefined,
      isRead: undefined,
      message: undefined,
    },
    sortBy: NotificationSearchQuerySortByEnum.CreatedDate,
    sortDir: Direction.Desc,
    pageIndex: 0,
    pageSize: 10,
    isSetResultAsRead: false,
  })
  const [snackBar, setSnackBar] = React.useState<snackBarProps>({
    open: false,
    message: '',
    severity: 'success',
  })
  const [notification, setNotification] = React.useState<NotificationRow>({
    message: '',
    productType: '',
    createdDate: '',
    fromProfileFullName: '',
    toProfileFullName: '',
  })
  const [open, setOpen] = React.useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const dispatch = useAppDispatch()
  const permissions = useSelector((state: RootState) => state.advisorPermissionsReducer)
  const notifications = useSelector((state: RootState) => state.notificationsReducer.notifications)
  const loading = useSelector((state: RootState) => state.notificationsReducer.loading)
  const totalElements = useSelector((state: RootState) => state.notificationsReducer.totalElements)
  const handleCloseSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }

    setSnackBar({
      open: false,
      message: '',
      severity: 'success',
    })
  }
  const { t } = useTranslation()
  /* -------------------------------------------------------------------------- */
  /*                                  HELPERS                                   */
  /* -------------------------------------------------------------------------- */
  const handleNotification = ({
    message,
    productType,
    createdDate,
    fromProfileFullName,
    toProfileFullName,
  }: NotificationRow) => {
    setNotification({
      message,
      productType,
      createdDate,
      fromProfileFullName,
      toProfileFullName,
    })
    handleOpen()
  }

  const translateProductType = (value: string) => {
    let type = ''
    // eslint-disable-next-line array-callback-return
    Object.entries(ProductType).forEach(([key, v]) => {
      if (value === v) type = key
    })
    return type ? t(`product_types.${type}`) : ''
  }
  /* -------------------------------------------------------------------------- */
  /*                                  COSNTANT                                  */
  /* -------------------------------------------------------------------------- */
  const columns: GridColDef[] = [
    {
      field: 'productType',
      headerName: t('notifications_table.product_type'),
      width: 150,
      disableColumnMenu: true,
      sortable: false,
      valueFormatter: ({ value }) => translateProductType(value),
    },
    {
      field: 'message',
      headerName: t('notifications_table.message'),
      width: 200,
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: 'createdDate',
      headerName: t('notifications_table.created_date'),
      width: 150,
      disableColumnMenu: true,
      valueFormatter: ({ value }) => value.substring(0, 10),
    },
    {
      field: 'fromProfileFullName',
      headerName: t('notifications_table.send_from'),
      flex: 1,
      disableColumnMenu: true,
    },
    {
      field: 'toProfileFullName',
      headerName: t('notifications_table.send_to'),
      flex: 1,
      disableColumnMenu: true,
    },
    {
      field: 'Actions',
      headerName: '',
      disableColumnMenu: true,
      sortable: false,
      renderCell: (cellValues) => (
        <IconButton onClick={() => handleNotification(cellValues.row)} color="secondary">
          <OpenInNew />
        </IconButton>
      ),
    },
  ]

  const handleChangePage = (page: number) => {
    setFilterValue((prevState) => ({
      ...prevState,
      pageIndex: page,
    }))
  }

  // eslint-disable-next-line
  const handleSortChange = (model: any) => {
    setFilterValue((prevState) => ({
      ...prevState,
      sortBy: model[0]?.field ?? NotificationSearchQuerySortByEnum.CreatedDate,
      sortDir: model[0]?.sort === 'desc' ? Direction.Desc : Direction.Asc,
    }))
  }

  React.useEffect(() => {
    dispatch(getNotifications(filterValue)).then((res) => {
      if (res?.status !== 200) {
        setSnackBar({
          open: true,
          message: t(`errors.${res.status}`),
          severity: 'error',
        })
      }
    })
  }, [filterValue, dispatch, t])

  return (
    <div>
      {permissions.notificationPermissions.sendNotifications && (
        <div style={{ textAlign: 'end', marginBottom: '12px' }}>
          <Container>
            <ModalSendNotification filterValue={filterValue} toProfileIdDefault={toProfileId} />
          </Container>
        </div>
      )}
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={snackBar.open}
        onClose={handleCloseSnackbar}
        autoHideDuration={4000}
      >
        <Alert onClose={handleCloseSnackbar} variant="filled" severity={snackBar?.severity}>
          {snackBar.message}
        </Alert>
      </Snackbar>
      <Modal open={open} onClose={handleClose} className="modal">
        <Container>
          <NotificationDetails values={notification} onCancel={handleClose} />
        </Container>
      </Modal>
      <DataGrid
        density="compact"
        rows={notifications ?? []}
        pagination
        columns={columns}
        rowCount={totalElements}
        pageSize={10}
        autoHeight
        onSortModelChange={(model) => handleSortChange(model)}
        paginationMode="server"
        loading={loading}
        onPageChange={(data) => {
          handleChangePage(data)
        }}
        disableSelectionOnClick
        localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
        rowsPerPageOptions={[10]}
      />
    </div>
  )
}

NotificationsList.defaultProps = {
  toProfileId: undefined,
  fromProfileId: undefined,
}
