import * as Yup from 'yup'
import i18n from '../i18n'

const phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/

export const ClientSchema = Yup.object().shape({
  id: Yup.number(),
  email: Yup.string()
    .trim()
    .email(i18n.t('errors.inavalid_email'))
    .required(i18n.t('errors.required_field')),
  firstName: Yup.string()
    .trim()
    .max(20, i18n.t('errors.max_first_name'))
    .required(i18n.t('errors.required_field')),
  lastName: Yup.string()
    .trim()
    .max(20, i18n.t('errors.max_last_name'))
    .required(i18n.t('errors.required_field')),
  locale: Yup.string(),
  communicationLocale: Yup.string(),
  phoneNumber: Yup.string()
    .trim()
    .matches(phoneRegex, i18n.t('errors.invalid_phone'))
    .required(i18n.t('errors.required_field')),
  crmCode: Yup.string().trim().required(i18n.t('errors.required_field')),
  streetAddress: Yup.string().trim(),
  maritalStatus: Yup.string().trim(),
  financialObjectives: Yup.array().of(Yup.string()).max(3),
})

export const AdvisorSchema = Yup.object().shape({
  id: Yup.number(),
  email: Yup.string()
    .trim()
    .email(i18n.t('errors.inavalid_email'))
    .required(i18n.t('errors.required_field')),
  firstName: Yup.string()
    .trim()
    .max(20, i18n.t('errors.max_first_name'))
    .required(i18n.t('errors.required_field')),
  lastName: Yup.string()
    .trim()
    .max(20, i18n.t('errors.max_last_name'))
    .required(i18n.t('errors.required_field')),
  phoneNumber: Yup.string()
    .trim()
    .matches(phoneRegex, i18n.t('errors.invalid_phone'))
    .required(i18n.t('errors.required_field')),
  locale: Yup.string(),
  communicationLocale: Yup.string(),
  admin: Yup.boolean(),
  types: Yup.array().when('admin', {
    is: false,
    then: Yup.array().min(1, i18n.t('errors.required_field')),
  }),
})

export const MortgageSchema = Yup.object().shape({
  id: Yup.number(),
  name: Yup.string()
    .trim()
    .max(20, i18n.t('errors.max_name'))
    .required(i18n.t('errors.required_field')),
  maturityDate: Yup.string().typeError(i18n.t('errors.invalid_date_format')).required(),
  rate: Yup.number()
    .min(0, i18n.t('errors.positive_number'))
    .required(i18n.t('errors.required_field')),
  rateType: Yup.string().required(i18n.t('errors.required_field')),
  frequency: Yup.string().required(i18n.t('errors.required_field')),
  homeEquityLineOfCredit: Yup.boolean().required(i18n.t('errors.required_field')),
  propertyValue: Yup.number()
    .min(0, i18n.t('errors.positive_number'))
    .required(i18n.t('errors.required_field')),
  amount: Yup.number()
    .min(0, i18n.t('errors.positive_number'))
    .required(i18n.t('errors.required_field')),
  balance: Yup.number()
    .min(0, i18n.t('errors.positive_number'))
    .required(i18n.t('errors.required_field')),
  lastBalanceUpdate: Yup.string()
    .typeError(i18n.t('errors.invalid_date_format'))
    .required(i18n.t('errors.required_field')),
  lender: Yup.string()
    .trim()
    .max(20, i18n.t('errors.max_lender'))
    .required(i18n.t('errors.required_field')),
})
