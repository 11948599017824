import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Button, Grid, MenuItem, Stack, TextField } from '@mui/material'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Typography from '@mui/material/Typography'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Roles } from '../../../helper/constants'
import { RootState } from '../../../redux/store'
import { Filtres } from './types'

interface FilterPanelProps {
  handleFilters: (Filtres: Filtres) => void
}
export default function FilterPanel({ handleFilters }: FilterPanelProps) {
  const { t } = useTranslation()
  const [filter, setFilter] = React.useState({
    email: '',
    firstName: '',
    lastName: '',
    sortBy: '',
    profileType: 'TOUS',
  })
  const updateFilter = (k: string, v: string) => setFilter((prev) => ({ ...prev, [k]: v }))
  const isAdmin = useSelector((state: RootState) => state.userProfileReducer.admin)
  const reset = () => {
    setFilter({
      email: '',
      firstName: '',
      lastName: '',
      sortBy: '',
      profileType: 'TOUS',
    })
    handleFilters({
      email: '',
      firstName: '',
      lastName: '',
      sortBy: '',
      profileType: 'TOUS',
    })
  }

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>{t('common.filters')}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <div>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label={t('forms.labels.first_name')}
                name="firstName"
                value={filter.firstName}
                onChange={(e) => updateFilter('firstName', e.target.value)}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label={t('forms.labels.last_name')}
                name="lastName"
                value={filter.lastName}
                onChange={(e) => updateFilter('lastName', e.target.value)}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label={t('forms.labels.email')}
                name="email"
                value={filter.email}
                onChange={(e) => updateFilter('email', e.target.value)}
              />
            </Grid>
            {isAdmin && (
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label={t('common.user_type')}
                  name="profileType"
                  select
                  value={filter.profileType}
                  onChange={(e) => updateFilter('profileType', e.target.value)}
                >
                  {Roles.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            )}
          </Grid>
        </div>
        <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={2} mt={2}>
          <Button color="primary" variant="contained" onClick={() => reset()}>
            {t('common.reset')}
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              handleFilters(filter)
            }}
          >
            {t('common.apply')}
          </Button>
        </Stack>
      </AccordionDetails>
    </Accordion>
  )
}
