import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import { FormikProps } from 'formik'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import {
  Advisor,
  AdvisorPut,
  Assignment,
  Client,
  ClientPut,
  ProfileSearchResultContent,
  ProfileType,
} from '../../../api'
import { options } from '../../../helper/constants'
import { getAdvisorPermissions } from '../../../redux/actions/advisor.permissions.actions'
import { RootState, useAppDispatch } from '../../../redux/store'
import SwitchUserStatus from '../../switchButton'
import DataGridUpdateUser from './dataGridUpdateUser'

/* -------------------------------------------------------------------------- */
/*                                  COMPONENT                                 */
/* -------------------------------------------------------------------------- */
interface UserFormUpdateProps {
  FormikUserForm: FormikProps<ClientPut> | FormikProps<AdvisorPut>
  setTransferTo?: (values: Assignment[] | []) => void
}

export function UserFormUpdate({ FormikUserForm, setTransferTo }: UserFormUpdateProps) {
  /* -------------------------------------------------------------------------- */
  /*                                  CONSTANTS                                 */
  /* -------------------------------------------------------------------------- */
  const isAdmin = useSelector((state: RootState) => state.userProfileReducer.admin)
  const permissions = useSelector((state: RootState) => state.advisorPermissionsReducer)
  const [isCurrentAdvisor, setIsCurrentAdvisor] = React.useState<boolean>(false)

  const { t } = useTranslation()
  const params = useParams()
  const navigate = useNavigate()
  const navigateTo = isAdmin ? '/users' : '/clients'
  const dispatch = useAppDispatch()

  const onChangeUserList = (data: Array<ProfileSearchResultContent>) => {
    FormikUserForm.setFieldValue('advisorIds', data)
  }

  React.useEffect(() => {
    const connectedUser = JSON.parse(window.localStorage.getItem('user') || '""')

    dispatch(getAdvisorPermissions(connectedUser.id))
  }, [dispatch])
  React.useEffect(() => {
    const connectedUser = JSON.parse(window.localStorage.getItem('user') || '""')
    if (connectedUser.id === Number(params.id)) setIsCurrentAdvisor(true)
  }, [params.id])

  /* -------------------------------------------------------------------------- */
  /*                                   RENDER                                   */
  /* -------------------------------------------------------------------------- */
  return (
    <Card>
      {(FormikUserForm.values as Advisor).profileType === ProfileType.Advisor && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            p: 2,
          }}
        >
          <Button color="primary" variant="contained" onClick={() => navigate(navigateTo)}>
            {t('common.cancel')}
          </Button>
          <Button
            color="primary"
            variant="contained"
            sx={{ ml: 2 }}
            onClick={() => FormikUserForm.handleSubmit()}
          >
            {t('common.save')}
          </Button>
        </Box>
      )}
      <CardHeader
        subheader={t('update_user.card_header_client')}
        title={
          (FormikUserForm.values as Advisor).profileType === ProfileType.Advisor
            ? t('update_user.update_advisor')
            : t('update_user.update_client')
        }
      />
      <Divider />

      <CardContent>
        <Grid container spacing={3}>
          <Grid item md={6} xs={12}>
            <TextField
              fullWidth
              label={t('forms.labels.first_name')}
              name="firstName"
              onChange={FormikUserForm.handleChange}
              required
              value={FormikUserForm.values.firstName}
              variant="outlined"
              helperText={
                FormikUserForm.errors.firstName &&
                FormikUserForm.touched.firstName &&
                FormikUserForm.errors.firstName
              }
              error={!!(FormikUserForm.errors.firstName && FormikUserForm.touched.firstName)}
              disabled={
                !permissions.clientPermissions.updateContactInformation && !isCurrentAdvisor
              }
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              fullWidth
              label={t('forms.labels.last_name')}
              name="lastName"
              onChange={FormikUserForm.handleChange}
              required
              value={FormikUserForm.values.lastName}
              variant="outlined"
              helperText={
                FormikUserForm.errors.lastName &&
                FormikUserForm.touched.lastName &&
                FormikUserForm.errors.lastName
              }
              error={!!(FormikUserForm.errors.lastName && FormikUserForm.touched.lastName)}
              disabled={
                !permissions.clientPermissions.updateContactInformation && !isCurrentAdvisor
              }
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              fullWidth
              label={t('forms.labels.email')}
              name="email"
              onChange={FormikUserForm.handleChange}
              required
              value={FormikUserForm.values.email}
              variant="outlined"
              helperText={
                FormikUserForm.errors.email &&
                FormikUserForm.touched.email &&
                FormikUserForm.errors.email
              }
              error={!!(FormikUserForm.errors.email && FormikUserForm.touched.email)}
              disabled={
                !permissions.clientPermissions.updateContactInformation && !isCurrentAdvisor
              }
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              fullWidth
              required
              label={t('forms.labels.phone_number')}
              name="phoneNumber"
              helperText={
                FormikUserForm.errors.phoneNumber &&
                FormikUserForm.touched.phoneNumber &&
                FormikUserForm.errors.phoneNumber
              }
              error={!!(FormikUserForm.errors.phoneNumber && FormikUserForm.touched.phoneNumber)}
              onChange={FormikUserForm.handleChange}
              value={FormikUserForm.values.phoneNumber}
              variant="outlined"
              disabled={
                !permissions.clientPermissions.updateContactInformation && !isCurrentAdvisor
              }
            />
          </Grid>

          {(FormikUserForm.values as Advisor).profileType === ProfileType.Advisor && (
            <Grid item md={12} xs={12}>
              <FormControlLabel
                label={t('forms.labels.admin') as string}
                control={
                  <Checkbox
                    name="admin"
                    disabled={!isAdmin || isCurrentAdvisor}
                    checked={(FormikUserForm?.values as AdvisorPut)?.admin ?? false}
                    onChange={FormikUserForm.handleChange}
                  />
                }
              />
            </Grid>
          )}

          {(FormikUserForm.values as Advisor).profileType === ProfileType.Advisor ? (
            <Grid item md={12} xs={12}>
              <Typography>{t('forms.labels.advisor_type')}</Typography>
              <FormGroup row>
                {options.map((el) => (
                  <FormLabel
                    key={el.value}
                    error={
                      !!(
                        (FormikUserForm.errors as AdvisorPut).types &&
                        (FormikUserForm.touched as unknown as AdvisorPut).types
                      )
                    }
                  >
                    <FormControlLabel
                      name="types"
                      control={
                        <Checkbox
                          disabled={!isAdmin}
                          onChange={(e) => {
                            if (e.target.checked) {
                              FormikUserForm.setFieldValue('types', [
                                ...((FormikUserForm.values as AdvisorPut)?.types ?? []),
                                el.value,
                              ])
                            } else {
                              FormikUserForm.setFieldValue(
                                'types',
                                (FormikUserForm.values as AdvisorPut)?.types?.filter(
                                  (item) => item !== el.value,
                                ),
                              )
                            }
                          }}
                          checked={
                            ((FormikUserForm.values as AdvisorPut).types as Array<string>)?.indexOf(
                              el.value,
                            ) > -1
                          }
                        />
                      }
                      label={el.label}
                    />
                  </FormLabel>
                ))}
              </FormGroup>
            </Grid>
          ) : (
            <>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label={t('forms.labels.crm_code')}
                  required
                  name="crmCode"
                  helperText={
                    (FormikUserForm.errors as ClientPut).crmCode &&
                    (FormikUserForm.touched as unknown as ClientPut).crmCode &&
                    (FormikUserForm.errors as ClientPut).crmCode
                  }
                  error={
                    !!(
                      (FormikUserForm.errors as ClientPut).crmCode &&
                      (FormikUserForm.touched as unknown as ClientPut).crmCode
                    )
                  }
                  onChange={FormikUserForm.handleChange}
                  value={(FormikUserForm.values as ClientPut).crmCode}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label={t('forms.labels.street_address')}
                  name="streetAddress"
                  onChange={FormikUserForm.handleChange}
                  value={(FormikUserForm.values as ClientPut).streetAddress}
                  variant="outlined"
                  disabled={!permissions.clientPermissions.updateContactInformation}
                />
              </Grid>
            </>
          )}
          {isAdmin && (
            <Grid item md={12}>
              <Typography>{t('forms.labels.account_status')}</Typography>
              <SwitchUserStatus
                profileType={(FormikUserForm.values as Advisor)?.profileType ?? ''}
                inactiveReason={FormikUserForm?.values?.inactiveReason ?? ''}
                status={FormikUserForm?.values?.active ?? false}
                types={(FormikUserForm.values as AdvisorPut)?.types ?? []}
                clients={(FormikUserForm.values as AdvisorPut)?.clientIds?.length ?? 0}
                onChangeStatus={(value) => {
                  FormikUserForm.setFieldValue('active', value)
                  if (value) {
                    FormikUserForm.setFieldValue('inactiveReason', '')
                  }
                }}
                errorHandel={
                  !!(FormikUserForm.errors.inactiveReason && FormikUserForm.touched.inactiveReason)
                }
                helperText={
                  FormikUserForm.errors.inactiveReason && FormikUserForm.touched.inactiveReason
                    ? FormikUserForm.errors.inactiveReason
                    : ''
                }
                onChangeRaison={(value) => FormikUserForm.setFieldValue('inactiveReason', value)}
                isCurrentAdvisor={isCurrentAdvisor}
                setTransferTo={setTransferTo}
              />
            </Grid>
          )}
          <Grid item md={6} xs={12}>
            <FormControl fullWidth>
              <InputLabel>{t('forms.labels.language')}</InputLabel>
              <Select
                value={FormikUserForm.values.locale || 'fr'}
                name="locale"
                label="Site Language"
                onChange={FormikUserForm.handleChange}
                onBlur={FormikUserForm.handleBlur}
                disabled={
                  !permissions.clientPermissions.updateContactInformation && !isCurrentAdvisor
                }
              >
                <MenuItem value="fr">{t('forms.languages.french')}</MenuItem>
                <MenuItem value="en">{t('forms.languages.english')}</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={6} xs={12}>
            <FormControl fullWidth>
              <InputLabel>{t('forms.labels.communication_language')}</InputLabel>
              <Select
                value={FormikUserForm.values.communicationLocale || 'fr'}
                name="communicationLocale"
                label="Communication Language"
                onChange={FormikUserForm.handleChange}
                onBlur={FormikUserForm.handleBlur}
                disabled={
                  !permissions.clientPermissions.updateContactInformation && !isCurrentAdvisor
                }
              >
                <MenuItem value="fr">{t('forms.languages.french')}</MenuItem>
                <MenuItem value="en">{t('forms.languages.english')}</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        {(FormikUserForm.values as Client).profileType === ProfileType.Client && (
          <Grid item md={12}>
            <DataGridUpdateUser
              onChangeUserList={(data) => onChangeUserList(data)}
              advisorList={(FormikUserForm.values as any)?.advisorIds}
            />
          </Grid>
        )}
      </CardContent>
    </Card>
  )
}

UserFormUpdate.defaultProps = {
  setTransferTo: undefined,
}
