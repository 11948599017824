import { Container } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import DashboardLayout from '../../components/DashboardLayout'
import { RootState } from '../../redux/store'

function DashboardScreen() {
  const navigate = useNavigate()
  const profileLoaded = useSelector((state: RootState) => state.userProfileReducer)
  const isAdmin = useSelector((state: RootState) => state.userProfileReducer.admin)

  React.useEffect(() => {
    if (profileLoaded.roles) {
      if (isAdmin) {
        navigate('/users')
      } else {
        navigate('/clients')
      }
    }
  }, [profileLoaded, isAdmin, navigate])

  /* -------------------------------------------------------------------------- */
  /*                                   RENDER                                   */
  /* -------------------------------------------------------------------------- */
  return (
    <DashboardLayout headerTitle="">
      <Container maxWidth="lg" />
    </DashboardLayout>
  )
}

export default DashboardScreen
