/* -------------------------------------------------------------------------- */
/*                                   IMPORTS                                  */
/* -------------------------------------------------------------------------- */
import React from 'react'
import { Route, Routes } from 'react-router-dom'
import DashboardScreen from '../screen/dashboard'
import { NotFound } from '../screen/notFound'
import NotificationsScreen from '../screen/notifications'
import UpdateAdvisorScreen from '../screen/users/advisors/updateAdvisor'
import UserClientScreen from '../screen/users/clients/updateClient'
import ClientsListScreen from '../screen/users/listOfClients'
import UsersListScreen from '../screen/users/listOfUsers'
import ProtectedRoute from './protected'

function MainRouter() {
  return (
    <Routes>
      <Route path="/" element={<ProtectedRoute component={DashboardScreen} />} />

      <Route path="/users" element={<ProtectedRoute component={UsersListScreen} adminOnly />} />
      <Route path="/clients" element={<ProtectedRoute component={ClientsListScreen} />} />
      <Route path="/client/:id" element={<ProtectedRoute component={UserClientScreen} />} />
      <Route path="/advisor/:id" element={<ProtectedRoute component={UpdateAdvisorScreen} />} />
      <Route path="/notifications" element={<ProtectedRoute component={NotificationsScreen} />} />

      <Route path="/*" element={<NotFound />} />
    </Routes>
  )
}

export default MainRouter
