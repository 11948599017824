import { LOADING_APP } from '../actionTypes'
import { ILoadingApp } from '../types'

const init: ILoadingApp = {
  loading: false,
}
export function loadingReducer(state = init, { type, payload }: any = {}) {
  switch (type) {
    case LOADING_APP:
      return {
        ...state,
        loading: payload,
      }

    default:
      return state
  }
}
