import { Alert, Box, Snackbar, Tab, Tabs } from '@mui/material'
import { FormikProps, FormikProvider, useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'
import { Advisor, AdvisorPut, Assignment, ReassignClients } from '../../../../api'
import AdvisorPermissions from '../../../../components/advisorPermissions'
import { Prompt } from '../../../../components/customPrompt'
import PromptView from '../../../../components/customPrompt/promptView'
import DashboardLayout from '../../../../components/DashboardLayout'
import { UserFormUpdate } from '../../../../components/Forms/signupUser/formUpdateUser'
import Loader from '../../../../components/loader'
import { compareObjects } from '../../../../helper/constants'
import { snackBarProps } from '../../../../helper/iTypes'
import { AdvisorSchema } from '../../../../helper/validationSchema'
import {
  getAdvisor,
  transferAdvisorClients,
  updateUserAdvisor,
} from '../../../../redux/actions/advisor.action'
import { onHandleLoading } from '../../../../redux/actions/loading.action'
import { getUserProfileSuccess } from '../../../../redux/actions/userProfil.action'
import { RootState, useAppDispatch } from '../../../../redux/store'
import { initialValuesAdvisor } from '../../clients/updateClient/initValuesFromik'
import '../../styles/index.css'

interface stateType {
  userID: number
}
interface TabPanelProps {
  children: React.ReactChild
  index: number
  value: number
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  )
}
function UpdateAdvisorScreen() {
  /* -------------------------------------------------------------------------- */
  /*                                    HOOKS                                   */
  /* -------------------------------------------------------------------------- */
  const loading = useSelector((state: RootState) => state.loadingReducer.loading)
  const isAdmin = useSelector((state: RootState) => state.userProfileReducer.admin)
  const [snackBar, setSnackBar] = React.useState<snackBarProps>({
    open: false,
    message: '',
    severity: 'success',
  })
  const { t } = useTranslation()

  const dispatch = useAppDispatch()
  const location = useLocation()
  const params = useParams()
  const [value, setValue] = useState(0)

  const stateLocation = location?.state as stateType
  const userID = stateLocation?.userID ?? params.id
  const [initialValues, setInitialValues] = useState<AdvisorPut>(initialValuesAdvisor)
  const myProfile = useSelector((state: RootState) => state.userProfileReducer)
  const [isNotCurrentAdvisor, setIsNotCurrentAdvisor] = React.useState<boolean>(false)
  const [showPromptTabs, setShowPromptTabs] = useState(false)
  const [handleIndexTab, setHandleIndexTab] = useState(0)
  const [transferTo, setTransferTo] = useState<Assignment[] | []>([])

  /* -------------------------------------------------------------------------- */
  /*                                  CONSTANTS                                 */
  /* -------------------------------------------------------------------------- */

  const handleCloseSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }
    setSnackBar({
      open: false,
      message: '',
      severity: 'success',
    })
  }

  function transferClients(id: number, clientIds: number[] | undefined) {
    const reassignClients: ReassignClients = {
      clientIds,
      newAssignments: transferTo,
    }
    dispatch(transferAdvisorClients(id, reassignClients)).catch((error) => {
      dispatch(onHandleLoading(false))
      throw new Error(error as string)
    })
  }

  function onSubmitForm(values: Advisor) {
    dispatch(updateUserAdvisor(values?.id, values as AdvisorPut)).then((res) => {
      if (res?.status === 200) {
        if (res.data.id === myProfile.id) {
          localStorage.setItem('user', JSON.stringify(res.data))
          dispatch(getUserProfileSuccess(res.data))
        }
        setInitialValues(res.data)
        setSnackBar({
          open: true,
          message: t('update_user.success_update_msg'),
          severity: 'success',
        })
        if (!values.active && transferTo.length > 0) {
          transferClients(values?.id, values.clientIds)
        }
      } else {
        setSnackBar({
          open: true,
          message: t(`errors.${res.status}`),
          severity: 'error',
        })
      }
    })
  }
  /* -------------------------------------------------------------------------- */
  /*                                    USEEFFECTS                              */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    const connectedUser = JSON.parse(window.localStorage.getItem('user') || '""')
    if (connectedUser.id !== Number(params.id)) {
      setIsNotCurrentAdvisor(true)
    } else {
      setIsNotCurrentAdvisor(false)
    }
    if (stateLocation || params.id) {
      dispatch(getAdvisor(userID)).then((res) => {
        if (res.status === 200) {
          setInitialValues(res.data)
        } else {
          setSnackBar({
            open: true,
            message: t(`errors.${res.status}`),
            severity: 'error',
          })
        }
      })
    } else {
      setInitialValues(myProfile)
    }
    return () => {
      setInitialValues({} as AdvisorPut)
    }
  }, [params.id, isNotCurrentAdvisor, stateLocation, dispatch, userID, t, myProfile])
  /* -------------------------------------------------------------------------- */
  /*                                   FORMIK                                   */
  /* -------------------------------------------------------------------------- */
  const FormikUserForm: FormikProps<AdvisorPut> = useFormik<AdvisorPut>({
    initialValues,
    enableReinitialize: true,
    onSubmit: (values) => {
      const castValues = AdvisorSchema.cast(values)
      onSubmitForm(castValues as Advisor)
    },
    validationSchema: AdvisorSchema,
  })
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    if (!compareObjects(FormikUserForm.initialValues, FormikUserForm.values)) {
      setHandleIndexTab(newValue)
      setShowPromptTabs(true)
    } else {
      setValue(newValue)
    }
  }
  const cancelNavigationTab = () => {
    setShowPromptTabs(false)
  }
  const confirmNavigationTab = () => {
    FormikUserForm.handleReset()
    setValue(handleIndexTab)
    setShowPromptTabs(false)
  }

  /* -------------------------------------------------------------------------- */
  /*                                   RENDER                                   */
  /* -------------------------------------------------------------------------- */
  return (
    <DashboardLayout
      headerTitle={`${t('common.advisor')}: ${initialValues?.firstName} ${initialValues?.lastName}`}
    >
      <div>
        <Prompt shouldPrompt={!!FormikUserForm.dirty} />
        <Loader loading={loading} />
        <PromptView
          showPrompt={showPromptTabs}
          cancelNavigation={cancelNavigationTab}
          confirmNavigation={confirmNavigationTab}
          dialogTitle="common.unsaved_changes"
          dialogText="common.blockNavigation"
        />

        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={snackBar.open}
          onClose={handleCloseSnackbar}
          autoHideDuration={4000}
        >
          <Alert onClose={handleCloseSnackbar} variant="filled" severity={snackBar?.severity}>
            {snackBar.message}
          </Alert>
        </Snackbar>
        {(!isNotCurrentAdvisor || isAdmin) && (
          <>
            {/*
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                p: 2,
              }}
            >
              <Button color="primary" variant="contained" onClick={() => navigate('/users')}>
                {t('common.cancel')}
              </Button>
              <Button
                color="primary"
                variant="contained"
                sx={{ ml: 2 }}
                onClick={() => FormikUserForm.handleSubmit()}
              >
                {t('common.save')}
              </Button>
            </Box>

            <UserFormUpdate FormikUserForm={FormikUserForm} />
            <AdvisorPermissions />

            */}
            <Box>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange}>
                  <Tab label={t('tabs_title.general')} id={`tab-${0}`} />
                  <Tab label={t('tabs_title.permission')} id={`tab-${1}`} />
                </Tabs>
              </Box>
              <FormikProvider value={FormikUserForm}>
                <TabPanel value={value} index={0}>
                  <UserFormUpdate FormikUserForm={FormikUserForm} setTransferTo={setTransferTo} />
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <AdvisorPermissions />
                </TabPanel>
              </FormikProvider>
            </Box>
          </>
        )}
      </div>
    </DashboardLayout>
  )
}

export default UpdateAdvisorScreen
