import { Configuration } from '../../api'
import { NotificationPost, NotificationsApi, NotificationSearchQuery } from '../../api/api'
import { handleAccessToken } from '../../services/config'
import { GET_NOTIFICATIONS_SUCCESS } from '../actionTypes'
import { AppDispatch } from '../store'
import { onHandleLoading } from './loading.action'

export function getNotificationsSuccess(data: any) {
  return {
    type: GET_NOTIFICATIONS_SUCCESS,
    payload: data,
  }
}

export function getNotifications(data: NotificationSearchQuery) {
  return async (dispatch: AppDispatch) => {
    const token = await handleAccessToken.getAccessTokenSilently()
    const notificationsAPI = new NotificationsApi(
      new Configuration({
        basePath: `${process.env.REACT_APP_BACKEND_API_URL}`,
        accessToken: token,
      }),
    )
    return notificationsAPI
      .searchMyNotifications('fr', data)
      .then((res) => {
        dispatch(getNotificationsSuccess(res.data))
        return res
      })
      .catch((error) => error.response)
  }
}

export function sendNotifications(notificationPost: NotificationPost) {
  return async (dispatch: AppDispatch) => {
    dispatch(onHandleLoading(true))
    const token = await handleAccessToken.getAccessTokenSilently()
    const notificationsAPI = new NotificationsApi(
      new Configuration({
        basePath: `${process.env.REACT_APP_BACKEND_API_URL}`,
        accessToken: token,
      }),
    )
    return notificationsAPI
      .sendNotification('fr', notificationPost)
      .then((res) => {
        dispatch(onHandleLoading(false))
        return res
      })
      .catch((error) => {
        dispatch(onHandleLoading(false))
        return error.response
      })
  }
}
