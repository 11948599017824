import { useDispatch } from 'react-redux'
import { Action, applyMiddleware, combineReducers, createStore } from 'redux'
import thunk, { ThunkDispatch } from 'redux-thunk'
import { advisorPermissionsReducer } from './reducers/advisor.permissions.reducer'
import { documentsReducer } from './reducers/documents.reducer'
import { loadingReducer } from './reducers/loading.reduser'
import { mortgagesReducer } from './reducers/mortgages.reducer'
import { notificationsReducer } from './reducers/notifications.reducer'
import { userProfileReducer } from './reducers/userProfile.reducer'
import { usersReducer } from './reducers/users.reducer'

const reducer = combineReducers({
  usersReducer,
  userProfileReducer,
  loadingReducer,
  documentsReducer,
  mortgagesReducer,
  advisorPermissionsReducer,
  notificationsReducer,
})

export const store = createStore(reducer, applyMiddleware(thunk))
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
export type ThunkAppDispatch = ThunkDispatch<RootState, void, Action>
export const useAppDispatch = () => useDispatch<ThunkAppDispatch>()
