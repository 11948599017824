import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  MenuItem,
  TextField,
} from '@mui/material'
import Alert from '@mui/material/Alert'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { FormikProvider, useFormik } from 'formik'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'
import { FrequencyType, RateType } from '../../../api'
import { Equity } from '../../../helper/constants'
import { MortgageSchema } from '../../../helper/validationSchema'
import { getClient } from '../../../redux/actions/client.action'
import { RootState, useAppDispatch } from '../../../redux/store'
import Loader from '../../loader'
/* -------------------------------------------------------------------------- */
/*                                  COMPONENT                                 */
/* -------------------------------------------------------------------------- */
interface FormSignup {
  submitLabel: string
  onSubmitForm: (values: any) => void
  onCancel: () => void
  initialValues: any
  onSetClientData: (data: any) => void
  itsUpdate: boolean
}

interface stateType {
  userID: number
}

export function MortgageForm({
  submitLabel,
  initialValues,
  onSubmitForm,
  onCancel,
  onSetClientData,
  itsUpdate,
}: FormSignup) {
  /* -------------------------------------------------------------------------- */
  /*                                  CONSTANTS                                 */
  /* -------------------------------------------------------------------------- */
  const loading = useSelector((state: RootState) => state.loadingReducer.loading)
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const location = useLocation()
  const paramsValues = useParams()
  const [balanceUpdated, setBalanceUpdated] = React.useState(false)
  const routerState = location.state as stateType
  const userID = routerState?.userID ?? paramsValues.id
  /* -------------------------------------------------------------------------- */
  /*                                   FORMIK                                   */
  /* -------------------------------------------------------------------------- */
  const FormikMortgageForm = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: (values) => {
      const castValues = MortgageSchema.cast(values)
      onSubmitForm(castValues as any)
    },
    validationSchema: MortgageSchema,
  })
  /* -------------------------------------------------------------------------- */
  React.useEffect(() => {
    dispatch(getClient(userID)).then((res: any) => {
      if (res.status === 200) {
        onSetClientData(res.data)
      }
    })
  }, [])
  React.useEffect(() => {
    const checkIfBalanceGetUpdated = () => {
      if (itsUpdate) {
        if (FormikMortgageForm.values.balance !== initialValues.balance) {
          setBalanceUpdated(true)
          if (FormikMortgageForm.values.lastBalanceUpdate !== initialValues.lastBalanceUpdate) {
            setBalanceUpdated(false)
          }
        }
      }
    }
    checkIfBalanceGetUpdated()
  }, [FormikMortgageForm.values.balance, FormikMortgageForm.values.lastBalanceUpdate])
  /* -------------------------------------------------------------------------- */
  /*                                   RENDER                                   */
  /* -------------------------------------------------------------------------- */
  return (
    <FormikProvider value={FormikMortgageForm}>
      <Loader loading={loading} />
      <Card sx={{ width: 800 }}>
        <CardHeader
          subheader={
            !itsUpdate
              ? t('forms.mortgage.add_mortgage_subtitle')
              : t('forms.mortgage.update_mortgage_subtitle')
          }
          title={
            !itsUpdate
              ? t('forms.mortgage.add_mortgage_title')
              : t('forms.mortgage.update_mortgage_title')
          }
        />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={6} xs={6}>
              <TextField
                fullWidth
                label={t('forms.mortgage.name')}
                name="name"
                onChange={FormikMortgageForm.handleChange}
                required
                value={FormikMortgageForm.values.name}
                variant="outlined"
                helperText={
                  FormikMortgageForm.errors.name &&
                  FormikMortgageForm.touched.name &&
                  FormikMortgageForm.errors.name
                }
                error={!!(FormikMortgageForm.errors.name && FormikMortgageForm.touched.name)}
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <TextField
                sx={{ width: 150, mr: 2 }}
                select
                value={FormikMortgageForm.values.rateType}
                name="rateType"
                label={t('forms.mortgage.rateType')}
                onChange={FormikMortgageForm.handleChange}
              >
                {Object.entries(RateType).map(([key, rateTypeValue]) => (
                  <MenuItem value={rateTypeValue} key={key}>
                    {t(`rate_types.${key}`)}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                sx={{ width: 150, mx: 2 }}
                select
                value={FormikMortgageForm.values.frequency}
                name="frequency"
                label={t('forms.mortgage.frequency')}
                onChange={FormikMortgageForm.handleChange}
              >
                {Object.entries(FrequencyType).map(([key, FrequencyTypeValue]) => (
                  <MenuItem value={FrequencyTypeValue} key={key}>
                    {t(`frequency_types.${key}`)}
                  </MenuItem>
                ))}
              </TextField>
              <LocalizationProvider sx={{ width: '1/4', mx: 2 }} dateAdapter={AdapterDateFns}>
                <DatePicker
                  label={t('forms.mortgage.maturityDate')}
                  value={FormikMortgageForm.values.maturityDate}
                  onChange={(newValue) => {
                    const timestamp = Date.parse(newValue)
                    // eslint-disable-next-line no-restricted-globals
                    if (isNaN(timestamp) === false) {
                      FormikMortgageForm.setFieldValue(
                        'maturityDate',
                        `${newValue.toISOString().split('.')[0]}Z`,
                      )
                    } else {
                      FormikMortgageForm.setFieldValue('maturityDate', null)
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...params}
                      required
                      helperText={
                        FormikMortgageForm.errors.maturityDate &&
                        FormikMortgageForm.touched.maturityDate &&
                        FormikMortgageForm.errors.maturityDate
                      }
                      error={
                        !!(
                          FormikMortgageForm.errors.maturityDate &&
                          FormikMortgageForm.touched.maturityDate
                        )
                      }
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item md={12} xs={12}>
              <TextField
                sx={{ width: '1/3', mr: 2 }}
                type="number"
                label={t('forms.mortgage.amount')}
                name="amount"
                onChange={FormikMortgageForm.handleChange}
                required
                value={FormikMortgageForm.values.amount}
                InputProps={{
                  inputProps: { min: 0, inputMode: 'numeric', pattern: '[0-9]*' },
                }}
                variant="outlined"
                helperText={
                  FormikMortgageForm.errors.amount &&
                  FormikMortgageForm.touched.amount &&
                  FormikMortgageForm.errors.amount
                }
                error={!!(FormikMortgageForm.errors.amount && FormikMortgageForm.touched.amount)}
              />
              <TextField
                sx={{ width: '1/3', mx: 2 }}
                type="number"
                label={t('forms.mortgage.propertyValue')}
                name="propertyValue"
                onChange={FormikMortgageForm.handleChange}
                required
                value={FormikMortgageForm.values.propertyValue}
                InputProps={{
                  inputProps: { min: 0, inputMode: 'numeric', pattern: '[0-9]*' },
                }}
                variant="outlined"
                helperText={
                  FormikMortgageForm.errors.propertyValue &&
                  FormikMortgageForm.touched.propertyValue &&
                  FormikMortgageForm.errors.propertyValue
                }
                error={
                  !!(
                    FormikMortgageForm.errors.propertyValue &&
                    FormikMortgageForm.touched.propertyValue
                  )
                }
              />
              <TextField
                sx={{ width: '1/3', mx: 2 }}
                type="number"
                label={t('forms.mortgage.balance')}
                name="balance"
                onChange={FormikMortgageForm.handleChange}
                required
                value={FormikMortgageForm.values.balance}
                InputProps={{
                  inputProps: { min: 0, inputMode: 'numeric', pattern: '[0-9]*' },
                }}
                variant="outlined"
                helperText={
                  FormikMortgageForm.errors.balance &&
                  FormikMortgageForm.touched.balance &&
                  FormikMortgageForm.errors.balance
                }
                error={!!(FormikMortgageForm.errors.balance && FormikMortgageForm.touched.balance)}
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <TextField
                sx={{ width: 150, mr: 2 }}
                select
                label={t('forms.mortgage.equity')}
                value={FormikMortgageForm.values.homeEquityLineOfCredit}
                name="homeEquityLineOfCredit"
                onChange={FormikMortgageForm.handleChange}
              >
                {Object.entries(Equity).map(([key, equityValue]: any) => (
                  <MenuItem value={equityValue} key={key}>
                    {t(`equity_types.${key}`)}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                sx={{ width: 150, mx: 2 }}
                type="number"
                label={t('forms.mortgage.rate')}
                name="rate"
                onChange={FormikMortgageForm.handleChange}
                required
                value={FormikMortgageForm.values.rate}
                InputProps={{
                  inputProps: { min: 0, inputMode: 'numeric', pattern: '[0-9]*' },
                }}
                variant="outlined"
                helperText={
                  FormikMortgageForm.errors.rate &&
                  FormikMortgageForm.touched.rate &&
                  FormikMortgageForm.errors.rate
                }
                error={!!(FormikMortgageForm.errors.rate && FormikMortgageForm.touched.rate)}
              />
              <LocalizationProvider sx={{ width: '1/3', mx: 2 }} dateAdapter={AdapterDateFns}>
                <DatePicker
                  label={t('forms.mortgage.lastBalanceUpdate')}
                  value={FormikMortgageForm.values.lastBalanceUpdate}
                  onChange={(newValue) => {
                    const timestamp = Date.parse(newValue)
                    // eslint-disable-next-line no-restricted-globals
                    if (isNaN(timestamp) === false) {
                      FormikMortgageForm.setFieldValue(
                        'lastBalanceUpdate',
                        `${newValue.toISOString().split('.')[0]}Z`,
                      )
                    } else {
                      FormikMortgageForm.setFieldValue('lastBalanceUpdate', null)
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...params}
                      required
                      helperText={
                        FormikMortgageForm.errors.lastBalanceUpdate &&
                        FormikMortgageForm.touched.lastBalanceUpdate &&
                        FormikMortgageForm.errors.lastBalanceUpdate
                      }
                      error={
                        !!(
                          FormikMortgageForm.errors.lastBalanceUpdate &&
                          FormikMortgageForm.touched.lastBalanceUpdate
                        )
                      }
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item md={6} xs={6}>
              <TextField
                fullWidth
                label={t('forms.mortgage.lender')}
                name="lender"
                onChange={FormikMortgageForm.handleChange}
                required
                value={FormikMortgageForm.values.lender}
                variant="outlined"
                helperText={
                  FormikMortgageForm.errors.lender &&
                  FormikMortgageForm.touched.lender &&
                  FormikMortgageForm.errors.lender
                }
                error={!!(FormikMortgageForm.errors.lender && FormikMortgageForm.touched.lender)}
              />
            </Grid>
          </Grid>
        </CardContent>
        {balanceUpdated && <Alert severity="error">{t('forms.mortgage.balance_error')}</Alert>}
        <Divider />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            p: 2,
          }}
        >
          <Button color="primary" variant="contained" onClick={() => onCancel()}>
            {t('common.cancel')}
          </Button>
          <Button
            color="primary"
            variant="contained"
            disabled={
              balanceUpdated ||
              (itsUpdate &&
                JSON.stringify(FormikMortgageForm.values) === JSON.stringify(initialValues))
            }
            onClick={() => FormikMortgageForm.handleSubmit()}
            sx={{ ml: 1 }}
          >
            {submitLabel}
          </Button>
        </Box>
      </Card>
    </FormikProvider>
  )
}
