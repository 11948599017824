import { green } from '@mui/material/colors'
import { createTheme } from '@mui/material/styles'

export const theme = createTheme({
  palette: {
    primary: {
      main: green[500],
      contrastText: '#fff',
    },
    secondary: {
      main: green[500],
    },
  },
})
