import { Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import DashboardLayout from '../../components/DashboardLayout'
import NotificationsList from '../../components/listNotifications'

function NotificationsScreen() {
  const { t } = useTranslation()
  /* -------------------------------------------------------------------------- */
  /*                                   RENDER                                   */
  /* -------------------------------------------------------------------------- */
  return (
    <DashboardLayout headerTitle={t('forms.notification.notification')}>
      <div>
        <Typography sx={{ mb: 3 }} variant="h4">
          {t('forms.notification.notification')}
        </Typography>
        <NotificationsList />
      </div>
    </DashboardLayout>
  )
}

export default NotificationsScreen
