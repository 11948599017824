/* -------------------------------------------------------------------------- */
/*                                   IMPORTS                                  */
/* -------------------------------------------------------------------------- */
import { useAuth0 } from '@auth0/auth0-react'
import { ThemeProvider } from '@mui/material'
import React, { useEffect } from 'react'
import './App.css'
import { theme } from './helper/theme'
import { getAdvisorPermissions } from './redux/actions/advisor.permissions.actions'
import { getUserProfileSuccess } from './redux/actions/userProfil.action'
import { useAppDispatch } from './redux/store'
import MainRuter from './routes'
import { handleAccessToken } from './services/config'

function App() {
  const { getAccessTokenSilently } = useAuth0()
  handleAccessToken.setAccessTokenSilently(getAccessTokenSilently)
  const dispatch = useAppDispatch()

  /* -------------------------------------------------------------------------- */
  /*                                   RENDER                                   */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    const connectedUser = JSON.parse(window.localStorage.getItem('user') || '""')
    if (connectedUser !== '') {
      dispatch(getUserProfileSuccess(connectedUser))
      dispatch(getAdvisorPermissions(connectedUser.id))
    }
  }, [])
  return (
    <ThemeProvider theme={theme}>
      <MainRuter />
    </ThemeProvider>
  )
}

export default App
