import Autocomplete from '@mui/material/Autocomplete'
import Chip from '@mui/material/Chip'
import TextField from '@mui/material/TextField'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { AdvisorType, ProfileSearchQuery, ProfileSearchResultContent, ProfileType } from '../../api'
import { onHandleLoading } from '../../redux/actions/loading.action'
import { getOnlyAdvisors } from '../../redux/actions/users.action'
import { useAppDispatch } from '../../redux/store'

interface SelectSingleAdvisorInputProps {
  label: string
  excludedAdvisor?: number[]
  typeFilter?: string
  selectedAdvisor: ProfileSearchResultContent | null
  setSelectedAdvisor: (value: ProfileSearchResultContent | null) => void
}

export default function SelectSingleAdvisorInput({
  label,
  excludedAdvisor,
  typeFilter,
  selectedAdvisor,
  setSelectedAdvisor,
}: SelectSingleAdvisorInputProps) {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [data, setData] = React.useState<Array<ProfileSearchResultContent>>([])

  React.useEffect(() => {
    let isMounted = true

    const obj: ProfileSearchQuery = {
      search: {
        profileType: ProfileType.Advisor,
      },
      pageIndex: 0,
    }

    dispatch(getOnlyAdvisors(obj))
      .then((res) => {
        if (res?.status === 200 && isMounted) {
          let filteredData: ProfileSearchResultContent[] = res?.data.content.filter(
            (el: ProfileSearchResultContent) => el.active && excludedAdvisor?.indexOf(el.id) === -1,
          )
          if (filteredData && typeFilter) {
            filteredData = filteredData.filter((el: ProfileSearchResultContent) =>
              el.types?.includes(typeFilter as AdvisorType),
            )
          }
          setData(filteredData)
        }
      })
      .catch((error) => {
        dispatch(onHandleLoading(false))
        throw new Error(error as string)
      })

    return () => {
      isMounted = false
    }
    // eslint-disable-next-line
  }, [])

  return (
    <div>
      <Autocomplete
        fullWidth
        id="advisors-list"
        noOptionsText="Aucun resultat"
        value={selectedAdvisor}
        onChange={(_event, newValue) => {
          setSelectedAdvisor(newValue)
        }}
        options={data}
        getOptionLabel={(option) => option.firstName ?? ''}
        renderOption={(props, option) => (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <li {...props} key={option.id}>
            {option?.firstName} {option?.lastName}
          </li>
        )}
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => (
            <Chip
              label={`${option?.firstName}  ${option?.lastName}`}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...getTagProps({ index })}
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...params}
            label={label}
            placeholder={t('forms.transfer_clients.choose_advisor')}
          />
        )}
        sx={{ marginTop: 3.5 }}
      />
    </div>
  )
}

SelectSingleAdvisorInput.defaultProps = {
  excludedAdvisor: [],
  typeFilter: undefined,
}
